import { BoutEventType, BoutSide, IBoutEvent, ICardInfo, IDualMeetBoutFencer, IExistingFencer, IWriteInFencer, Weapon } from "../types";
import { DB_V2 } from "./database";
import { awayMeetOrder, homeMeetOrder } from "./helpers";

interface EventCardArgs {
    color: "yellow" | "red" | "black";
    add: boolean;
}

interface BoutScorerFencer {
    score: number;
    cards: ICardInfo;
    name: string;
    forfeit: boolean;
    team: string;
}

const genBlankFencer = (): BoutScorerFencer => ({
    score: 0,
    cards: {
        yellow: false,
        red: 0,
        black: false
    },
    name: "",
    team: "",
    forfeit: false
});

const leftOrder = [3, 1, 2, 1, 3, 2, 1, 2, 3];
const rightOrder = [6, 5, 4, 6, 4, 5, 4, 6, 5];

type ScoreUpdater = (score: number) => void;
type CardsUpdater = (score: { yellow: boolean; red: number; black: boolean }) => void;
type BooleanUpdater = (value: boolean) => void;
type PriorityUpdater = (priority: BoutSide | null) => void;

export default class BoutScorer {
    private DB: DB_V2;

    public boutTime = 180000;
    public fencer1 = genBlankFencer();
    public fencer2 = genBlankFencer();
    public boutPriority: BoutSide | null = null;
    public switchedSides = false;
    public boutOrder = 0;
    public revising = false;

    private revisingWinner: BoutSide | null = null;

    private setFencer1Score: ScoreUpdater = () => {};
    private setFencer2Score: ScoreUpdater = () => {};
    private setFencer1Cards: CardsUpdater = () => {};
    private setFencer2Cards: CardsUpdater = () => {};
    private setFencer1Forfeit: BooleanUpdater = () => {};
    private setFencer2Forfeit: BooleanUpdater = () => {};
    private setSwitchedSides: BooleanUpdater = () => {};
    private setPriority: PriorityUpdater = () => {};

    public id: string;
    public meetId: string;
    public weapon: Weapon;
    public otherBouts: string[];

    constructor(
        DB: DB_V2,
        id: string,
        meetId: string,
        weapon: Weapon,
        order: number,
        otherBouts: string[],
        fencer1: IDualMeetBoutFencer,
        fencer2: IDualMeetBoutFencer,
        switchedSides: boolean,
        priority: BoutSide | null,
        _setFencer1Score?: ScoreUpdater,
        _setFencer2Score?: ScoreUpdater,
        _setFencer1Cards?: CardsUpdater,
        _setFencer2Cards?: CardsUpdater,
        _setFencer1Forfeit?: BooleanUpdater,
        _setFencer2Forfeit?: BooleanUpdater,
        _setSwitchedSides?: BooleanUpdater,
        _setPriority?: PriorityUpdater
    ) {
        this.DB = DB;

        this.id = id;
        this.meetId = meetId;
        this.weapon = weapon;
        this.boutOrder = order;
        this.otherBouts = otherBouts;
        this.switchedSides = switchedSides;
        this.boutPriority = priority;

        this.fencer1 = {
            name: `${fencer1.fencerInfo.firstName} ${fencer1.fencerInfo.lastName}`,
            team: fencer1.fencerInfo.teamName,
            forfeit: fencer1.forfeit,
            cards: { ...fencer1.cardInfo },
            score: fencer1.score
        };

        this.fencer2 = {
            name: `${fencer2.fencerInfo.firstName} ${fencer2.fencerInfo.lastName}`,
            team: fencer2.fencerInfo.teamName,
            forfeit: fencer2.forfeit,
            cards: { ...fencer2.cardInfo },
            score: fencer2.score
        };

        if (_setFencer1Score) this.setFencer1Score = _setFencer1Score;
        if (_setFencer2Score) this.setFencer2Score = _setFencer2Score;
        if (_setFencer1Cards) this.setFencer1Cards = _setFencer1Cards;
        if (_setFencer2Cards) this.setFencer2Cards = _setFencer2Cards;
        if (_setFencer1Forfeit) this.setFencer1Forfeit = _setFencer1Forfeit;
        if (_setFencer2Forfeit) this.setFencer2Forfeit = _setFencer2Forfeit;
        if (_setSwitchedSides) this.setSwitchedSides = _setSwitchedSides;
        if (_setPriority) this.setPriority = _setPriority;

        this.setFencer1Score(this.fencer1.score);
        this.setFencer2Score(this.fencer2.score);
        this.setFencer1Cards(this.fencer1.cards);
        this.setFencer2Cards(this.fencer2.cards);
        this.setFencer1Forfeit(this.fencer1.forfeit);
        this.setFencer2Forfeit(this.fencer2.forfeit);
        this.setSwitchedSides(this.switchedSides);
        this.setPriority(this.boutPriority);
    }

    public get winner() {
        let winner: BoutSide | null = null;
        if (this.fencer1.forfeit && this.fencer2.forfeit) return null;
        if (this.fencer1.forfeit) winner = BoutSide.Fencer2;
        else if (this.fencer2.forfeit) winner = BoutSide.Fencer1;
        else if (this.fencer1.score > this.fencer2.score) winner = BoutSide.Fencer1;
        else if (this.fencer2.score > this.fencer1.score) winner = BoutSide.Fencer2;
        else if (this.fencer1.score === this.fencer2.score) winner = this.boutPriority;
        return winner;
    }

    private setRevising(val: boolean) {
        if (val) {
            this.revisingWinner = this.winner;
        }
        this.revising = val;
    }

    private createEvent(type: BoutEventType, side?: BoutSide, card?: EventCardArgs): Omit<IBoutEvent, "id"> {
        let description = "";
        let fencerName = side === BoutSide.Fencer1 ? this.fencer1.name : side === BoutSide.Fencer2 ? this.fencer2.name : "";
        if (fencerName && this.fencer1.name === this.fencer2.name) {
            fencerName += ` (${side === BoutSide.Fencer1 ? this.fencer1.team : this.fencer2.team})`;
        }
        switch (type) {
            case BoutEventType.Start:
                description = "Bout started";
                break;
            case BoutEventType.End:
                if (!this.winner) {
                    description = "Bout ended - No winner";
                } else {
                    const winnerName = this.winner === BoutSide.Fencer1 ? this.fencer1.name : this.fencer2.name;
                    description = `Bout ended - ${winnerName} won`;
                }
                break;
            case BoutEventType.SwitchSides:
                description = "Fencers switched sides";
                break;
            case BoutEventType.Forfeit:
                description = `${fencerName} forfeited the bout`;
                break;
            case BoutEventType.MedicalForfeit:
                description = `${fencerName} medically forfeited the bout`;
                break;
            case BoutEventType.RescindForfeit:
                description = `${fencerName} rescinded their forfeit`;
                break;
            case BoutEventType.Touch:
                description = `${fencerName} was awarded a touch`;
                break;
            case BoutEventType.DoubleTouch:
                description = `Double touch awarded`;
                break;
            case BoutEventType.AnnulTouch:
                description = `A touch was annulled for ${fencerName}`;
                break;
            case BoutEventType.Card:
                if (!card) break;
                description = card.add ? `${fencerName} received a ${card.color} card` : `${fencerName}'s ${card.color} card was retracted`;
                break;
            case BoutEventType.Timeout:
                description = `${fencerName} took a timeout`;
                break;
            case BoutEventType.TimeoutEnd:
                description = `Timeout ended for ${fencerName}`;
                break;
            case BoutEventType.TimeoutCancel:
                description = `Timeout cancelled for ${fencerName}`;
                break;
            case BoutEventType.ClockEnd:
                description = `Bout time ran out`;
                break;
            case BoutEventType.Priority:
                description = `${fencerName} was given priority`;
                break;
            case BoutEventType.PriorityRemoved:
                description = `Priority was removed from ${fencerName}`;
                break;
            case BoutEventType.RandomPriority:
                description = `Priority was randomly awarded to ${fencerName}`;
                break;
            case BoutEventType.MedicalSubstitution:
                description = `${fencerName} was substituted out due to a medical issue`;
        }

        if (this.revising) {
            description = `Revision: ${description}`;
        }

        return {
            boutTime: this.boutTime,
            description,
            localTime: Date.now(),
            type,
            fencer1Cards: this.fencer1.cards,
            fencer2Cards: this.fencer2.cards,
            score1: this.fencer1.score,
            score2: this.fencer2.score
        };
    }

    //#region Control
    public startBout(revising: boolean) {
        if (revising) {
            this.setRevising(true);
        } else {
            const event = this.createEvent(BoutEventType.Start);
            this.DB.createBoutEvent(this.id, event);
            this.DB.updateBout(this.id, { startedAt: event.localTime }, this.boutTime);
        }
    }

    public endBout(meetId: string) {
        if (!this.revising || (this.revising && this.winner !== this.revisingWinner)) {
            const event = this.createEvent(BoutEventType.End);
            this.DB.createBoutEvent(this.id, event);
        }
        this.setRevising(false);
        // this.DB.updateBout(this.id, { endedAt: Date.now() }, this.boutTime);
        this.DB.endBout(this.id, meetId, this.winner || 0);
    }

    public forfeit(time: number, side: BoutSide, value: boolean, medical: boolean) {
        this.boutTime = time;

        if (side === BoutSide.Fencer1) {
            this.setFencer1Forfeit(value);
            this.fencer1.forfeit = value;
        } else if (side === BoutSide.Fencer2) {
            this.setFencer2Forfeit(value);
            this.fencer2.forfeit = value;
        }

        if (medical && (side === BoutSide.Fencer1 ? this.fencer1.score >= this.fencer2.score : this.fencer1.score <= this.fencer2.score)) {
            this.fencer1.score = 0;
            this.fencer2.score = 0;
            this.setFencer1Score(0);
            this.setFencer2Score(0);
        }

        const event = this.createEvent(
            value ? (medical ? BoutEventType.MedicalForfeit : BoutEventType.Forfeit) : BoutEventType.RescindForfeit,
            side
        );
        this.DB.createBoutEvent(this.id, event);
        const oldName = side === BoutSide.Fencer1 ? this.fencer1.name : this.fencer2.name;
        this.DB.changeNamesForEvents(this.id, oldName, "No fencer");
        const updates = {
            [`fencer${side}/forfeit`]: value,
            [`fencer${side}/medicalForfeit`]: value && medical,
            [`fencer1/score`]: this.fencer1.score,
            [`fencer2/score`]: this.fencer2.score
        };
        this.DB.updateBout(this.id, updates, this.boutTime);
        this.DB.endBout(this.id, this.meetId, this.winner || 0);
        if (value) {
            this.DB.endBout(this.id, this.meetId, this.winner || 0);
        } else if (!this.fencer1.forfeit && !this.fencer2.forfeit) {
            this.DB.unendBout(this.id, this.meetId, this.winner || 0);
        }
    }

    public subFencer(side: BoutSide, fencer: IExistingFencer | IWriteInFencer, medical: boolean) {
        const weaponStr = { Sabre: "S", Foil: "F", Epee: "E" }[this.weapon];
        const sideStr = side === BoutSide.Fencer1 ? "left" : "right";

        if (medical) {
            const event = this.createEvent(BoutEventType.MedicalSubstitution, side);
            this.DB.createBoutEvent(this.id, event);
        }

        this[side === BoutSide.Fencer1 ? "fencer1" : "fencer2"].name = `${fencer.firstName} ${fencer.lastName}`;

        // First round of the weapon
        if (this.boutOrder <= 2) {
            // Get 123/456 position of fencer
            const currBoutStrip = `${weaponStr}${(side === BoutSide.Fencer1 ? leftOrder : rightOrder)[this.boutOrder]}`;
            // Figure out which other bouts they fence
            const boutsList = side === BoutSide.Fencer1 ? homeMeetOrder : awayMeetOrder;
            const indices = Object.entries(boutsList)
                .filter(([idx, val]) => val === currBoutStrip)
                .map(l => l[0]);
            const bouts = indices.map(l => this.otherBouts[l]);

            const firstBout = true;
            for (const bout of bouts) {
                if ("id" in fencer) {
                    this.DB.subBoutFencer(bout, sideStr, fencer.id, medical && firstBout, true);
                } else {
                    this.DB.subBoutWriteInFencer(bout, sideStr, fencer, medical && firstBout);
                }
            }
        } else {
            if ("id" in fencer) {
                this.DB.subBoutFencer(this.id, sideStr, fencer.id, medical, false);
            } else {
                this.DB.subBoutWriteInFencer(this.id, sideStr, fencer, medical);
            }
        }
    }

    public async subWriteInFencer(side: BoutSide, teamID: string, season: string, firstName: string, lastName: string, medical: boolean) {
        const weaponStr = { Sabre: "S", Foil: "F", Epee: "E" }[this.weapon];
        const sideStr = side === BoutSide.Fencer1 ? "left" : "right";

        if (medical) {
            const event = this.createEvent(BoutEventType.MedicalSubstitution, side);
            this.DB.createBoutEvent(this.id, event);
        }

        this[side === BoutSide.Fencer1 ? "fencer1" : "fencer2"].name = `${firstName} ${lastName}`;

        // First round of the weapon
        if (this.boutOrder <= 2) {
            // Get 123/456 position of fencer
            const currBoutStrip = `${weaponStr}${(side === BoutSide.Fencer1 ? leftOrder : rightOrder)[this.boutOrder]}`;
            // Figure out which other bouts they fence
            const boutsList = side === BoutSide.Fencer1 ? homeMeetOrder : awayMeetOrder;
            const indices = Object.entries(boutsList)
                .filter(([_, val]) => val === currBoutStrip)
                .map(l => Number(l[0]));
            const bouts = indices.map(l => this.otherBouts[l]);

            const fencer = await this.DB.subBoutWriteIn(bouts, teamID, season, this.weapon, sideStr, { firstName, lastName }, medical);
            return fencer;
        } else {
            const fencer = await this.DB.subBoutWriteIn([this.id], teamID, season, this.weapon, sideStr, { firstName, lastName }, medical);
            return fencer;
        }
    }

    public removeFencer(side: BoutSide) {
        this.DB.removeBoutFencer(this.id, side === BoutSide.Fencer1 ? "left" : "right", true);
    }

    public switchSides(time: number) {
        this.boutTime = time;

        this.switchedSides = !this.switchedSides;
        this.setSwitchedSides(this.switchedSides);

        const event = this.createEvent(BoutEventType.SwitchSides);
        this.DB.createBoutEvent(this.id, event);
        this.DB.updateBout(this.id, { switchedSides: this.switchedSides }, this.boutTime);
    }
    //#endregion Control

    //#region Touches
    public addTouch(time: number, side: BoutSide) {
        this.boutTime = time;

        if (side === BoutSide.Fencer1) {
            this.setFencer1Score(++this.fencer1.score);
        } else {
            this.setFencer2Score(++this.fencer2.score);
        }

        const newScore = side === BoutSide.Fencer1 ? this.fencer1.score : this.fencer2.score;

        const event = this.createEvent(BoutEventType.Touch, side);
        this.DB.createBoutEvent(this.id, event);
        this.DB.updateBout(this.id, { [`fencer${side}/score`]: newScore }, this.boutTime);
    }

    public addDoubleTouch(time: number) {
        this.boutTime = time;

        this.setFencer1Score(++this.fencer1.score);
        this.setFencer2Score(++this.fencer2.score);

        const event = this.createEvent(BoutEventType.DoubleTouch);
        this.DB.createBoutEvent(this.id, event);
        this.DB.updateBout(
            this.id,
            {
                "fencer1/score": this.fencer1.score,
                "fencer2/score": this.fencer2.score
            },
            this.boutTime
        );
    }

    public annulTouch(time: number, side: BoutSide) {
        this.boutTime = time;

        if (side === BoutSide.Fencer1) {
            this.fencer1.score--;
            if (this.fencer1.score < 0) return;
            this.setFencer1Score(this.fencer1.score);
        } else {
            this.fencer2.score--;
            if (this.fencer2.score < 0) return;
            this.setFencer2Score(this.fencer2.score);
        }

        const newScore = side === BoutSide.Fencer1 ? this.fencer1.score : this.fencer2.score;

        const event = this.createEvent(BoutEventType.AnnulTouch, side);
        this.DB.createBoutEvent(this.id, event);
        this.DB.updateBout(this.id, { [`fencer${side}/score`]: newScore }, this.boutTime);
    }
    //#endregion Touches

    //#region Timeouts
    public startTimeout(time: number, side: BoutSide) {
        this.boutTime = time;

        const event = this.createEvent(BoutEventType.Timeout, side);
        this.DB.createBoutEvent(this.id, event);
        this.DB.updateBout(this.id, {}, this.boutTime);
    }

    public endTimeout(time: number, side: BoutSide) {
        this.boutTime = time;

        const event = this.createEvent(BoutEventType.TimeoutEnd, side);
        this.DB.createBoutEvent(this.id, event);
        this.DB.updateBout(this.id, { [`fencer${side}/timeout`]: true }, this.boutTime);
    }

    public cancelTimeout(time: number, side: BoutSide) {
        this.boutTime = time;

        const event = this.createEvent(BoutEventType.TimeoutCancel, side);
        this.DB.createBoutEvent(this.id, event);
        this.DB.updateBout(this.id, {}, this.boutTime);
    }
    //#endregion Timeouts

    //#region Cards
    public setYellowCard(time: number, side: BoutSide, val: boolean) {
        this.boutTime = time;

        if (side === BoutSide.Fencer1) {
            if (this.fencer1.cards.yellow === val) return;
            this.fencer1.cards.yellow = val;
            this.setFencer1Cards(this.fencer1.cards);
        } else {
            if (this.fencer2.cards.yellow === val) return;
            this.fencer2.cards.yellow = val;
            this.setFencer2Cards(this.fencer2.cards);
        }

        const event = this.createEvent(BoutEventType.Card, side, {
            color: "yellow",
            add: val
        });
        this.DB.createBoutEvent(this.id, event);
        this.DB.updateBout(this.id, { [`fencer${side}/cardInfo/yellow`]: val }, this.boutTime);
    }

    public setRedCard(time: number, side: BoutSide, val: number) {
        this.boutTime = time;

        const otherSide = side === BoutSide.Fencer1 ? BoutSide.Fencer2 : BoutSide.Fencer1;

        const oldCardCount = (side === BoutSide.Fencer1 ? this.fencer1 : this.fencer2).cards.red;

        const diff = val - oldCardCount;
        const add = diff > 0;

        let newScore = 0;

        if (side === BoutSide.Fencer1) {
            if (this.fencer1.cards.red === val) return;
            this.fencer1.cards.red = val;
            this.fencer2.score += diff;
            newScore = this.fencer2.score;
            this.setFencer2Score(this.fencer2.score);
            this.setFencer1Cards(this.fencer1.cards);
        } else {
            if (this.fencer2.cards.red === val) return;
            this.fencer2.cards.red = val;
            this.fencer1.score += diff;
            newScore = this.fencer1.score;
            this.setFencer1Score(this.fencer1.score);
            this.setFencer2Cards(this.fencer2.cards);
        }

        const event = this.createEvent(BoutEventType.Card, side, {
            color: "red",
            add
        });
        this.DB.createBoutEvent(this.id, event);
        const updates = {
            [`fencer${side}/cardInfo/red`]: val,
            [`fencer${otherSide}/score`]: newScore
        };
        this.DB.updateBout(this.id, updates, this.boutTime);
    }

    public setBlackCard(time: number, side: BoutSide, val: boolean) {
        this.boutTime = time;

        if (side === BoutSide.Fencer1) {
            if (this.fencer1.cards.black === val) return;
            this.fencer1.cards.black = val;
            this.setFencer1Cards(this.fencer1.cards);
        } else {
            if (this.fencer2.cards.black === val) return;
            this.fencer2.cards.black = val;
            this.setFencer2Cards(this.fencer2.cards);
        }

        const event = this.createEvent(BoutEventType.Card, side, {
            color: "black",
            add: val
        });
        this.DB.createBoutEvent(this.id, event);
        this.DB.updateBout(this.id, { [`fencer${side}/cardInfo/black`]: val }, this.boutTime);
    }
    //#endregion Cards

    //#region Other
    public togglePriority(time: number, side: BoutSide) {
        this.boutTime = time;

        let event: Omit<IBoutEvent, "id"> | undefined = undefined;

        if (this.boutPriority === side) {
            this.boutPriority = null;
            this.setPriority(null);
            event = this.createEvent(BoutEventType.PriorityRemoved, side);
            this.DB.updateBout(this.id, { priority: null }, this.boutTime);
        } else {
            this.boutPriority = side;
            this.setPriority(side);
            event = this.createEvent(BoutEventType.Priority, side);
            this.boutTime = 60000;
            this.DB.updateBout(this.id, { priority: side === 1 ? "left" : "right" }, 60000);
        }

        this.DB.createBoutEvent(this.id, event);
    }

    public randomPriority(time: number) {
        this.boutTime = time;

        const side = Math.floor(Math.random() * 2) + 1;

        this.boutPriority = side;
        this.setPriority(side);
        const event = this.createEvent(BoutEventType.RandomPriority, side);
        this.DB.createBoutEvent(this.id, event);
        this.DB.updateBout(this.id, { priority: side === 1 ? "left" : "right" }, this.boutTime);
    }

    public clockEnd() {
        this.boutTime = 0;

        const event = this.createEvent(BoutEventType.ClockEnd);
        this.DB.createBoutEvent(this.id, event);
        this.DB.updateBout(this.id, {}, 0);
    }

    public setTimer(time: number) {
        this.boutTime = time;
    }
    //#endregion Other
}
