import { Box, Typography } from "@mui/material";
import TbTPage from "../../../components/TbTPage/TbTPage";

const ErrorPage = ({ message, code }: { message?: string; code?: string | number }) => {
    return (
        <TbTPage>
            <Box
                style={{
                    height: "calc(100vh - 115px)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Typography variant="h2" style={{ fontSize: 72 }}>
                    {code || 500}
                </Typography>
                <Typography variant="h4" style={{ fontWeight: 300, fontSize: 24, marginTop: 10 }}>
                    {message || "An error occurred."}
                </Typography>
            </Box>
        </TbTPage>
    );
};

export default ErrorPage;
