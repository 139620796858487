const escape = (text: string) => {
    return `"${text.replace('"', '""')}"`
}

const newLine = (tokens: any[]) => {
    return `${tokens.map(t => escape(t.toString())).join(",")}`
}

/**
 * Generates and downloads a csv file for HS attendance.
 * @param fencerData A dictionary of fencer names to their attendance for each meet (P for present, 
 *             N/A if no attendance, A if absent)
 * @param meets The meet names
 */
export default function generateHSAttendanceCsv(fencerData: Record<string, string[]>, meets: string[]) {
    const header = newLine(["Meets", ...Object.keys(fencerData)]);

    const lines: string[] = [];
    lines.push(header);

    const totalAttended: number[] = [];
    for (const attendance of Object.values(fencerData)) {
        totalAttended.push(
            attendance.reduce((accumulator, attended) => accumulator += (attended === "P" || attended === "C") ? 1 : 0, 0))
    }

    const totalAttendedLine = newLine(["Total", ...totalAttended]);
    const percentageAttendedLine = newLine(["Percentage", 
        ...totalAttended.map(t => `${(t / meets.length * 100).toFixed(0)}%`)]);

    lines.push(totalAttendedLine);
    lines.push(percentageAttendedLine);

    for (let i = 0; i < meets.length; i++) {
        const tokens = [meets[i]];

        for (const attendance of Object.values(fencerData)) {
            tokens.push(attendance[i]);
        }

        lines.push(newLine(tokens));
    }

    return lines.join('\n');
}