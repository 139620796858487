import { ReactElement } from "react";
import useDivision from "../../hooks/divison";
import { Division } from "../../types";
import "./TbTPage.css";

const TbTPage: React.FC<{ className?: string }> = ({ children, className }: { children: ReactElement[]; className: string }) => {
    const division = useDivision();

    const divClass = {
        [Division.NCAA]: "college",
        [Division.NJSIAA]: "hs",
        null: "hs"
    }[division || "null"];

    return <div className={`tbtPage ${divClass} ${className ?? ""}`}>{children}</div>;
};

export default TbTPage;
