import { useEffect } from "react";
import * as ReactDOM from "react-dom";

import { PersistGate } from "redux-persist/integration/react";
import { Provider, useDispatch } from "react-redux";
import storeRaw from "./utils/store";
import fire from "./utils/firebaseSetup";

import Home from "./App";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useDatabase from "./hooks/database";

import "./utils/Promise";

const { store, persistor } = storeRaw();

const App = () => {
    const dispatch = useDispatch();
    const DB = useDatabase();

    const authListener = () => {
        fire.auth().onAuthStateChanged(async user => {
            if (user && user !== null) {
                dispatch({ type: "updateUid", uid: user.uid });
                dispatch({ type: "updateUser", user });
                DB.getCurrentUserInfo(userInfo => {
                    if (userInfo.status === "fail") return;
                    dispatch({ type: "setUserInfoV2", payload: userInfo.data });
                });
            } else if (store.getState().userInfo) {
                dispatch({ type: "resetStateV2" });
                sessionStorage.removeItem("signedIn");
                localStorage.removeItem("persist:root");
            }
        });
    };

    useEffect(() => {
        authListener();

        const resetCache = localStorage.getItem("lastLoginWasFromV2");

        if (!resetCache) {
            dispatch({ type: "resetState" });
            localStorage.removeItem("persist:root");
            localStorage.removeItem("v2.dualMeetBouts");
            localStorage.removeItem("v2.organizations");
            localStorage.removeItem("v2.dualMeets");
            localStorage.removeItem("v2.teams");
            localStorage.setItem("lastLoginWasFromV2", "true");
        }

        const boutKey = localStorage.getItem("boutKey");

        if (boutKey) {
            dispatch({ type: "setBoutKey", payload: boutKey });
        } else {
            dispatch({ type: "createBoutKey" });
        }
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Home />
        </LocalizationProvider>
    );
};

// eslint-disable-next-line
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);

export const modalBoxStyle = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    maxWidth: "800px",
    maxHeight: "70vh",
    overflow: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
};

export const modalBoxStyleNarrower = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 600 ? "30%" : "90%",
    maxWidth: "90vw",
    maxHeight: "70vh",
    overflow: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
};
