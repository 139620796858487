import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { IUser, UserFlag } from "../types";
import { genRandomStr } from "./helpers";
import { CURRENT_SEASON_STR } from "./season";

const persistConfig = {
    key: "root",
    storage
};

export interface ReduxState {
    /**
     * Currently selected season, for use to filter dual meets, team records, etc
     */
    season: string;
    /**
     * User info of currently logged in user, is `null` if not logged in
     */
    userInfo: IUser | null;
    /**
     * Whether the user is a site admin. Detects both the `SuperAdmin` and `UberAdmin` flags.
     */
    siteAdmin: boolean;
    /**
     * Most recently entered scoring PIN.
     */
    enteredPIN?: number;
    /**
     * Most recently entered scoring PIN expiration date.
     */
    enteredPINExpiration?: number;
    /**
     * Bout key to detect which device is currently scoring.
     */
    boutKey: string;
}

export const getInitialState = (): ReduxState => ({
    season: CURRENT_SEASON_STR,
    userInfo: null,
    siteAdmin: false,
    boutKey: genRandomStr()
});

export const initialState = getInitialState();

function teamDataReducer(state = initialState, action: { type: string } & Record<string, unknown>) {
    switch (action.type) {
        case "setBoutKey":
            localStorage.setItem("boutKey", action.payload as string);
            return { ...state, boutKey: action.payload };
        case "createBoutKey": {
            const key = genRandomStr();
            localStorage.setItem("boutKey", key);
            return { ...state, boutKey: key };
        }
        // V2
        case "switchSeasonV2":
            return { ...state, season: action.payload };
        case "setUserTeamsV2":
            return {
                ...state,
                userInfo: { ...state.userInfo, teams: action.payload }
            };
        case "setUserInfoV2": {
            const superAdmin = Boolean(((action.payload as IUser | null)?.flags || 0) & UserFlag.SuperAdmin);
            const uberAdmin = Boolean(((action.payload as IUser | null)?.flags || 0) & UserFlag.UberAdmin);
            return { ...state, userInfo: action.payload, siteAdmin: superAdmin || uberAdmin };
        }
        case "setEnteredPINV2":
            // 4 hours long
            return {
                ...state,
                enteredPIN: action.payload,
                enteredPINExpiration: Date.now() + 14400000
            };
        case "resetEnteredPINV2":
            return {
                ...state,
                enteredPIN: undefined,
                enteredPINExpiration: undefined
            };
        case "resetStateV2":
            return getInitialState();
        default:
            return state;
    }
}

const persistedReducer = persistReducer(persistConfig, teamDataReducer);

export default () => {
    const store = createStore(persistedReducer);
    const persistor = persistStore(store);
    return { store, persistor };
};
