export interface NCAADivision {
    name: string;
    id: string;
}

export interface NCAARegion {
    name: string;
    id: string;
}

export interface Conference {
    name: string;
    id: string;
}

export interface HSDistrict {
    name: string;
    id: string;
}

export const HighSchoolDistricts: HSDistrict[] = [
    { name: "District 1", id: "d1" },
    { name: "District 2", id: "d2" },
    { name: "District 3", id: "d3" },
    { name: "District 4", id: "d4" },
    { name: "District 5", id: "d5" },
    { name: "District 6", id: "d6" },
    { name: "Independent", id: "ind" }
];

export const HighSchoolConferences: Conference[] = [
    { name: "Burlington County Scholastic League", id: "BCSL" },
    { name: "Big North Conference", id: "BNC" },
    { name: "Cape Atlantic League", id: "CAL" },
    { name: "Colonial Conference", id: "CC" },
    { name: "Colonial Valley Conference", id: "CVC" },
    { name: "Greater Middlesex Conference", id: "GMC" },
    { name: "Hudson County Interscholastic Athletic League", id: "HCIAL" },
    { name: "Northwest Jersey Athletic Conference", id: "NJAC" },
    { name: "New Jersey Association of Independent Schools", id: "NJAIS" },
    { name: "North Jersey Interscholastic Conference", id: "NJIC" },
    { name: "Olympic Conference", id: "OC" },
    { name: "Shore Conference", id: "SC" },
    { name: "Super Essex Conference", id: "SEC" },
    { name: "Skyland Conference", id: "SKY" },
    { name: "Tri County Conference", id: "TCC" },
    { name: "Union County Conference", id: "UCC" }
];

export const NCAADivisions: NCAADivision[] = [
    { name: "Division I", id: "div1" },
    { name: "Division II", id: "div2" },
    { name: "Division III", id: "div3" },
    { name: "Club", id: "club" }
];

export const NCAAMensConferences: Conference[] = [
    { name: "Atlantic Coast Conference", id: "ACC" },
    { name: "Big 10", id: "Big 10" },
    { name: "Big East", id: "Big East" },
    { name: "Baltimore-Washington Collegiate Fencing Conference", id: "BWCFC" },
    { name: "Ivy League", id: "Ivy League" },
    { name: "Mid-Atlantic Collegiate Fencing Association", id: "MACFA" },
    { name: "Midwest Fencing Conference", id: "MWFC" },
    { name: "Northeast Fencing Conference", id: "NFC" }
];

export const NCAAWomensConferences: Conference[] = [
    { name: "Atlantic Coast Conference", id: "ACC" },
    { name: "Big 10", id: "Big 10" },
    { name: "Big East", id: "Big East" },
    { name: "Baltimore-Washington Collegiate Fencing Conference", id: "BWCFC" },
    { name: "Eastern Women's Fencing Conference", id: "EWFC" },
    { name: "Ivy League", id: "Ivy League" },
    { name: "Midwest Fencing Conference", id: "MWFC" },
    { name: "Northeast Fencing Conference", id: "NFC" },
    { name: "National Intercollegiate Women's Fencing Association", id: "NIWFA" }
];

export const NCAARegions: NCAARegion[] = [
    { name: "Mid-Atlantic/South", id: "south" },
    { name: "Northeast", id: "northeast" },
    { name: "Midwest", id: "midwest" },
    { name: "West", id: "west" }
];
