import { Box, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import TbTPage from "../../../components/TbTPage/TbTPage";
import { ReduxState } from "../../../utils/store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useDatabase from "../../../hooks/database";
import ErrorPage from "../NotFound/NotFound";
import { IOverallRankings, UserFlag, Weapon } from "../../../types";
import { CommonLoading } from "../../../components/Loading/Loading";
import { CURRENT_SEASON_STR } from "../../../utils/season";
import StyledDropdown from "../../../components/StyledDropdown/StyledDropdown";

const Rankings = () => {
    const DB = useDatabase();
    const userInfo = useSelector((s: ReduxState) => s.userInfo);
    
    const [rankings, setRankings] = useState<IOverallRankings | null>(null);
    const [season, setSeason] = useState(CURRENT_SEASON_STR);
    const [weapon, setWeapon] = useState<Weapon>("Sabre");
    const [gender, setGender] = useState<"man" | "woman">("man");
    
    const [criticalError, setCriticalError] = useState("");

    useEffect(() => {
        if ((userInfo?.flags || 0) & UserFlag.CanSeeHSRankings) {
            DB.getFencerRankings().then(rankings => {
                if (rankings.status !== "success") {
                    return setCriticalError(rankings.data);
                }
                setRankings(rankings.data);
            }).catch(e => {
                setCriticalError((e as Error).message);
            });
        }
    }, [userInfo?.flags]);

    if (criticalError) {
        return <ErrorPage code={500} message={criticalError} />
    }

    if (!rankings) {
        return <CommonLoading />
    }

    const seasons = Object.keys(rankings || {});
    seasons.sort((a, b) => a.localeCompare(b));

    let selectedRankings = rankings[season]?.[weapon] || [];
    selectedRankings = selectedRankings.filter(l => l.gender === gender || l.gender === "both");
    selectedRankings.sort((a, b) => b.dualMeetStats.wins - a.dualMeetStats.wins || a.dualMeetStats.losses - b.dualMeetStats.losses);

    const genderDisplayStr = {
        "man": "Men's",
        "woman": "Women's"
    }[gender];

    return <TbTPage>
        <div style={{ height: 50 }}></div>
        <Typography
            variant="h3"
            sx={{
                fontFamily: "Lexend Deca",
                fontWeight: 400,
                marginBottom: "30px"
            }}
        >
            NJSIAA Fencer Rankings
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
            <StyledDropdown displayText={`${season || "No"} season`}>
                {seasons.map(l => (
                    <MenuItem key={`switchSeason${l}`} onClick={() => setSeason(l)}>
                        {seasons.length === 0 ? "No" : l} season
                    </MenuItem>
                ))}
            </StyledDropdown>

            <Box sx={{ ml: 1 }}>
                <StyledDropdown displayText={weapon}>
                    <MenuItem key="sabre" onClick={() => setWeapon("Sabre")}>Sabre</MenuItem>
                    <MenuItem key="foil" onClick={() => setWeapon("Foil")}>Foil</MenuItem>
                    <MenuItem key="epee" onClick={() => setWeapon("Epee")}>Epee</MenuItem>
                </StyledDropdown>
            </Box>

            <Box sx={{ ml: 1 }}>
                <StyledDropdown displayText={genderDisplayStr}>
                    <MenuItem key={`genderMen`} onClick={() => setGender("man")}>Men's</MenuItem>
                    <MenuItem key={`genderWomen`} onClick={() => setGender("woman")}>Women's</MenuItem>
                </StyledDropdown>
            </Box>
        </Box>

        <Box sx={{ mx: 2, mb: 2 }}>
            <TableContainer component={Paper} sx={{ maxWidth: 600, width: "100%", margin: "auto" }}>
                <Typography fontFamily="Lexend Deca" fontWeight={500} fontSize={20} mt={2}>
                    Rankings for {genderDisplayStr} {weapon} {season}
                </Typography>
                <Table sx={{ width: "100%" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Rank</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Dual Meet Record</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { selectedRankings.map(l => <TableRow key={`ranking${l.id}`} hover>
                            <TableCell>{ l.rank }{ l.tied ? "T" : "" }</TableCell>
                            <TableCell>{ l.firstName } { l.lastName }</TableCell>
                            <TableCell>{ l.dualMeetStats.wins }-{ l.dualMeetStats.losses }</TableCell>
                        </TableRow>) }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </TbTPage>
}

export default Rankings;