import { IDualMeet, IDualMeet_DB } from "../types";

export const meetsRawEqual = (a: IDualMeet_DB, b: IDualMeet_DB) => {
    if (!a || !b) return false;
    if (a.bouts.length !== b.bouts.length) return false;
    for (let i = 0; i < a.bouts.length; i++) {
        if (a.bouts[i].id !== b.bouts[i].id || a.bouts[i].winner !== b.bouts[i].winner) return false;
    }
    if (a.color !== b.color) return false;
    if (a.correspondingMeet !== b.correspondingMeet) return false;
    if (a.createdAt !== b.createdAt) return false;
    if (a.createdBy !== b.createdBy) return false;
    if (a.endedAt !== b.endedAt) return false;
    if (a.eventID !== b.eventID) return false;
    if (a.id !== b.id) return false;
    if (a.lineupsVisible !== b.lineupsVisible) return false;
    if (a.lineupsVisibleEpee !== b.lineupsVisibleEpee) return false;
    if (a.lineupsVisibleFoil !== b.lineupsVisibleFoil) return false;
    if (a.lineupsVisibleSabre !== b.lineupsVisibleSabre) return false;
    if (a.name !== b.name) return false;
    if (a.pin1 !== b.pin1) return false;
    if (a.pin2 !== b.pin2) return false;
    if (a.published !== b.published) return false;
    if (a.publishedAt !== b.publishedAt) return false;
    if (a.publishedBy !== b.publishedBy) return false;
    if (a.season !== b.season) return false;
    if (a.startedAt !== b.startedAt) return false;
    if (a.team1ID !== b.team1ID) return false;
    // if (a.team1Lineup !== b.team1Lineup) return false;
    // if (a.team1WriteInData !== b.team1WriteInData) return false;
    if (a.team2ID !== b.team2ID) return false;
    // if (a.team2Lineup !== b.team2Lineup) return false;
    // if (a.team2WriteInData !== b.team2WriteInData) return false;
    if (a.type !== b.type) return false;
    return true;
};

export const meetsEqual = (a: IDualMeet, b: IDualMeet) => {
    if (a.bouts.length !== b.bouts.length) return false;
    for (let i = 0; i < a.bouts.length; i++) {
        if (a.bouts[i].id !== b.bouts[i].id || a.bouts[i].winner !== b.bouts[i].winner) return false;
    }
    if (a.color !== b.color) return false;
    if (a.correspondingMeet !== b.correspondingMeet) return false;
    if (a.createdAt !== b.createdAt) return false;
    if (a.createdBy !== b.createdBy) return false;
    if (a.endedAt !== b.endedAt) return false;
    if (a.eventID !== b.eventID) return false;
    if (a.id !== b.id) return false;
    if (a.lineupsVisible !== b.lineupsVisible) return false;
    if (a.lineupsVisibleEpee !== b.lineupsVisibleEpee) return false;
    if (a.lineupsVisibleFoil !== b.lineupsVisibleFoil) return false;
    if (a.lineupsVisibleSabre !== b.lineupsVisibleSabre) return false;
    if (a.name !== b.name) return false;
    if (a.pin1 !== b.pin1) return false;
    if (a.pin2 !== b.pin2) return false;
    if (a.published !== b.published) return false;
    if (a.publishedAt !== b.publishedAt) return false;
    if (a.publishedBy !== b.publishedBy) return false;
    if (a.season !== b.season) return false;
    if (a.startedAt !== b.startedAt) return false;
    if (a.team1 !== b.team1) return false;
    if (a.team1Lineup !== b.team1Lineup) return false;
    if (a.team2 !== b.team2) return false;
    if (a.team2Lineup !== b.team2Lineup) return false;
    if (a.type !== b.type) return false;
    return true;
};
