import { mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";
import { memo, useState } from "react";
import { IDualMeetTeam, Weapon } from "../types";

type WriteInProps = {
    roster: IDualMeetTeam["fencers"];
    teamName: string;
    home: boolean;
    onClose: () => void;
};

const WriteInTeamBox = ({ roster, teamName, home, onClose }: WriteInProps) => {
    const [updateKey, setUpdateKey] = useState(0);

    const update = () => setUpdateKey(u => u + 1);

    const [newSabreFirstName, setNewSabreFirstName] = useState("");
    const [newSabreLastName, setNewSabreLastName] = useState("");
    const [newFoilFirstName, setNewFoilFirstName] = useState("");
    const [newFoilLastName, setNewFoilLastName] = useState("");
    const [newEpeeFirstName, setNewEpeeFirstName] = useState("");
    const [newEpeeLastName, setNewEpeeLastName] = useState("");

    const addFencer = (weapon: Weapon) => {
        const firstName = weapon === "Sabre" ? newSabreFirstName : weapon === "Foil" ? newFoilFirstName : newEpeeFirstName;
        const lastName = weapon === "Sabre" ? newSabreLastName : weapon === "Foil" ? newFoilLastName : newEpeeLastName;
        if (firstName.trim() !== "" && lastName.trim() !== "") {
            roster[weapon].push({
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                teamName: teamName.trim(),
                home: 0,
                away: 0
            });
            (weapon === "Sabre" ? setNewSabreFirstName : weapon === "Foil" ? setNewFoilFirstName : setNewEpeeFirstName)("");
            (weapon === "Sabre" ? setNewSabreLastName : weapon === "Foil" ? setNewFoilLastName : setNewEpeeLastName)("");
        }
    };

    const updateStrip = (fencer: IDualMeetTeam["fencers"]["Sabre"]["0"], weapon: Weapon, home: boolean, strip: number) => {
        for (const fencer of roster[weapon]) {
            if (fencer.home === strip) {
                fencer.home = 0;
            }
            if (fencer.away === strip) {
                fencer.away = 0;
            }
        }
        fencer[home ? "home" : "away"] = strip;
        update();
    };

    const deleteFencer = (fencer: IDualMeetTeam["fencers"]["Sabre"]["0"], weapon: Weapon) => {
        roster[weapon] = roster[weapon].filter(l => l !== fencer);
        update();
    };

    return (
        <div>
            <Typography variant="h5" fontFamily="Lexend Deca">
                Sabre
            </Typography>
            <div key={`zzzz${updateKey}`} style={{ marginLeft: "10px" }}>
                {roster.Sabre.map(l => (
                    <div
                        key={`epeeFencer${l.firstName}${l.lastName}`}
                        style={{
                            fontSize: 18,
                            alignItems: "center",
                            display: "flex",
                            marginTop: "10px"
                        }}
                    >
                        <Select
                            size="small"
                            label="Strip"
                            placeholder="Strip"
                            value={l[home ? "home" : "away"]}
                            sx={{ width: 75, marginRight: "15px" }}
                        >
                            <MenuItem
                                value={home ? 3 : 6}
                                onClick={() => updateStrip(l, "Sabre", home, home ? 3 : 6)}
                                key={`updateStripAazxcv${l.firstName}${l.lastName}`}
                            >
                                {home ? 3 : 6}
                            </MenuItem>
                            <MenuItem
                                value={home ? 1 : 5}
                                onClick={() => updateStrip(l, "Sabre", home, home ? 1 : 5)}
                                key={`updateStripBazxcv${l.firstName}${l.lastName}`}
                            >
                                {home ? 1 : 5}
                            </MenuItem>
                            <MenuItem
                                value={home ? 2 : 4}
                                onClick={() => updateStrip(l, "Sabre", home, home ? 2 : 4)}
                                key={`updateStripCazxcv${l.firstName}${l.lastName}`}
                            >
                                {home ? 2 : 4}
                            </MenuItem>
                            <MenuItem
                                value={0}
                                onClick={() => updateStrip(l, "Sabre", home, 0)}
                                key={`updateStripSubasdff${l.firstName}${l.lastName}`}
                            >
                                Sub
                            </MenuItem>
                        </Select>
                        {l.firstName} {l.lastName}
                        <IconButton onClick={() => deleteFencer(l, "Sabre")}>
                            <Icon path={mdiDelete} size={1} horizontal vertical rotate={180} />
                        </IconButton>
                    </div>
                ))}
            </div>
            <div style={{ display: "flex", margin: "10px 0 10px 10px" }}>
                <TextField
                    label="First name"
                    placeholder="First name"
                    size="small"
                    value={newSabreFirstName}
                    onChange={e => setNewSabreFirstName(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="Last name"
                    placeholder="Last name"
                    size="small"
                    value={newSabreLastName}
                    onChange={e => setNewSabreLastName(e.target.value)}
                    sx={{ margin: "0 10px" }}
                    fullWidth
                />
                <Button variant="contained" onClick={() => addFencer("Sabre")}>
                    Add
                </Button>
            </div>
            <Typography variant="h6" fontFamily="Lexend Deca">
                Foil
            </Typography>
            <div key={`zxzzz${updateKey}`} style={{ marginLeft: "10px" }}>
                {roster.Foil.map(l => (
                    <div
                        key={`epeeFencer${l.firstName}${l.lastName}`}
                        style={{
                            fontSize: 18,
                            alignItems: "center",
                            display: "flex",
                            marginTop: "10px"
                        }}
                    >
                        <Select
                            size="small"
                            label="Strip"
                            placeholder="Strip"
                            value={l[home ? "home" : "away"]}
                            sx={{ width: 75, marginRight: "15px" }}
                        >
                            <MenuItem
                                value={home ? 3 : 6}
                                onClick={() => updateStrip(l, "Foil", home, home ? 3 : 6)}
                                key={`uptripAaaxcv${l.firstName}${l.lastName}`}
                            >
                                {home ? 3 : 6}
                            </MenuItem>
                            <MenuItem
                                value={home ? 1 : 5}
                                onClick={() => updateStrip(l, "Foil", home, home ? 1 : 5)}
                                key={`uptripBaaxcv${l.firstName}${l.lastName}`}
                            >
                                {home ? 1 : 5}
                            </MenuItem>
                            <MenuItem
                                value={home ? 2 : 4}
                                onClick={() => updateStrip(l, "Foil", home, home ? 2 : 4)}
                                key={`uptripCaaxcv${l.firstName}${l.lastName}`}
                            >
                                {home ? 2 : 4}
                            </MenuItem>
                            <MenuItem
                                value={0}
                                onClick={() => updateStrip(l, "Foil", home, 0)}
                                key={`updateStripSubascxvdff${l.firstName}${l.lastName}`}
                            >
                                Sub
                            </MenuItem>
                        </Select>
                        {l.firstName} {l.lastName}
                        <IconButton onClick={() => deleteFencer(l, "Foil")}>
                            <Icon path={mdiDelete} size={1} horizontal vertical rotate={180} />
                        </IconButton>
                    </div>
                ))}
            </div>
            <div style={{ display: "flex", margin: "10px 0 10px 10px" }}>
                <TextField
                    label="First name"
                    placeholder="First name"
                    size="small"
                    value={newFoilFirstName}
                    onChange={e => setNewFoilFirstName(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="Last name"
                    placeholder="Last name"
                    size="small"
                    value={newFoilLastName}
                    onChange={e => setNewFoilLastName(e.target.value)}
                    sx={{ margin: "0 10px" }}
                    fullWidth
                />
                <Button variant="contained" onClick={() => addFencer("Foil")}>
                    Add
                </Button>
            </div>
            <Typography variant="h6" fontFamily="Lexend Deca">
                Epee
            </Typography>
            <div key={`zzyz${updateKey}`} style={{ marginLeft: "10px" }}>
                {roster.Epee.map(l => (
                    <div
                        key={`epeeFencer${l.firstName}${l.lastName}`}
                        style={{
                            fontSize: 18,
                            alignItems: "center",
                            display: "flex",
                            marginTop: "10px"
                        }}
                    >
                        <Select
                            size="small"
                            label="Strip"
                            placeholder="Strip"
                            value={l[home ? "home" : "away"]}
                            sx={{ width: 75, marginRight: "15px" }}
                        >
                            <MenuItem
                                value={home ? 3 : 6}
                                onClick={() => updateStrip(l, "Epee", home, home ? 3 : 6)}
                                key={`upipAaz${l.firstName}${l.lastName}`}
                            >
                                {home ? 3 : 6}
                            </MenuItem>
                            <MenuItem
                                value={home ? 1 : 5}
                                onClick={() => updateStrip(l, "Epee", home, home ? 1 : 5)}
                                key={`upipBaz${l.firstName}${l.lastName}`}
                            >
                                {home ? 1 : 5}
                            </MenuItem>
                            <MenuItem
                                value={home ? 2 : 4}
                                onClick={() => updateStrip(l, "Epee", home, home ? 2 : 4)}
                                key={`upipCaz${l.firstName}${l.lastName}`}
                            >
                                {home ? 2 : 4}
                            </MenuItem>
                            <MenuItem
                                value={0}
                                onClick={() => updateStrip(l, "Epee", home, 0)}
                                key={`updateStripSubasdxcvff${l.firstName}${l.lastName}`}
                            >
                                Sub
                            </MenuItem>
                        </Select>
                        {l.firstName} {l.lastName}
                        <IconButton onClick={() => deleteFencer(l, "Epee")}>
                            <Icon path={mdiDelete} size={1} horizontal vertical rotate={180} />
                        </IconButton>
                    </div>
                ))}
            </div>
            <div style={{ display: "flex", margin: "10px 0 0 10px" }}>
                <TextField
                    label="First name"
                    placeholder="First name"
                    size="small"
                    value={newEpeeFirstName}
                    onChange={e => setNewEpeeFirstName(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="Last name"
                    placeholder="Last name"
                    size="small"
                    value={newEpeeLastName}
                    onChange={e => setNewEpeeLastName(e.target.value)}
                    sx={{ margin: "0 10px" }}
                    fullWidth
                />
                <Button variant="contained" onClick={() => addFencer("Epee")}>
                    Add
                </Button>
            </div>
            <Button variant="contained" sx={{ margin: "20px auto 0 auto" }} onClick={onClose} fullWidth>
                Save
            </Button>
        </div>
    );
};

export default WriteInTeamBox;
