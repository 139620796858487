import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { CommonLoading } from "../../../components/Loading/Loading";

import TbTPage from "../../../components/TbTPage/TbTPage";
import DualMeetInfoComponent from "../../../components/DualMeetInfo";
import SeasonSwitcher from "../../../components/SeasonTeamSwitcher/SeasonSwitcher";
import { ITeam, IExistingFencer, Weapon, IDualMeet, BoutSide, UserFlag, IOrganization } from "../../../types";
import { meetScoreFromBouts, dualMeetSquadScores, boutWinner, rosterMapFromTeam } from "../../../utils/helpers";
import { ReduxState } from "../../../utils/store";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import "./TeamDetail.css";
import { useWindowSize } from "../../../hooks/window";
import {
    Box,
    Dialog,
    DialogTitle,
    Link,
    ListItemButton,
    MenuItem,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import useDatabase from "../../../hooks/database";
import ErrorPage from "../NotFound/NotFound";

import { HighSchoolConferences, HighSchoolDistricts } from "../../../utils/ncaaConference";
import { DBResult, isSuccess } from "../../../utils/database";
import StyledDropdown from "../../../components/StyledDropdown/StyledDropdown";

import { mdiExport } from "@mdi/js";
import Icon from "@mdi/react";
import generateHSAttendanceCsv from "../../../utils/csvExporter";
import RosterAutoUpdateDialog from "../../../components/RosterAutoUpdateDialog";

interface FencerListItem {
    fencer: IExistingFencer;
    role: { home: number; away: number; power?: number };
    records: Record<string, { wins: number; losses: number }>;
}

const FencerListItem = ({ fencer, role, records }: FencerListItem) => {
    return (
        <ListItemButton sx={{ width: "100%" }} href={`/fencer/${fencer.id}`}>
            <div className="teamDetailRosterMemberRole">{role.home || "Sub"}</div>
            <div className="teamDetailRosterMemberRole" style={{ marginRight: "10px" }}>
                {role.away || "Sub"}
            </div>
            <div className="teamDetailRosterMemberName">
                {fencer.firstName} {fencer.lastName} {fencer.gradYear ? `- ${fencer.gradYear}` : ""}
            </div>
            <div className="teamDetailRosterMemberRecord">
                {records[fencer.id]?.wins || 0}-{records[fencer.id]?.losses || 0}
            </div>
        </ListItemButton>
    );
};

export default function TeamDetail() {
    const { id } = useParams<{ id: string }>();

    const size = useWindowSize();
    const DB = useDatabase();
    const history = useHistory();

    const userInfo = useSelector((s: ReduxState) => s.userInfo);
    const rosterSeason = useSelector((s: ReduxState) => s.season);

    const [notFound, setNotFound] = useState(false);
    const [teamData, setTeamData] = useState<ITeam | null>(null);
    const [pairTeam, setPairTeam] = useState<ITeam | null>(null);
    const [processedRoster, setProcessedRoster] = useState<Record<Weapon, Map<IExistingFencer, { home: number; away: number }>>>({
        Sabre: new Map(),
        Foil: new Map(),
        Epee: new Map()
    });
    const [dualMeets, setDualMeets] = useState<IDualMeet[]>([]);
    const [scoresForMeets, setScoresForMeets] = useState<Record<string, [number, number]>>({});
    const [squadScores, setSquadScores] = useState<Record<Weapon, { wins: number; losses: number }>>({
        Sabre: { wins: 0, losses: 0 },
        Foil: { wins: 0, losses: 0 },
        Epee: { wins: 0, losses: 0 }
    });
    const [standouts, setStandouts] = useState<{ name: string; wins: number; losses: number; weapon: Weapon }[]>([]);
    // const [boutsCache, setBoutsCache] = useState<Record<string, IDualMeetBout>>({});
    const [fencerRecords, setFencerRecords] = useState<Record<Weapon, Record<string, { wins: number; losses: number }>>>({
        Sabre: {},
        Foil: {},
        Epee: {}
    });

    const [orgData, setOrgData] = useState<IOrganization | null>(null);

    const [criticalError, setCriticalError] = useState("");
    const [snackbarError, setSnackbarError] = useState("");

    const handleTeam = async (result: DBResult<ITeam>) => {
        if (result.status === "fail") return setCriticalError(result.data);
        const { data } = result;
        setTeamData(data);
        DB.getOrganizationFromTeam(data.id).then(orgResult => {
            if (orgResult.status === "fail") return setSnackbarError(orgResult.data);
            const org = orgResult.data;
            if (org.boysTeam && org.boysTeam !== id) {
                DB.getTeam(org.boysTeam).then(pair => {
                    if (pair.status === "fail") return setSnackbarError(pair.data);
                    setPairTeam(pair.data);
                });
            }
            if (org.girlsTeam && org.girlsTeam !== id) {
                DB.getTeam(org.girlsTeam).then(pair => {
                    if (pair.status === "fail") return setSnackbarError(pair.data);
                    setPairTeam(pair.data);
                });
            }
            setOrgData(org);
        });
        if (!userInfo) {
            return;
        }
    };

    // TODO: refactor this page
    useEffect(() => {
        DB.getTeam(id, { listener: handleTeam });

        return () => {
            DB.stopListeningTeam(id, handleTeam);
        };
    }, [id]);

    useEffect(() => {
        if (!teamData) return;
        Promise.all(teamData.fencers.map(l => DB.getFencerRecord(l))).then(records => {
            const res: typeof fencerRecords = {
                Sabre: {},
                Foil: {},
                Epee: {}
            };

            for (const fencerRecord of records) {
                if (fencerRecord.status === "fail" || !fencerRecord.data.bouts) continue;
                const bouts = Object.values(fencerRecord.data.bouts);
                for (const record of bouts) {
                    if (record.season !== rosterSeason) continue;
                    const targetObj = res[record.weapon];
                    if (fencerRecord.data.id in targetObj) {
                        targetObj[fencerRecord.data.id].wins += Number(record.won);
                        targetObj[fencerRecord.data.id].losses += Number(record.lost);
                    } else {
                        targetObj[fencerRecord.data.id] = { wins: Number(record.won), losses: Number(record.lost) };
                    }
                }
            }

            setFencerRecords(res);
        });
    }, [teamData, rosterSeason]);

    useEffect(() => {
        let active = true;
        if (teamData) {
            rosterMapFromTeam(DB, teamData, rosterSeason).then(roster => setProcessedRoster(roster));
        }
        (async () => {
            if (!teamData?.dualMeets?.[rosterSeason]) return;

            const meetResults = await Promise.all(teamData.dualMeets[rosterSeason].map(l => DB.getDualMeet(l)));
            const meets = meetResults.filter(isSuccess).map(l => l.data);
            if (active) {
                setDualMeets(meets);
            }

            // Get all bouts for all dual meets
            const bouts = await Promise.all(meets.map(meet => Promise.all(meet.bouts.map(l => DB.getBout(l.id)))));

            // Scores for each meet
            const scores = bouts.map(l => meetScoreFromBouts(l.filter(isSuccess).map(j => j.data)));
            const scoresObj = {};
            for (let i = 0; i < scores.length; i++) {
                scoresObj[meets[i].id] = scores[i];
            }
            // Squad-level scores for each meet
            const squadScores = await Promise.all(meets.map(l => dualMeetSquadScores(DB, l)));
            // Squad-level scores for each meet, but for the team we are currently viewing
            const squadScoresObj = {
                Sabre: { wins: 0, losses: 0 },
                Foil: { wins: 0, losses: 0 },
                Epee: { wins: 0, losses: 0 }
            };
            for (let i = 0; i < squadScores.length; i++) {
                const score = squadScores[i];
                const meet = meets[i];
                for (const weapon in score) {
                    if (meet.team1.id === id) {
                        if (score[weapon][0] > score[weapon][1]) {
                            squadScoresObj[weapon].wins++;
                        } else if (score[weapon][0] < score[weapon][1]) {
                            squadScoresObj[weapon].losses++;
                        }
                    } else if (meet.team2.id === id) {
                        if (score[weapon][1] > score[weapon][0]) {
                            squadScoresObj[weapon].wins++;
                        } else if (score[weapon][1] < score[weapon][0]) {
                            squadScoresObj[weapon].losses++;
                        }
                    }
                }
            }
            if (active) {
                setSquadScores(squadScoresObj);
                setScoresForMeets(scoresObj);
            }
        })();

        return () => {
            active = false;
        };
    }, [teamData, rosterSeason]);

    const seasonMeets = dualMeets.filter(l => l.season === rosterSeason);
    const mostRecentMeet: IDualMeet | undefined = seasonMeets.length
        ? seasonMeets.reduce((acc, cur) => (acc.startedAt! > cur.startedAt! ? acc : cur), dualMeets[0])
        : undefined;
    const mostRecentMeetScore = mostRecentMeet ? scoresForMeets[mostRecentMeet.id] : undefined;

    useEffect(() => {
        if (!mostRecentMeet) return;
        Promise.all(mostRecentMeet.bouts.map(l => DB.getBout(l.id))).then(bouts => {
            const res: Record<
                string,
                {
                    name: string;
                    wins: number;
                    losses: number;
                    weapon: Weapon;
                }
            > = {};

            for (const boutResult of bouts) {
                if (boutResult.status === "fail") continue;
                const bout = boutResult.data;
                // Probably a more concise way to do this
                const winner = boutWinner(bout);
                if (mostRecentMeet.team1.id === id) {
                    const fencerID = bout.fencer1.fencerInfo.id;
                    if (!fencerID) continue;
                    if (fencerID in res) {
                        if (winner === BoutSide.Fencer1) {
                            res[fencerID].wins++;
                        } else if (winner === BoutSide.Fencer2) {
                            res[fencerID].losses++;
                        }
                    } else {
                        if (winner === BoutSide.Fencer1) {
                            res[fencerID] = {
                                name: `${bout.fencer1.fencerInfo.firstName} ${bout.fencer1.fencerInfo.lastName}`,
                                wins: 1,
                                losses: 0,
                                weapon: bout.weapon
                            };
                        } else if (winner === BoutSide.Fencer2) {
                            res[fencerID] = {
                                name: `${bout.fencer1.fencerInfo.firstName} ${bout.fencer1.fencerInfo.lastName}`,
                                wins: 0,
                                losses: 1,
                                weapon: bout.weapon
                            };
                        }
                    }
                } else if (mostRecentMeet.team2.id === id) {
                    const fencerID = bout.fencer2.fencerInfo.id;
                    if (!fencerID) continue;
                    if (fencerID in res) {
                        if (winner === BoutSide.Fencer2) {
                            res[fencerID].wins++;
                        } else if (winner === BoutSide.Fencer1) {
                            res[fencerID].losses++;
                        }
                    } else {
                        if (winner === BoutSide.Fencer2) {
                            res[fencerID] = {
                                name: `${bout.fencer2.fencerInfo.firstName} ${bout.fencer2.fencerInfo.lastName}`,
                                wins: 1,
                                losses: 0,
                                weapon: bout.weapon
                            };
                        } else if (winner === BoutSide.Fencer1) {
                            res[fencerID] = {
                                name: `${bout.fencer2.fencerInfo.firstName} ${bout.fencer2.fencerInfo.lastName}`,
                                wins: 0,
                                losses: 1,
                                weapon: bout.weapon
                            };
                        }
                    }
                }
            }

            const standouts = Object.values(res)
                .filter(l => l.wins >= 2)
                .sort((a, b) => b.wins - a.wins || a.name.localeCompare(b.name));

            setStandouts(standouts);
        });
    }, [mostRecentMeet]);

    if (notFound) {
        return <ErrorPage message="The requested team could not be found." />;
    }

    if (!teamData) {
        return (
            <TbTPage>
                <CommonLoading />
            </TbTPage>
        );
    }

    const sabreEntries = Array.from(processedRoster.Sabre.entries());
    const foilEntries = Array.from(processedRoster.Foil.entries());
    const epeeEntries = Array.from(processedRoster.Epee.entries());

    const overallScore = (() => {
        let wins = 0;
        let losses = 0;

        if (!dualMeets.length || !Object.keys(scoresForMeets).length) return [0, 0];

        for (let i = 0; i < dualMeets.length; i++) {
            const dualMeet = dualMeets[i];
            const score = scoresForMeets[dualMeet.id];

            if (!score) continue;

            if (score[0] + score[1] !== 27) continue;

            if (dualMeet.team1.id === id) {
                if (score[0] > score[1]) {
                    wins++;
                } else {
                    losses++;
                }
            } else if (dualMeet.team2.id === id) {
                if (score[1] > score[0]) {
                    wins++;
                } else {
                    losses++;
                }
            }
        }

        return [wins, losses];
    })();

    const highestSquadWinCount = Math.max(...Object.values(squadScores).map(l => l.wins));
    const topSquads = Object.keys(squadScores).filter(l => squadScores[l].wins === highestSquadWinCount);

    const topTeamInDropdown = pairTeam ? (teamData.name.includes("Men's") ? teamData : pairTeam) : teamData;
    const bottomTeamInDropdown = teamData.name.includes("Women's") ? teamData : pairTeam;

    const allowedToManageTeam =
        (userInfo?.flags || 0) & UserFlag.UberAdmin ||
        userInfo?.teams?.includes(id) ||
        (teamData.managers || []).includes(userInfo?.id || "adf");

    const exportAttendance = async (season: string) => {
        const meetIdsForCurrentSeason = new Set(teamData.dualMeets[season] ?? []);
        const meets = dualMeets.filter(d => meetIdsForCurrentSeason.has(d.id));

        const allFencers: { [fencerId: string]: IExistingFencer } = {};

        [epeeEntries, foilEntries, sabreEntries].forEach(entries => {
            entries.forEach(([fencer]) => {
                allFencers[fencer.id] = fencer;
            });
        });

        // Fencer ids --> ['P', 'A', 'P', etc.]
        const fencerIdToMeetsAttended: { [fencerId: string]: string[] } = {};

        Object.values(allFencers).forEach(f => {
            fencerIdToMeetsAttended[f.id] = [];
        });

        for (const meet of meets) {
            const team = meet.team1.id === id ? meet.team1 : meet.team2;

            const attendanceAsSet = new Set(team.attendance ?? []);

            const boutResults = await Promise.all(meet.bouts.map(l => DB.getBout(l.id)));
            const bouts = boutResults.filter(isSuccess).map(l => l.data);
            const competingFencers = bouts.flatMap(l => [l.fencer1.fencerInfo.id, l.fencer2.fencerInfo.id]);

            for (const fencer of Object.keys(fencerIdToMeetsAttended)) {
                if (team.attendance) {
                    fencerIdToMeetsAttended[fencer].push(
                        competingFencers.includes(fencer) ? "C" : attendanceAsSet.has(fencer) ? "P" : "A"
                    );
                } else {
                    fencerIdToMeetsAttended[fencer].push(competingFencers.includes(fencer) ? "C" : "N/A");
                }
            }
        }

        const fencerNamesToMeetsAttended: { [name: string]: string[] } = {};

        Object.entries(fencerIdToMeetsAttended).forEach(([key, value]) => {
            const fencer = allFencers[key];
            fencerNamesToMeetsAttended[`${fencer.firstName} ${fencer.lastName}`] = value;
        });

        const csv = generateHSAttendanceCsv(fencerNamesToMeetsAttended, [...meets.map(m => m.name)]);

        const blob = new Blob([csv], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `attendance-${teamData.abbreviation}-${rosterSeason}.csv`;

        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
    };

    return (
        <TbTPage>
            <div style={{ height: 50 }}></div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: size.width < 800 ? "column" : "row"
                }}
            >
                {orgData?.logoPath && (
                    <img
                        style={{
                            objectFit: "cover",
                            width: 128,
                            height: 128,
                            display: "block",
                            marginRight: size.width < 800 ? 0 : 32,
                            marginBottom: size.width < 800 ? 32 : 0
                        }}
                        src={`https://firebasestorage.googleapis.com/v0/b/touchbytouch-b336e.appspot.com/o/${encodeURIComponent(orgData.logoPath)}?alt=media`}
                    />
                )}
                <div>
                    <Typography variant={size.width < 800 ? "h4" : "h3"}>{teamData.name}</Typography>
                    {orgData && teamData.conference && (
                        <h5>
                            {teamData.conference
                                ? HighSchoolConferences.find(
                                      d =>
                                          d.id ===
                                          (typeof teamData.conference! === "string" ? teamData.conference : teamData.conference![0])
                                  )?.name || ""
                                : ""}
                        </h5>
                    )}
                    {orgData && orgData.district && orgData.district !== "" && (
                        <h4>{HighSchoolDistricts.find(d => d.id === orgData.district)?.name || ""}</h4>
                    )}
                </div>
            </div>
            <Box
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    flexDirection: size.width < 800 ? "column" : "row",
                    gap: "8px",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                    paddingLeft: "25px"
                }}
            >
                {topTeamInDropdown ? (
                    <StyledDropdown displayText={teamData.name}>
                        <Link href={`/team/${topTeamInDropdown.id}`} key={`switchTeam${topTeamInDropdown.id}`}>
                            <MenuItem>{topTeamInDropdown.name}</MenuItem>
                        </Link>
                        {bottomTeamInDropdown ? (
                            <Link href={`/team/${bottomTeamInDropdown.id}`} key={`switchTeam${bottomTeamInDropdown.id}`}>
                                <MenuItem>{bottomTeamInDropdown.name}</MenuItem>
                            </Link>
                        ) : null}
                    </StyledDropdown>
                ) : null}
                <SeasonSwitcher teamID={id} />
                {allowedToManageTeam && (
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => exportAttendance(rosterSeason)}
                        startIcon={<Icon path={mdiExport} size="20px" />}
                    >
                        Export attendance for {rosterSeason}
                    </Button>
                )}
            </Box>
            <Card sx={{ maxWidth: 1100, width: "90%", margin: "0 auto" }}>
                <CardHeader
                    title="Team Stats"
                    sx={{
                        backgroundColor: theme => theme.palette.secondary.dark
                    }}
                />
                <CardContent sx={{ padding: "0 !important" }}>
                    <Table size="small">
                        <colgroup>
                            <col style={{ width: "34%" }} />
                            <col style={{ width: "33%" }} />
                            <col style={{ width: "33%" }} />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell size="medium" sx={{ fontSize: "150%" }}>
                                    Wins
                                </TableCell>
                                <TableCell size="medium" sx={{ fontSize: "150%" }}>
                                    Losses
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow hover>
                                <TableCell sx={{ fontSize: "150%" }}>Team</TableCell>
                                <TableCell sx={{ fontSize: "150%" }}>{overallScore[0]}</TableCell>
                                <TableCell sx={{ fontSize: "150%" }}>{overallScore[1]}</TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Sabre</TableCell>
                                <TableCell>{squadScores.Sabre.wins}</TableCell>
                                <TableCell>{squadScores.Sabre.losses}</TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Foil</TableCell>
                                <TableCell>{squadScores.Foil.wins}</TableCell>
                                <TableCell>{squadScores.Foil.losses}</TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Epee</TableCell>
                                <TableCell>{squadScores.Epee.wins}</TableCell>
                                <TableCell>{squadScores.Epee.losses}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize: "150%" }}>{topSquads.length > 1 ? "Top Squads" : "Top Squad"}</TableCell>
                                <TableCell
                                    sx={{
                                        fontSize: "150%",
                                        textAlign: "center"
                                    }}
                                    colSpan={2}
                                >
                                    {topSquads.join(", ")}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            <main className="teamDetailContentDiv">
                <div className="teamDetailRosterSection">
                    <Card className="teamDetailRoster">
                        <CardHeader
                            sx={{
                                backgroundColor: theme => theme.palette.secondary.dark,
                                position: "relative",
                                height: "60px"
                            }}
                            action={
                                allowedToManageTeam ? (
                                    <Button
                                        href={`/rosterEditor/${id}`}
                                        variant="contained"
                                        color="secondary"
                                        sx={{
                                            position: "absolute",
                                            top: 12,
                                            right: 12
                                        }}
                                    >
                                        {size.width < 800 ? "Edit" : "Edit roster"}
                                    </Button>
                                ) : null
                            }
                            title="Roster"
                        />
                        <CardContent
                            sx={{
                                padding: "0 !important",
                                overflowY: "auto",
                                maxHeight: "540px"
                            }}
                        >
                            {processedRoster.Sabre.size || processedRoster.Foil.size || processedRoster.Epee.size ? (
                                <List
                                    sx={{
                                        overflowY: "auto",
                                        padding: "0 !important"
                                    }}
                                >
                                    <ListSubheader>Sabre</ListSubheader>
                                    {sabreEntries
                                        .sort((a, b) => (a[0].gradYear || 0) - (b[0].gradYear || 0))
                                        .map(([l, role]) => (
                                            <FencerListItem fencer={l} role={role} records={fencerRecords.Sabre} key={`tdrmbrs${l.id}`} />
                                        ))}
                                    <Divider />
                                    <ListSubheader>Foil</ListSubheader>
                                    {foilEntries
                                        .sort((a, b) => (a[0].gradYear || 0) - (b[0].gradYear || 0))
                                        .map(([l, role]) => (
                                            <FencerListItem fencer={l} role={role} records={fencerRecords.Foil} key={`tdrmbrf${l.id}`} />
                                        ))}
                                    <Divider />
                                    <ListSubheader>Epee</ListSubheader>
                                    {epeeEntries
                                        .sort((a, b) => (a[0].gradYear || 0) - (b[0].gradYear || 0))
                                        .map(([l, role]) => (
                                            <FencerListItem fencer={l} role={role} records={fencerRecords.Epee} key={`tdrmbre${l.id}`} />
                                        ))}
                                </List>
                            ) : (
                                <Typography sx={{ padding: "15px" }}>
                                    This team doesn't seem to have added any fencers for their roster yet.
                                </Typography>
                            )}
                        </CardContent>
                    </Card>
                </div>
                <div className="teamDetailMeetsSection" style={{ display: "flex", flexDirection: "column" }}>
                    <h3>Dual meets for {teamData.name}</h3>
                    <div className="teamDetailMeetsList">
                        {Object.keys(teamData.dualMeets[rosterSeason] || {}).length === 0 ? (
                            <h4>No dual meets yet!</h4>
                        ) : (
                            <div
                                className="teamDetailMeets"
                                style={{
                                    maxWidth: 450,
                                    textAlign: "center",
                                    margin: "auto",
                                    maxHeight: "100%",
                                    overflowY: "auto"
                                }}
                            >
                                {dualMeets.length === 0
                                    ? Object.keys(teamData.dualMeets[rosterSeason || ""] || {}).map(l => (
                                          <Skeleton
                                              key={`skeletongoadfm${l}`}
                                              sx={{ marginBottom: "20px" }}
                                              width={450}
                                              height={190}
                                              variant="rectangular"
                                          />
                                      ))
                                    : dualMeets
                                          .sort((a, b) => b.startedAt - a.startedAt)
                                          .map(data => <DualMeetInfoComponent data={data} key={`dualMadsfi${data.id}`} />)}
                            </div>
                        )}
                    </div>
                </div>
            </main>
            {mostRecentMeet && (
                <Card
                    sx={{
                        maxWidth: "1100px",
                        width: "90%",
                        margin: "0 auto 20px auto"
                    }}
                >
                    <CardHeader
                        title="Most Recent Meet"
                        color="secondary"
                        sx={{
                            backgroundColor: theme => theme.palette.secondary.dark
                        }}
                    />
                    <CardContent sx={{ padding: "0 !important" }}>
                        <List
                            sx={{
                                padding: "0 !important",
                                textAlign: "center"
                            }}
                        >
                            <ListItem>
                                <ListItemText
                                    primaryTypographyProps={{
                                        textAlign: "center",
                                        fontSize: "24px"
                                    }}
                                    primary={mostRecentMeet.name}
                                />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <div
                                    style={{
                                        textAlign: "center",
                                        width: "100%"
                                    }}
                                >
                                    <strong style={{ fontSize: "125%" }}>{mostRecentMeetScore?.[0] || 0}</strong> vs.{" "}
                                    <strong style={{ fontSize: "125%" }}>{mostRecentMeetScore?.[1] || 0}</strong>
                                </div>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText
                                    primaryTypographyProps={{
                                        textAlign: "center",
                                        fontSize: "22px"
                                    }}
                                    primary={new Date(mostRecentMeet.startedAt!).toLocaleDateString(undefined, {
                                        month: "long",
                                        day: "numeric",
                                        year: "numeric"
                                    })}
                                />
                            </ListItem>
                            <Divider />
                            <ListItem sx={{ backgroundColor: "#333" }}>
                                <ListItemText
                                    primaryTypographyProps={{
                                        textAlign: "center",
                                        fontSize: "22px",
                                        fontWeight: 500
                                    }}
                                    primary="Standouts"
                                />
                            </ListItem>
                            {(standouts || []).map(({ name, weapon, wins, losses }) => (
                                <React.Fragment key={`fencerStandout${name}`}>
                                    <Divider />
                                    <ListItem>
                                        <ListItemText
                                            sx={{
                                                width: "33%",
                                                textAlign: "center"
                                            }}
                                            primaryTypographyProps={{
                                                fontSize: "20px"
                                            }}
                                            primary={name}
                                        />
                                        <ListItemText
                                            sx={{
                                                width: "34%",
                                                textAlign: "center"
                                            }}
                                            primaryTypographyProps={{
                                                fontSize: "20px"
                                            }}
                                            primary={weapon}
                                        />
                                        <ListItemText
                                            sx={{
                                                width: "33%",
                                                textAlign: "center"
                                            }}
                                            primaryTypographyProps={{
                                                fontSize: "20px"
                                            }}
                                            primary={`${wins}-${losses}`}
                                        />
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            )}
            <RosterAutoUpdateDialog />
        </TbTPage>
    );
}
