import { useEffect, useState } from "react";
import { IBoutEvent } from "../../types";
import "./BoutLog.css";

interface BoutLogProps {
    log: IBoutEvent[];
    mobile: boolean;
}

const BoutLog = ({ log, mobile }: BoutLogProps) => {
    const [reversedLog, setReversedLog] = useState<IBoutEvent[]>([]);

    useEffect(() => {
        const cloned = [...log];
        cloned.reverse();
        setReversedLog(cloned);
    }, [log]);

    return (
        <div
            className={mobile ? "boutLogMobile" : "boutLog"}
            style={{
                height: mobile ? "200px" : "325px"
            }}
        >
            {reversedLog.map(event => (
                <div className="boutEvent" key={`doesAnyoneEvenReadThis${event.id}`}>
                    {event.description}
                    .
                    <br />
                    <strong>
                        Score: {event.score1} - {event.score2}
                    </strong>
                    <br />
                    Bout Clock: {Math.floor(event.boutTime / 60000)}:{(Math.floor(event.boutTime / 1000) % 60).toString().padStart(2, "0")}
                </div>
            ))}
            {reversedLog.length === 0 && (
                <h5
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        fontSize: 16,
                        color: "#999"
                    }}
                >
                    No bout events yet
                </h5>
            )}
        </div>
    );
};

export default BoutLog;
