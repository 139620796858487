import { v4 as uuidv4 } from "uuid";
import firebase from "./firebaseSetup";

export default class FileStorage {
    /**
     * Upload a file to Firebase Storage; does not validate File content type
     * @param file The File to upload
     * @returns The path at which the File was saved
     */
    public static async upload(file: File) {
        const storage = firebase.storage();

        const name = `images/${uuidv4()}.${file.name.split(".").findLast(() => true)}`;
        const ref = storage.ref(name);

        ref.put(file);

        return name;
    }

    /**
     * Delete a file from Firebase Storage
     * @param path The path to delete
     */
    public static async deleteFile(path: string) {
        const storage = firebase.storage();

        const ref = storage.ref(path);

        ref.delete();
    }
}
