import { Box } from "@mui/material";
import useDivision from "../../hooks/divison";
import { Division } from "../../types";
import "./Loading.css";
import React from "react";

interface CommonLoadingProps {
    /**
     * How fast the loading circle spins
     */
    speed: number;
    /**
     * The color of the loading circle (defaults to blue/purple based on division)
     */
    color: string;
    /**
     * How large the loading circle is (defaults to large)
     */
    size: "small" | "default" | "large";
}

/**
 * Loading circle placed in the center of the page
 * @param options Configuration options
 */
export const CommonLoading = React.forwardRef(
    ({ speed, color: providedColor, size = "large", ...props }: Partial<CommonLoadingProps>, ref) => {
        const division = useDivision();

        const color = providedColor || (division === Division.NCAA ? "hsl(211 54% 55%)" : "hsl(257 54% 55%)");

        const sizeItem: Record<CommonLoadingProps["size"], string> = {
            small: "10px",
            default: "12px",
            large: "14px"
        };

        const leSize = sizeItem[size] || sizeItem["default"];

        return (
            <Box
                {...props}
                ref={ref}
                style={{
                    width: 50,
                    height: 50,
                    margin: "auto",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }}
            >
                <div
                    className="commonLoadingItemDiv"
                    style={{
                        animation: `CommonLoading ${speed || 2}s linear infinite`,
                        animationDelay: "0.2s"
                    }}
                >
                    <span
                        className="commonLoadingItemSpan"
                        style={{
                            height: leSize,
                            width: leSize,
                            background: color || "#00adb5"
                        }}
                    />
                </div>
                <div
                    className="commonLoadingItemDiv"
                    style={{
                        animation: `CommonLoading ${speed || 2}s linear infinite`,
                        animationDelay: "0.4s"
                    }}
                >
                    <span
                        className="commonLoadingItemSpan"
                        style={{
                            height: leSize,
                            width: leSize,
                            background: color || "#00adb5"
                        }}
                    />
                </div>
                <div
                    className="commonLoadingItemDiv"
                    style={{
                        animation: `CommonLoading ${speed || 2}s linear infinite`,
                        animationDelay: "0.6s"
                    }}
                >
                    <span
                        className="commonLoadingItemSpan"
                        style={{
                            height: leSize,
                            width: leSize,
                            background: color || "#00adb5"
                        }}
                    />
                </div>
                <div
                    className="commonLoadingItemDiv"
                    style={{
                        animation: `CommonLoading ${speed || 2}s linear infinite`,
                        animationDelay: "0.8s"
                    }}
                >
                    <span
                        className="commonLoadingItemSpan"
                        style={{
                            height: leSize,
                            width: leSize,
                            background: color || "#00adb5"
                        }}
                    />
                </div>
            </Box>
        );
    }
);
