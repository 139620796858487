import { firebase } from "../firebaseSetup";
import { VERSION } from "../constants";

class VersionService {
    public INSTALLED_VERSION = VERSION;

    protected PREFIX = "/test";
    protected database: firebase.database.Database;

    public constructor(db: firebase.database.Database, prefix: string) {
        this.database = db;
        this.PREFIX = prefix;
    }

    public async getAppVersion(): Promise<string> {
        const v = await this.database.ref(`${this.PREFIX}/version`).once("value");
        return v.val();
    }
}

export default VersionService;
