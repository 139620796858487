import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "../utils/store";
import useDatabase from "../hooks/database";
import { CURRENT_SEASON_STR, LAST_SEASON_STR } from "../utils/season";

const RosterAutoUpdateDialog = () => {
    const DB = useDatabase();
    const [open, setOpen] = useState(false);
    const [lastAccessedSeason, setLastAccessedSeason] = useState(LAST_SEASON_STR);
    const [teamsUpdated, setTeamsUpdated] = useState<{ id: string; name: string }[]>([]);
    const userInfo = useSelector((s: ReduxState) => s.userInfo);

    useEffect(() => {
        if (!userInfo) return;

        const teams = [...userInfo.teams, ...userInfo.managingTeams];
        Promise.all(teams.map(l => DB.getTeam(l))).then(teamResults => {
            const updatedTeams: { id: string; name: string }[] = [];
            for (const team of teamResults) {
                if (team.status === "fail") continue;
                if (!team.data.lastAccessedSeason) {
                    updatedTeams.push({ id: team.data.id, name: team.data.name });
                    setLastAccessedSeason(LAST_SEASON_STR);
                    setOpen(true);
                } else if (team.data.lastAccessedSeason !== CURRENT_SEASON_STR) {
                    updatedTeams.push({ id: team.data.id, name: team.data.name });
                    setLastAccessedSeason(team.data.lastAccessedSeason);
                    setOpen(true);
                }
            }
            setTeamsUpdated(updatedTeams);
        });
    }, [userInfo?.teams, userInfo?.managingTeams]);

    const close = () => {
        setOpen(false);
        DB.updateTeamsLastAccessedSeason(teamsUpdated.map(l => l.id));
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Roster Auto Update</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Our records indicate that you've last used Touch by Touch during the {lastAccessedSeason} season. TbT has automatically
                    made a copy of your teams' rosters for the {CURRENT_SEASON_STR} season, but we strongly recommend you remove any fencers
                    that graduated and add any new fencers.
                </DialogContentText>
                <DialogContentText sx={{ mt: 1 }}>
                    Updated teams:
                    <ul>
                        {teamsUpdated.map(l => (
                            <li key={`teamUpdated${l.id}`}>{l.name}</li>
                        ))}
                    </ul>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} autoFocus>
                    I understand
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RosterAutoUpdateDialog;
