import { mdiMedicalBag } from "@mdi/js";
import Icon from "@mdi/react";
import { Box } from "@mui/material";

export interface MedicalIconProps {
    side: "left" | "right";
    setMedicalInfoOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setMedicalInfoSide: React.Dispatch<React.SetStateAction<"left" | "right">>;
}

export const MedicalIcon = ({ side, setMedicalInfoOpen, setMedicalInfoSide }: MedicalIconProps) => {
    return (
        <Box
            style={{ marginLeft: 5 }}
            onClick={() => {
                setMedicalInfoSide(side);
                setMedicalInfoOpen(true);
            }}
        >
            <Icon path={mdiMedicalBag} size={1} style={{ cursor: "pointer" }} />
        </Box>
    );
};
