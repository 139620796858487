import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import EquipmentOnGround from "../../../assets/EquipmentOnGround.jpg";
import PermissionDenied from "../../../components/PermissionDenied/PermissionDenied";
import TbTPage from "../../../components/TbTPage/TbTPage";
import {
    CollegeProgramType,
    DualMeetType,
    IDualMeetTeam,
    IOrganization,
    ITeam,
    RosterCSV,
    RosterCSVFencer,
    SchoolType,
    UserFlag,
    Weapon
} from "../../../types";
import { ReduxState } from "../../../utils/store";
import "./DualMeetCreation.css";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Modal,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { modalBoxStyle } from "../../..";
import useDatabase from "../../../hooks/database";
import WriteInTeamBox from "../../../components/WriteInTeamBox";
import { CommonLoading } from "../../../components/Loading/Loading";
import { DBResult } from "../../../utils/database";
import { userCanSeeTeam } from "../../../utils/helpers";

type Roster = IDualMeetTeam["fencers"];

interface WriteInModalContentProps {
    home: boolean;
    teamName: string;
    teamAbb: string;
    gender: string;
    updateTeamName: (val: string) => void;
    updateTeamAbbreviation: (val: string) => void;
    setWriteInGender: React.Dispatch<React.SetStateAction<"mens" | "womens">>;
    mensRoster: React.MutableRefObject<Roster>;
    womensRoster: React.MutableRefObject<Roster>;
    roster: Roster;
    selectingOrganizations: boolean;
    close: () => void;
}

const WriteInModalContent = ({
    home,
    teamName,
    teamAbb,
    gender,
    updateTeamName,
    updateTeamAbbreviation,
    setWriteInGender,
    mensRoster,
    womensRoster,
    roster,
    selectingOrganizations,
    close
}: WriteInModalContentProps) => {
    const [editingRosterSide, setEditingRosterSide] = useState<"mens" | "womens">("mens");

    return (
        <Box sx={modalBoxStyle}>
            <Typography variant="h4" fontFamily="Lexend Deca" sx={{ marginBottom: "10px" }}>
                Write-in Team
            </Typography>
            <div
                style={{
                    display: "flex",
                    margin: "10px 0",
                    alignItems: "center",
                    flexWrap: "wrap"
                }}
            >
                <Typography variant="h5" fontFamily="Lexend Deca" sx={{ flexShrink: 0, marginRight: "10px" }}>
                    Team Name
                </Typography>
                <TextField
                    label="Write-in team name"
                    placeholder="Write-in team name (e.g. Morris Hills High School)"
                    size="small"
                    value={teamName}
                    onChange={e => updateTeamName(e.target.value)}
                    fullWidth
                    sx={{ flexBasis: "400px" }}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    marginBottom: "10px",
                    alignItems: "center",
                    flexWrap: "wrap"
                }}
            >
                <Typography variant="h5" fontFamily="Lexend Deca" sx={{ flexShrink: 0, marginRight: "10px" }}>
                    Team Abbreviation
                </Typography>
                <TextField
                    label="Write-in team abbreviation"
                    placeholder="Write-in team abbreviation (e.g. MHHS)"
                    size="small"
                    value={teamAbb}
                    onChange={e => updateTeamAbbreviation(e.target.value)}
                    fullWidth
                    sx={{ flexBasis: "400px" }}
                />
            </div>
            {!selectingOrganizations ? (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap"
                    }}
                >
                    <Typography variant="h5" fontFamily="Lexend Deca" sx={{ flexShrink: 0, margin: "0 10px 10px 0" }}>
                        Gender
                    </Typography>
                    <RadioGroup
                        row
                        value={gender}
                        onChange={e => setWriteInGender((e.target as HTMLInputElement).value as "mens" | "womens")}
                    >
                        <FormControlLabel control={<Radio />} label="Men's" value="mens" />
                        <FormControlLabel control={<Radio />} label="Women's" value="womens" />
                    </RadioGroup>
                </Box>
            ) : null}
            {selectingOrganizations ? (
                <>
                    <Tabs value={editingRosterSide} onChange={(_, v) => setEditingRosterSide(v)} centered>
                        <Tab label="Men's" value="mens" />
                        <Tab label="Women's" value="womens" />
                    </Tabs>
                    <Box hidden={editingRosterSide === "womens"}>
                        <WriteInTeamBox onClose={() => close()} home={home} roster={mensRoster.current} teamName={teamName} />
                    </Box>
                    <Box hidden={editingRosterSide === "mens"}>
                        <WriteInTeamBox onClose={() => close()} home={home} roster={womensRoster.current} teamName={teamName} />
                    </Box>
                </>
            ) : (
                <WriteInTeamBox onClose={() => close()} home={home} roster={roster} teamName={teamName} />
            )}
        </Box>
    );
};

const DualMeetCreation = () => {
    // TODO: possible refactors here with changing variable names
    const [dualMeetName, setDualMeetName] = useState("");
    const [usingCustomName, setUsingCustomName] = useState(false);
    const [meetType, setMeetType] = useState(DualMeetType.Varsity);
    const [team1, setTeam1] = useState<string | null>(null);
    const [team2, setTeam2] = useState<string | null>(null);
    const [writeIn1, setWriteIn1] = useState(false);
    const [writeIn2, setWriteIn2] = useState(false);
    const [writeInOpen1, setWriteInOpen1] = useState(false);
    const [writeInOpen2, setWriteInOpen2] = useState(false);
    const [writeInRoster1] = useState<Roster>({
        Sabre: [],
        Foil: [],
        Epee: []
    });
    const [writeInRoster2] = useState<Roster>({
        Sabre: [],
        Foil: [],
        Epee: []
    });
    const [writeInGender1, setWriteInGender1] = useState<"mens" | "womens">("mens");
    const [writeInGender2, setWriteInGender2] = useState<"mens" | "womens">("mens");
    const [writeInTeamName1, setWriteInTeamName1] = useState("");
    const [writeInTeamName2, setWriteInTeamName2] = useState("");
    const [writeInTeamAbb1, setWriteInTeamAbb1] = useState("");
    const [writeInTeamAbb2, setWriteInTeamAbb2] = useState("");
    const [possibleTeams, setPossibleTeams] = useState<ITeam[]>([]);
    const [possibleOrgs, setPossibleOrgs] = useState<IOrganization[]>([]);
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [selectingOrganizations, setSelectingOrganizations] = useState(true);

    const [creatingMeet, setCreatingMeet] = useState(false);

    const [teamsLoading, setTeamsLoading] = useState(true);
    const [orgsLoading, setOrgsLoading] = useState(true);
    const loading = teamsLoading || orgsLoading;

    const [error, setError] = useState("");
    const [formError, setFormError] = useState("");

    const [unauthorized, setUnauthorized] = useState(false);

    const writeInMensRoster1 = useRef<Roster>({
        Sabre: [],
        Foil: [],
        Epee: []
    });
    const writeInMensRoster2 = useRef<Roster>({
        Sabre: [],
        Foil: [],
        Epee: []
    });
    const writeInWomensRoster1 = useRef<Roster>({
        Sabre: [],
        Foil: [],
        Epee: []
    });
    const writeInWomensRoster2 = useRef<Roster>({
        Sabre: [],
        Foil: [],
        Epee: []
    });

    const history = useHistory();
    const DB = useDatabase();

    const userInfo = useSelector((s: ReduxState) => s.userInfo);

    const teamsListener = (teams: DBResult<Record<string, ITeam>>) => {
        if (teams.status === "fail") return setError(teams.data);
        setPossibleTeams(
            Object.values(teams.data)
                .filter(l => userCanSeeTeam(l, userInfo))
                .sort((a, b) => a.name.localeCompare(b.name))
        );
        setTeamsLoading(false);
        if (userInfo) {
            if ([...userInfo.teams, ...userInfo.managingTeams].every(team => !teams.data[team].published)) {
                setUnauthorized(true);
            }
        } else {
            setUnauthorized(true);
        }
    };

    const orgsListener = (orgs: DBResult<Record<string, IOrganization>>) => {
        if (orgs.status === "fail") return setError(orgs.data);
        setPossibleOrgs(
            Object.values(orgs.data)
                .filter(l => (userInfo?.flags || 0) & UserFlag.UberAdmin || l.published)
                .sort((a, b) => a.name.localeCompare(b.name))
        );
        setOrgsLoading(false);
    };

    useEffect(() => {
        DB.getTeamList(teamsListener);
        DB.getOrganizationList(orgsListener);

        return () => {
            DB.stopListeningTeamList(teamsListener);
            DB.stopListeningOrganizationList(orgsListener);
        };
    }, []);

    useEffect(() => {
        if (!team1 || !team2 || usingCustomName) return;

        // @ts-ignore: TS is dumb and I'm too lazy to make this type safe so enjoy
        const team1Data: ITeam | IOrganization = availableOptions.find((l: ITeam | IOrganization) => l.id === team1)!;
        // @ts-ignore
        const team2Data: ITeam | IOrganization = availableOptions.find((l: ITeam | IOrganization) => l.id === team2)!;

        setDualMeetName(`${team1Data.name} (H) vs. ${team2Data.name} (A)`);
    }, [team1, team2, usingCustomName]);

    useEffect(() => {
        setTeam1(null);
        setTeam2(null);
    }, [meetType]);

    useEffect(() => {
        setTeam1(null);
        setTeam2(null);
    }, [selectingOrganizations]);

    if (!userInfo) {
        return <PermissionDenied message="You are not logged in!" />;
    }

    if (loading) {
        return (
            <TbTPage className="dualMeetCreationPage">
                <Card
                    sx={{
                        width: "90%",
                        maxWidth: 1000,
                        margin: "10vh auto 0 auto"
                    }}
                >
                    <CardHeader
                        title="Create New Dual Meet"
                        titleTypographyProps={{
                            variant: "h4",
                            textAlign: "center",
                            fontFamily: "Lexend Deca"
                        }}
                    />
                    <CardContent
                        sx={{
                            display: "flex",
                            padding: "0 !important",
                            height: 440,
                            overflowX: "auto"
                        }}
                    >
                        <CommonLoading />
                    </CardContent>
                </Card>
            </TbTPage>
        );
    }

    if (unauthorized) {
        return (
            <TbTPage className="dualMeetCreationPage">
                <Card
                    sx={{
                        width: "90%",
                        maxWidth: 600,
                        margin: "10vh auto 0 auto"
                    }}
                >
                    <CardHeader
                        title="Create New Dual Meet"
                        titleTypographyProps={{
                            variant: "h4",
                            textAlign: "center",
                            fontFamily: "Lexend Deca"
                        }}
                    />
                    <CardContent sx={{ height: 240, padding: "20px" }}>
                        <Typography variant="h5" fontFamily="Lexend Deca" sx={{ textAlign: "center", marginBottom: "10px" }}>
                            Unauthorized User
                        </Typography>
                        <Typography variant="body1">
                            In order to create a dual meet, you must first have a published team. Please request administrator publication
                            of your team in your school's page. If you do not currently have any teams, you can create teams for your school
                            in the appropriate page.
                        </Typography>
                        <Box
                            sx={{
                                width: "100%",
                                height: 60,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Button href="/createSchool" variant="contained" color="secondary">
                                Create School
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </TbTPage>
        );
    }

    const team1Valid = writeIn1 ? writeInTeamName1 && writeInTeamAbb1 : team1;
    const team2Valid = writeIn2 ? writeInTeamName2 && writeInTeamAbb2 : team2;
    const valid = team1Valid && team2Valid && team1 !== team2;

    const updateTeamName1 = (name: string) => {
        setWriteInTeamName1(name);
        for (const weapon in writeInRoster1) {
            for (const fencer of writeInRoster1[weapon as Weapon]) {
                fencer.teamName = name.trim();
            }
        }
    };

    const updateTeamName2 = (name: string) => {
        setWriteInTeamName2(name);
        for (const weapon in writeInRoster2) {
            for (const fencer of writeInRoster2[weapon as Weapon]) {
                fencer.teamName = name.trim();
            }
        }
    };

    const updateTeamAbbreviation1 = (name: string) => {
        setWriteInTeamAbb1(name);
        for (const weapon in writeInRoster1) {
            for (const fencer of writeInRoster1[weapon as Weapon]) {
                fencer.teamAbbreviation = name.trim();
            }
        }
    };

    const updateTeamAbbreviation2 = (name: string) => {
        setWriteInTeamAbb2(name);
        for (const weapon in writeInRoster2) {
            for (const fencer of writeInRoster2[weapon as Weapon]) {
                fencer.teamAbbreviation = name.trim();
            }
        }
    };

    const submitForm = async () => {
        if (!valid || !startDate || creatingMeet) return;

        setCreatingMeet(true);

        const seasonBase = new Date().getMonth() >= 7 ? new Date().getFullYear() : new Date().getFullYear() - 1;
        const season = `${seasonBase}-${seasonBase + 1}`;

        const processSubmittedTeam = (submittedTeam: string | null | undefined, roster: IDualMeetTeam["fencers"]) => {
            return new Promise(async res => {
                if (submittedTeam) {
                    const arr: RosterCSV = [];
                    for (const weapon in roster) {
                        arr.push(
                            ...roster[weapon as Weapon].map(
                                l =>
                                    ({
                                        firstName: l.firstName,
                                        lastName: l.lastName,
                                        weapon,
                                        awayStrip: l.away,
                                        homeStrip: l.home
                                    }) as RosterCSVFencer
                            )
                        );
                    }
                    await DB.importSeason(submittedTeam, season, arr);
                    return res(null);
                } else {
                    return res(null);
                }
            });
        };

        if (selectingOrganizations) {
            let org1 = writeIn1
                ? {
                      name: writeInTeamName1,
                      abbreviation: writeInTeamAbb1,
                      gender: writeInGender1
                  }
                : possibleOrgs.find(l => l.id === team1);
            let org2 = writeIn2
                ? {
                      name: writeInTeamName2,
                      abbreviation: writeInTeamAbb2,
                      gender: writeInGender2
                  }
                : possibleOrgs.find(l => l.id === team2);

            if (org1 && !("id" in org1)) {
                const newOrg = await DB.createOrganization(
                    org1.name,
                    "US",
                    "New Jersey",
                    {
                        boysTeam: CollegeProgramType.Varsity,
                        girlsTeam: CollegeProgramType.Varsity
                    },
                    org1.abbreviation,
                    SchoolType.HS,
                    true
                );
                if (newOrg.status === "fail") return setFormError(newOrg.data);
                org1 = newOrg.data;
                await processSubmittedTeam(org1.boysTeam, writeInMensRoster1.current);
                await processSubmittedTeam(org1.girlsTeam, writeInWomensRoster1.current);
            }
            if (org2 && !("id" in org2)) {
                const newOrg = await DB.createOrganization(
                    org2.name,
                    "US",
                    "New Jersey",
                    {
                        boysTeam: CollegeProgramType.Varsity,
                        girlsTeam: CollegeProgramType.Varsity
                    },
                    org2.abbreviation,
                    SchoolType.HS,
                    true
                );
                if (newOrg.status === "fail") return setFormError(newOrg.data);
                org2 = newOrg.data;
                await processSubmittedTeam(org2.boysTeam, writeInMensRoster2.current);
                await processSubmittedTeam(org2.girlsTeam, writeInWomensRoster2.current);
            }
            if (!org1 || !org2) {
                return setFormError("One of the provided teams was invalid.");
            }
            let dualMeetId = "";
            if (org1.boysTeam && org2.boysTeam) {
                const result = await DB.createNewDualMeet(
                    org1.boysTeam,
                    org2.boysTeam,
                    meetType,
                    season,
                    startDate,
                    usingCustomName ? dualMeetName : undefined
                );
                if (result.status === "fail") return setFormError(result.data);
                dualMeetId = result.data;
            }
            if (org1.girlsTeam && org2.girlsTeam) {
                const result = await DB.createNewDualMeet(
                    org1.girlsTeam,
                    org2.girlsTeam,
                    meetType,
                    season,
                    startDate,
                    usingCustomName ? dualMeetName : undefined,
                    dualMeetId
                );
                if (result.status === "fail") return setFormError(result.data);
                dualMeetId = result.data;
            }
            history.push(`/meet/${dualMeetId}`);
        } else {
            let submittedTeam1 = writeIn1
                ? {
                      name: writeInTeamName1,
                      abbreviation: writeInTeamAbb1,
                      fencers: writeInRoster1,
                      gender: writeInGender1
                  }
                : team1;
            let submittedTeam2 = writeIn2
                ? {
                      name: writeInTeamName2,
                      abbreviation: writeInTeamAbb2,
                      fencers: writeInRoster2,
                      gender: writeInGender2
                  }
                : team2;
            home: if (submittedTeam1 && typeof submittedTeam1 !== "string") {
                const newOrg = await DB.createOrganization(
                    submittedTeam1.name,
                    "US",
                    "New Jersey",
                    {
                        boysTeam: submittedTeam1.gender === "mens" ? CollegeProgramType.Varsity : CollegeProgramType.None,
                        girlsTeam: submittedTeam1.gender === "womens" ? CollegeProgramType.Varsity : CollegeProgramType.None
                    },
                    submittedTeam1.abbreviation,
                    SchoolType.HS,
                    true
                );
                if (newOrg.status === "fail") {
                    setFormError(newOrg.data);
                    break home;
                }
                submittedTeam1 = newOrg.data[submittedTeam1.gender === "mens" ? "boysTeam" : "girlsTeam"] || null;
                await processSubmittedTeam(submittedTeam1, writeInRoster1);
            }
            away: if (submittedTeam2 && typeof submittedTeam2 !== "string") {
                const newOrg = await DB.createOrganization(
                    submittedTeam2.name,
                    "US",
                    "New Jersey",
                    {
                        boysTeam: submittedTeam2.gender === "mens" ? CollegeProgramType.Varsity : CollegeProgramType.None,
                        girlsTeam: submittedTeam2.gender === "womens" ? CollegeProgramType.Varsity : CollegeProgramType.None
                    },
                    submittedTeam2.abbreviation,
                    SchoolType.HS,
                    true
                );
                if (newOrg.status === "fail") {
                    setFormError(newOrg.data);
                    break away;
                }
                submittedTeam2 = newOrg.data[submittedTeam2.gender === "mens" ? "boysTeam" : "girlsTeam"] || null;
                await processSubmittedTeam(submittedTeam2, writeInRoster2);
            }
            if (!submittedTeam1 || !submittedTeam2) {
                alert("One of the provided teams was invalid.");
                return;
            }
            const dualMeetId = await DB.createNewDualMeet(
                submittedTeam1!,
                submittedTeam2!,
                meetType,
                season,
                startDate,
                usingCustomName ? dualMeetName : undefined
            );
            if (dualMeetId.status === "fail") return setFormError(dualMeetId.data);
            history.push(`/meet/${dualMeetId.data}`);
        }
    };

    const availableOptions = selectingOrganizations
        ? possibleOrgs.filter(l => l.type === (meetType === DualMeetType.MiddleSchool ? SchoolType.MS : SchoolType.HS))
        : possibleTeams.filter(l => l.type === (meetType === DualMeetType.MiddleSchool ? SchoolType.MS : SchoolType.HS));

    const meetManager = (userInfo.flags || 0) & UserFlag.MeetManager;

    return (
        <TbTPage className="dualMeetCreationPage">
            <Card
                sx={{
                    width: "90%",
                    maxWidth: 1000,
                    margin: "10vh auto 0 auto"
                }}
            >
                <CardHeader
                    title="Create New Dual Meet"
                    titleTypographyProps={{
                        variant: "h4",
                        textAlign: "center",
                        fontFamily: "Lexend Deca"
                    }}
                />
                <CardContent
                    sx={{
                        display: "flex",
                        padding: "0 !important",
                        height: 440,
                        overflowX: "auto"
                    }}
                >
                    <Box
                        sx={{
                            minWidth: 300,
                            width: 475,
                            flexShrink: 0.5,
                            padding: "10px 15px"
                        }}
                    >
                        <FormControl style={{ marginTop: 10, marginBottom: 10 }} fullWidth>
                            <TextField
                                label="Dual Meet Name (autogenerated)"
                                placeholder="Custom Dual Meet Name"
                                size="small"
                                value={dualMeetName}
                                onChange={e => setDualMeetName(e.target.value)}
                                disabled={!usingCustomName}
                                sx={{
                                    cursor: usingCustomName ? "inherit" : "not-allowed !important"
                                }}
                                fullWidth
                            />
                        </FormControl>
                        {meetManager ? (
                            <FormControlLabel
                                sx={{ margin: "0" }}
                                control={
                                    <Checkbox sx={{ padding: "5px !important" }} onChange={() => setUsingCustomName(!usingCustomName)} />
                                }
                                labelPlacement="start"
                                label="Custom dual meet name?"
                            />
                        ) : (
                            <></>
                        )}
                        <FormControl
                            size="small"
                            style={{
                                marginTop: 10,
                                marginBottom: 10,
                                width: "100%"
                            }}
                        >
                            <InputLabel id="school-type">Meet Type</InputLabel>
                            <Select
                                labelId="school-type"
                                id="school-type"
                                label="Meet Type"
                                value={meetType}
                                onChange={e => setMeetType(e.target.value as DualMeetType)}
                            >
                                <MenuItem value={DualMeetType.Varsity}>Varsity</MenuItem>
                                <MenuItem value={DualMeetType.JV}>JV</MenuItem>
                                <MenuItem value={DualMeetType.MiddleSchool}>Middle School</MenuItem>
                            </Select>
                        </FormControl>
                        <Stack direction="row" spacing={0} alignItems="center" justifyContent="center" sx={{ marginBottom: "5px" }}>
                            <Typography>Teams</Typography>
                            <Switch defaultChecked onChange={() => setSelectingOrganizations(!selectingOrganizations)} />
                            <Typography>Schools</Typography>
                        </Stack>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                height: 43
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={0}
                                alignItems="center"
                                justifyContent="center"
                                sx={{ marginTop: "5px", flexShrink: 0 }}
                            >
                                <Typography>Write-in</Typography>
                                <Switch onChange={() => setWriteIn1(!writeIn1)} />
                            </Stack>
                            {!writeIn1 ? (
                                <FormControl fullWidth size="small">
                                    <InputLabel id="team1-select">Home {selectingOrganizations ? "School" : "Team"}</InputLabel>
                                    <Select
                                        labelId="team1-select"
                                        id="team1-select"
                                        label={`Home ${selectingOrganizations ? "School" : "Team"}`}
                                        value={team1}
                                        onChange={j => setTeam1(j.target.value)}
                                    >
                                        {availableOptions.map(l => (
                                            <MenuItem value={l.id} key={`dualMeetCreationTeam1${l.id}`}>
                                                {l.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <Button variant="contained" sx={{ width: "100%" }} onClick={() => setWriteInOpen1(true)}>
                                    Edit write-in roster
                                </Button>
                            )}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                height: 43
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={0}
                                alignItems="center"
                                justifyContent="center"
                                sx={{ marginTop: "5px", flexShrink: 0 }}
                            >
                                <Typography>Write-in</Typography>
                                <Switch onChange={() => setWriteIn2(!writeIn2)} />
                            </Stack>
                            {!writeIn2 ? (
                                <FormControl fullWidth size="small">
                                    <InputLabel id="team2-select">Away {selectingOrganizations ? "School" : "Team"}</InputLabel>
                                    <Select
                                        labelId="team2-select"
                                        id="team2-select"
                                        label={`Away ${selectingOrganizations ? "School" : "Team"}`}
                                        value={team2}
                                        onChange={j => setTeam2(j.target.value)}
                                    >
                                        {availableOptions.map(l => (
                                            <MenuItem value={l.id} key={`dualMeetCreationTeam2${l.id}`}>
                                                {l.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <Button variant="contained" sx={{ width: "100%" }} onClick={() => setWriteInOpen2(true)}>
                                    Edit write-in roster
                                </Button>
                            )}
                        </div>
                        {team1 && team1 === team2 && (
                            <Alert severity="warning" sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
                                You cannot select the same school for both sides.
                            </Alert>
                        )}
                        <div
                            style={{
                                margin: "10px auto 20px auto",
                                textAlign: "center"
                            }}
                        >
                            <DateTimePicker
                                renderInput={props => <TextField {...props} />}
                                desktopModeMediaQuery="@media only screen and (min-width: 600px)"
                                label="Meet Start Date"
                                value={startDate}
                                onChange={setStartDate}
                            />
                        </div>
                        <Button
                            fullWidth
                            variant="contained"
                            disabled={!valid}
                            className={`${!valid ? "disabledButton" : ""}`}
                            onClick={submitForm}
                        >
                            Create
                        </Button>
                    </Box>
                    <CardMedia component="img" image={EquipmentOnGround} alt="Old fencers" />
                </CardContent>
            </Card>
            <Modal open={writeInOpen1} onClose={() => setWriteInOpen1(false)}>
                <WriteInModalContent
                    home={true}
                    teamName={writeInTeamName1}
                    teamAbb={writeInTeamAbb1}
                    gender={writeInGender1}
                    updateTeamName={updateTeamName1}
                    updateTeamAbbreviation={updateTeamAbbreviation1}
                    setWriteInGender={setWriteInGender1}
                    mensRoster={writeInMensRoster1}
                    womensRoster={writeInWomensRoster1}
                    roster={writeInRoster1}
                    selectingOrganizations={selectingOrganizations}
                    close={() => setWriteInOpen1(false)}
                />
            </Modal>
            <Modal open={writeInOpen2} onClose={() => setWriteInOpen2(false)}>
                <WriteInModalContent
                    home={false}
                    teamName={writeInTeamName2}
                    teamAbb={writeInTeamAbb2}
                    gender={writeInGender2}
                    updateTeamName={updateTeamName2}
                    updateTeamAbbreviation={updateTeamAbbreviation2}
                    setWriteInGender={setWriteInGender2}
                    mensRoster={writeInMensRoster2}
                    womensRoster={writeInWomensRoster2}
                    roster={writeInRoster2}
                    selectingOrganizations={selectingOrganizations}
                    close={() => setWriteInOpen2(false)}
                />
            </Modal>
        </TbTPage>
    );
};

export default DualMeetCreation;
