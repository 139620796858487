import React, { useState } from "react";
import fire from "../utils/firebaseSetup";
import TbTPage from "./TbTPage/TbTPage";
import { Box, Button, Card, CardContent, CardHeader, FormControl, FormHelperText, TextField, Typography } from "@mui/material";

interface ForgotLoginProps {
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    emailError: string;
    setEmailError: React.Dispatch<React.SetStateAction<string>>;
    setForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

const ForgotLogin = ({ email, setEmail, emailError, setEmailError, setForgotPassword }: ForgotLoginProps) => {
    const [emailSent, setEmailSent] = useState(false);

    const checkEmail = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            await fire.auth().sendPasswordResetEmail(email);
            setEmail("");
            setEmailError("");
            setEmailSent(true);
        } catch (err: any) {
            setEmailError(err.message);
        }
    };

    return (
        <TbTPage>
            <div style={{ padding: "50px 0", color: "#444" }}>
                <Card style={{ width: "25rem", margin: "20px auto" }}>
                    <CardHeader title="Forgot password" />
                    <CardContent>
                        {emailSent ? (
                            <Box>
                                <Typography>A reset email will be sent to the email address shortly.</Typography>
                                <Button href="/">Return to home</Button>
                            </Box>
                        ) : (
                            <Box component="form" onSubmit={checkEmail}>
                                <Typography>
                                    Enter the email associated with your account to receive information on how to reset your password.
                                </Typography>
                                <FormControl style={{ marginTop: 10, width: "90%" }}>
                                    <TextField
                                        label="Email"
                                        placeholder="Email"
                                        type="email"
                                        size="small"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        autoComplete="email"
                                        fullWidth
                                        autoFocus
                                    />
                                    <FormHelperText
                                        style={{
                                            width: "100%",
                                            color: "red",
                                            display: "flex",
                                            textAlign: "left"
                                        }}
                                    >
                                        {emailError}
                                    </FormHelperText>
                                </FormControl>
                                <Button type="submit" variant="contained" style={{ width: "90%", marginTop: 10 }}>
                                    Submit
                                </Button>
                                <Button onClick={() => setForgotPassword(false)} sx={{ marginTop: "10px" }}>
                                    Return to login
                                </Button>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            </div>
        </TbTPage>
    );
};

export default ForgotLogin;
