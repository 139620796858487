import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

import Navigation from "./components/highschool/Navigation";
import Footer from "./components/highschool/Footer";

import LiveNow from "./pages/highschool/LiveNow/LiveNow";
import About from "./pages/highschool/About/About";
import Login from "./pages/highschool/Login/Login";
import OrgCreation from "./pages/highschool/OrganizationCreation/OrganizationCreation";
import DualMeets from "./pages/highschool/DualMeets/DualMeets";
import TeamList from "./pages/highschool/TeamList/TeamList";
import TeamDetail from "./pages/highschool/TeamDetail/TeamDetail";
import OrganizationList from "./pages/highschool/OrganizationList/OrganizationList";
import OrganizationDetail from "./pages/highschool/OrganizationDetail/OrganizationDetail";
import RosterEditor from "./pages/highschool/RosterEditor/RosterEditor";
import DualMeetCreation from "./pages/highschool/DualMeetCreation/DualMeetCreation";
import DualMeetInfo from "./pages/highschool/DualMeetInfo/DualMeetInfo";
import DualMeetScorer from "./pages/highschool/DualMeetScorer/DualMeetScorer";
import FencerInfo from "./pages/highschool/FencerInfo/FencerInfo";
import SuperadminPage from "./pages/highschool/SuperadminPage/SuperadminPage";
import PrivacyPolicy from "./pages/highschool/PrivacyPolicy/PrivacyPolicy";
import Rankings from "./pages/highschool/Rankings/Rankings";
import ErrorPage from "./pages/highschool/NotFound/NotFound";

import "./Home.css";

const darkTheme = createTheme({
    palette: {
        mode: "dark"
    },
    components: {
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: {
                    fontFamily: "Lexend Deca",
                    fontWeight: 500
                },
                sx: {
                    background: theme => theme.palette.secondary.dark
                }
            }
        }
    }
});

const Home = () => {
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Router basename={process.env.PUBLIC_URL}>
                <Navigation />
                <Switch>
                    <Route path="/" exact render={() => <LiveNow></LiveNow>} />
                    <Route path="/about" exact render={() => <About></About>} />
                    <Route path="/privacy" exact render={() => <PrivacyPolicy />} />
                    <Route path="/login" exact render={() => <Login />} />
                    <Route path="/createDualMeet" exact render={() => <DualMeetCreation />} />
                    <Route path="/createSchool" exact render={() => <OrgCreation />} />
                    <Route path="/dualMeets" exact render={() => <DualMeets />} />
                    <Route path="/meet/:id" exact render={() => <DualMeetInfo />} />
                    <Route path="/dualMeetScorer/:id" render={() => <DualMeetScorer />} />
                    <Route path="/schoolList" exact render={() => <OrganizationList />} />
                    <Route path="/school/:id" exact render={() => <OrganizationDetail />} />
                    <Route path="/teamList" exact render={() => <TeamList />} />
                    <Route path="/team/:id" exact render={() => <TeamDetail />} />
                    <Route path="/rosterEditor/:teamId" exact render={() => <RosterEditor />} />
                    <Route path="/fencer/:id" exact render={() => <FencerInfo />} />
                    <Route path="/superadminPage" exact render={() => <SuperadminPage />} />
                    <Route path="/rankings" exact render={() => <Rankings />} />
                    <Route path="*" render={() => <ErrorPage code={404} message="Page not found." />} />
                </Switch>
                <Footer />
            </Router>
        </ThemeProvider>
    );
};

export default Home;
