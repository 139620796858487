import { Division } from "../types";

const useDivision = (): Division | null => {
    const div = process.env.REACT_APP_DIVISION as string | undefined;
    if (div === Division.NJSIAA) return Division.NJSIAA;
    if (div === Division.NCAA) return Division.NCAA;
    return null;
};

export default useDivision;
