import { useEffect, useState } from "react";
import geography, { GeographyData } from "../../../utils/geography";
import EquipmentOnGround from "../../../assets/EquipmentOnGround.jpg";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../utils/store";
import PermissionDenied from "../../../components/PermissionDenied/PermissionDenied";
import TbTPage from "../../../components/TbTPage/TbTPage";
import "./OrganizationCreation.css";
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Checkbox,
    FormControl,
    FormControlLabel,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    Box,
    CardHeader,
    Modal,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link
} from "@mui/material";
import { modalBoxStyle } from "../../..";
import useDatabase from "../../../hooks/database";
import { schoolNamesEqual } from "../../../utils/helpers";
import { CollegeProgramType, IOrganization, SchoolType } from "../../../types";
import { HSDistrict, HighSchoolDistricts, Conference, HighSchoolConferences } from "../../../utils/ncaaConference";
import { DBResult } from "../../../utils/database";

const OrganizationCreation = () => {
    const defaultDistrict = { id: "", name: "Select district" };
    const defaultConference = { id: "", name: "Select conference" };

    const [region, setRegion] = useState("");
    const [district, setDistrict] = useState<HSDistrict>(defaultDistrict);
    const [conference, setConference] = useState<Conference>(defaultConference);
    const [organizationName, setOrganizationName] = useState("");
    const [organizationAbbreviation, setOrganizationAbbreviation] = useState("");
    const [boysTeam, setBoysTeam] = useState(false);
    const [girlsTeam, setGirlsTeam] = useState(false);
    const [confirming, setConfirming] = useState(false);
    const [teamType, setTeamType] = useState(SchoolType.HS);

    const [schools, setSchools] = useState<Record<string, IOrganization>>({});
    const [duplicateOpen, setDuplicateOpen] = useState(false);
    const [duplicateStr, setDuplicateStr] = useState("");
    const [duplicateID, setDuplicateID] = useState("");

    const [criticalError, setCriticalError] = useState("");
    const [formError, setFormError] = useState("");

    const history = useHistory();
    const DB = useDatabase();

    const userInfo = useSelector((s: ReduxState) => s.userInfo);

    const handleSchools = (result: DBResult<Record<string, IOrganization>>) => {
        if (result.status === "fail") return setCriticalError(result.data);
        setSchools(result.data);
    };

    useEffect(() => {
        DB.getOrganizationList(handleSchools);

        return () => {
            DB.stopListeningOrganizationList(handleSchools);
        };
    }, []);

    if (!userInfo) {
        return <PermissionDenied message="You are not logged in!" />;
    }

    const submitForm = async () => {
        const org = await DB.createOrganization(
            organizationName,
            "US",
            region,
            {
                boysTeam: boysTeam ? CollegeProgramType.Varsity : CollegeProgramType.None,
                girlsTeam: girlsTeam ? CollegeProgramType.Varsity : CollegeProgramType.None,
                boysTeamConference: region === "New Jersey" ? conference.id : undefined,
                girlsTeamConference: region === "New Jersey" ? conference.id : undefined
            },
            organizationAbbreviation,
            teamType,
            false,
            region === "New Jersey" ? district.id : undefined
        );
        if (org.status === "fail") return setFormError(org.data);
        history.push(`/school/${org.data.id}`);
    };

    const tryConfirm = () => {
        for (const id in schools) {
            if (schoolNamesEqual(schools[id].name, organizationName) && schools[id].region === region) {
                setDuplicateID(id);
                // Technically this isn't necessary, but maybe in the future we will have near-matches (Morris Hills vs Morris Hills High School)
                setDuplicateStr(schools[id].name);
                setDuplicateOpen(true);
                return;
            }
        }

        setConfirming(true);
    };

    const Confirmation = () => {
        return (
            <Dialog open={confirming} onClose={() => setConfirming(false)}>
                <DialogTitle>Confirm School Creation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to create this school?{" "}
                        <strong>
                            This action will create a{" "}
                            {boysTeam && girlsTeam ? "men's team and a women's team" : `${boysTeam ? "men's" : "women's"} team only`}!
                        </strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setConfirming(false);
                        }}
                    >
                        Go back
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            submitForm();
                            setConfirming(false);
                        }}
                        autoFocus
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const disabled =
        !organizationName.trim() ||
        region === "Select region" ||
        (!boysTeam && !girlsTeam) ||
        !organizationAbbreviation ||
        !Object.keys(schools).length ||
        (region === "New Jersey" &&
            (!district ||
                !conference ||
                !HighSchoolDistricts.find(d => d.id === district.id) ||
                !HighSchoolConferences.find(d => d.id === conference.id)));

    return (
        <TbTPage className="orgCreationPage">
            <Card
                sx={{
                    width: "90%",
                    maxWidth: 1000,
                    margin: "10vh auto 30px auto"
                }}
            >
                <CardHeader
                    title="Create New School"
                    titleTypographyProps={{
                        variant: "h4",
                        textAlign: "center",
                        fontFamily: "Lexend Deca"
                    }}
                />
                <CardContent
                    sx={{
                        display: "flex",
                        padding: "0 !important",
                        height: 385
                    }}
                >
                    <Box
                        sx={{
                            minWidth: 300,
                            width: 450,
                            flexShrink: 0.5,
                            padding: "10px 15px",
                            overflow: "auto"
                        }}
                    >
                        <FormControl style={{ marginTop: 10 }} fullWidth>
                            <TextField
                                label="School Name"
                                placeholder="School Name (e.g. Morris Hills High School)"
                                size="small"
                                value={organizationName}
                                onChange={e => setOrganizationName(e.target.value)}
                                fullWidth
                            />
                        </FormControl>
                        <FormControl style={{ marginTop: 15 }} fullWidth>
                            <TextField
                                label="School Abbreviation"
                                placeholder="School Abbreviation (e.g. MHHS)"
                                size="small"
                                value={organizationAbbreviation}
                                onChange={e => setOrganizationAbbreviation(e.target.value)}
                                fullWidth
                            />
                        </FormControl>
                        <FormControlLabel
                            sx={{ margin: "15px 0 0 10px" }}
                            control={<Checkbox sx={{ padding: "5px !important" }} onChange={() => setBoysTeam(!boysTeam)} />}
                            labelPlacement="start"
                            label="Create men's team"
                        />
                        <FormControlLabel
                            sx={{ margin: "0 0 20px 10px" }}
                            control={<Checkbox sx={{ padding: "5px !important" }} onChange={() => setGirlsTeam(!girlsTeam)} />}
                            labelPlacement="start"
                            label="Create women's team"
                        />
                        <FormControl fullWidth size="small">
                            <InputLabel id="region-select">Region</InputLabel>
                            <Select
                                labelId="region-select"
                                id="region-select"
                                label="Region"
                                value={region}
                                onChange={e => setRegion(e.target.value)}
                            >
                                {geography
                                    .find(l => l.countryCode === "US")!
                                    .regions.map(l => (
                                        <MenuItem value={l} key={`newTeamRegion${l}`}>
                                            {l || "Select region"}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        {region === "New Jersey" && (
                            <>
                                <FormControl fullWidth size="small" style={{ marginTop: 20 }}>
                                    <InputLabel id="district-select">District</InputLabel>
                                    <Select
                                        labelId="district-select"
                                        id="district-select"
                                        label="District"
                                        value={district.id}
                                        onChange={e =>
                                            setDistrict(HighSchoolDistricts.find(d => d.id === e.target.value) || defaultDistrict)
                                        }
                                    >
                                        {HighSchoolDistricts.map(d => (
                                            <MenuItem value={d.id} key={`newTeamDistrict${d.id}`}>
                                                {d.name || "Select district"}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth size="small" style={{ marginTop: 20 }}>
                                    <InputLabel id="conference-select">Conference</InputLabel>
                                    <Select
                                        labelId="conference-select"
                                        id="conference-select"
                                        label="Conference"
                                        value={conference.id}
                                        onChange={e =>
                                            setConference(HighSchoolConferences.find(d => d.id === e.target.value) || defaultConference)
                                        }
                                    >
                                        {HighSchoolConferences.map(d => (
                                            <MenuItem value={d.id} key={`newTeamConference${d.id}`}>
                                                {d.name || "Select conference"}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth size="small" style={{ marginTop: 20 }}>
                                    <InputLabel id="school-type">School Type</InputLabel>
                                    <Select
                                        labelId="school-type"
                                        id="school-type"
                                        label="School Type"
                                        value={teamType}
                                        onChange={e => setTeamType(e.target.value as SchoolType)}
                                    >
                                        <MenuItem value={SchoolType.HS}>High School</MenuItem>
                                        <MenuItem value={SchoolType.MS}>Middle School</MenuItem>
                                    </Select>
                                </FormControl>
                            </>
                        )}
                        <Button
                            sx={{ margin: "20px 0 0 0" }}
                            fullWidth
                            variant="contained"
                            disabled={disabled}
                            className={`${disabled ? "disabledButton" : ""}`}
                            onClick={tryConfirm}
                        >
                            Create
                        </Button>
                    </Box>
                    <CardMedia component="img" image={EquipmentOnGround} alt="Old fencers" />
                </CardContent>
            </Card>
            <Confirmation />
            <Dialog open={duplicateOpen} onClose={() => setDuplicateOpen(false)}>
                <DialogTitle>School Name Conflict</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Another {region} school already exists with the name {duplicateStr}. If it is unmanaged, you can request to manage
                        it on the <Link href={`/school/${duplicateID}`}>school's page</Link>. If you think this message is in error, please
                        contact TbT admins.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDuplicateOpen(false);
                            setConfirming(true);
                        }}
                    >
                        Create anyways
                    </Button>
                    <Button variant="contained" onClick={() => setDuplicateOpen(false)} autoFocus>
                        I understand
                    </Button>
                </DialogActions>
            </Dialog>
        </TbTPage>
    );
};

export default OrganizationCreation;
