import { Box, IconButton, Paper, Typography } from "@mui/material";
import { ITeamInvite } from "../types";
import { mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";

const InviteCode = ({ data, deleteInvite }: { data: ITeamInvite; deleteInvite: () => void }) => {
    const timeToExpiration = new Date(data.expiresAt).getTime() - Date.now();

    const expired = timeToExpiration < 0;

    const expirationStr =
        timeToExpiration >= 3_600_000
            ? `${Math.floor(timeToExpiration / 3_600_000)} hours`
            : `${Math.floor(timeToExpiration / 60_000)} minutes`;

    const secondaryStr = data.claimed
        ? `Claimed on ${new Date(data.claimedAt!).toDateString()}`
        : expired
          ? "Expired!"
          : `Expires in ${expirationStr}`;

    const secondaryColor = data.claimed ? "success.main" : expired ? "error.main" : "text.secondary";

    return (
        <Paper
            variant="outlined"
            sx={{ padding: "16px", margin: "5px 0 10px 0", display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
            <Box>
                <Typography variant="h5" gutterBottom>
                    {data.code}
                </Typography>
                <Typography sx={{ fontSize: 14 }} color={secondaryColor}>
                    {secondaryStr}
                </Typography>
            </Box>
            <Box>
                <IconButton onClick={deleteInvite}>
                    <Icon path={mdiDelete} size={1} horizontal vertical rotate={180} />
                </IconButton>
            </Box>
        </Paper>
    );
};

export default InviteCode;
