import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { IDualMeetBoutFencer } from "../types";

interface MedicalDialogProps {
    medicalInfoOpen: boolean;
    medicalInfoFencer?: IDualMeetBoutFencer;
    setMedicalInfoOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MedicalDialog = ({ medicalInfoOpen, setMedicalInfoOpen, medicalInfoFencer }: MedicalDialogProps) => {
    return (
        <Dialog open={Boolean(medicalInfoOpen)} onClose={() => setMedicalInfoOpen(false)}>
            <DialogTitle fontFamily="Lexend Deca">
                Medical {medicalInfoFencer ? (medicalInfoFencer.medicalForfeit ? "Forfeit" : "Substitution") : "Issue"}
            </DialogTitle>
            {medicalInfoFencer ? (
                <DialogContent>
                    {medicalInfoFencer.medicalForfeit ? (
                        <DialogContentText>
                            {medicalInfoFencer.fencerInfo.firstName} {medicalInfoFencer.fencerInfo.lastName} had a medical issue and
                            forfeited the bout.
                        </DialogContentText>
                    ) : medicalInfoFencer.medicalFencerInfo ? (
                        <DialogContentText>
                            {medicalInfoFencer.medicalFencerInfo.firstName} {medicalInfoFencer.medicalFencerInfo.lastName} had a medical
                            issue and was replaced by {medicalInfoFencer.fencerInfo.firstName} {medicalInfoFencer.fencerInfo.lastName}.
                        </DialogContentText>
                    ) : (
                        <></>
                    )}
                </DialogContent>
            ) : null}
            <DialogActions>
                <Button variant="text" onClick={() => setMedicalInfoOpen(false)}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};
