import { Grid, Card, Typography, CardContent, CardHeader, CardActionArea, Chip, Switch, Box, Fade } from "@mui/material";
import { useEffect, useState } from "react";
import TbTPage from "../../../components/TbTPage/TbTPage";
import { IOrganization, SchoolType, UserFlag } from "../../../types";
import geography from "../../../utils/geography";

import "./OrganizationList.css";
import useDatabase from "../../../hooks/database";

import { HighSchoolDistricts } from "../../../utils/ncaaConference";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../utils/store";
import { CommonLoading } from "../../../components/Loading/Loading";

const OrgDiv = ({ data, conference }: { data: IOrganization; conference?: string }) => {
    return (
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
            <Card>
                <CardActionArea href={`/school/${data.id}`} sx={{ textDecoration: "none !important" }}>
                    <CardHeader
                        title={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                {data.logoPath && (
                                    <img
                                        style={{
                                            objectFit: "cover",
                                            width: 32,
                                            height: 32,
                                            marginRight: 16
                                        }}
                                        src={`https://firebasestorage.googleapis.com/v0/b/touchbytouch-b336e.appspot.com/o/${encodeURIComponent(data.logoPath)}?alt=media`}
                                    />
                                )}
                                {data.name}
                            </div>
                        }
                        sx={{ backgroundColor: data.color }}
                    />
                    <CardContent
                        sx={{
                            padding: "10px !important",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Typography variant="body1" color="text.secondary" textAlign="center">
                            {conference ? `${conference} - ` : ""}
                            {data.district ? `${HighSchoolDistricts.find(d => d.id === data.district)?.name} - ` : ""}
                            {data.region}
                        </Typography>
                        <Box
                            sx={{
                                display: "inline-flex",
                                flexDirection: "column"
                            }}
                        >
                            {!data.published && (
                                <Chip
                                    sx={{
                                        fontFamily: "Lexend Deca",
                                        fontWeight: "500",
                                        margin: "2px 0 2px 10px"
                                    }}
                                    size="small"
                                    label="Unpublished"
                                    color="error"
                                />
                            )}
                            {!data.administrators.length && (
                                <Chip
                                    sx={{
                                        fontFamily: "Lexend Deca",
                                        fontWeight: "500",
                                        margin: "2px 0 2px 10px"
                                    }}
                                    size="small"
                                    label="Unmanaged"
                                    color="warning"
                                />
                            )}
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

export default function OrganizationList() {
    const DB = useDatabase();
    const [ms, setMs] = useState(false);

    const userInfo = useSelector((s: ReduxState) => s.userInfo);

    const [orgList, setOrgList] = useState<Record<string, IOrganization>>({});
    const [conferenceDict, setConferenceDict] = useState<Map<IOrganization, string> | null>(null);
    const [loading, setLoading] = useState(true);

    const [criticalError, setCriticalError] = useState("");

    useEffect(() => {
        (async () => {
            const DBOrgList = await DB.getOrganizationList();
            if (DBOrgList.status === "fail") return setCriticalError(DBOrgList.data);
            const orgs = DBOrgList.data;

            const dict = new Map<IOrganization, string>();

            for (const org of Object.entries(orgs)) {
                const team = org[1].boysTeam || org[1].girlsTeam;

                if (team) {
                    const result = await DB.getTeam(team);
                    if (result.status === "fail") continue;
                    if (result.data.conference) {
                        dict.set(
                            org[1],
                            result.data.conference
                                ? typeof result.data.conference === "string"
                                    ? result.data.conference
                                    : result.data.conference[0]
                                : ""
                        );
                    }
                }
            }

            setConferenceDict(dict);

            if ((userInfo?.flags || 0) & UserFlag.UberAdmin) {
                setOrgList(orgs);
                setLoading(false);
            } else {
                setOrgList(
                    Object.fromEntries(
                        Object.entries(orgs).filter(
                            l =>
                                l[1].published ||
                                l[1].administrators.includes(userInfo?.id || "") ||
                                userInfo?.managingTeams.includes(l[1].boysTeam || "") ||
                                userInfo?.managingTeams.includes(l[1].girlsTeam || "")
                        )
                    )
                );
                setLoading(false);
            }
        })();
    }, []);

    const teamListArr = Object.values(orgList).filter(l => (ms ? l.type === SchoolType.MS : l.type === SchoolType.HS));
    teamListArr.sort((a, b) => a.name.localeCompare(b.name));

    return (
        <TbTPage>
            <div style={{ height: 50 }}></div>
            <Typography fontFamily="Lexend Deca" component="h1" variant="h3">
                Schools
            </Typography>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Typography>View middle schools?</Typography>
                <Switch checked={ms} onChange={() => setMs(u => !u)} />
            </div>
            {loading && <CommonLoading /> }
            <Fade in={!loading}>
                <Grid
                    container
                    spacing={3}
                    sx={{
                        width: "90vw",
                        margin: "0 auto 20px auto",
                        paddingRight: "20px"
                    }}
                >
                    {teamListArr.map(l => (
                        <OrgDiv data={l} conference={conferenceDict?.get(l)} key={`orgListDiv${l.id}`} />
                    ))}
                </Grid>
            </Fade>
        </TbTPage>
    );
}
