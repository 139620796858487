import "./PermissionDenied.css";
import TbTPage from "../TbTPage/TbTPage";

export default function PermissionDenied({ message, promptTeamCreation }: { message: string; promptTeamCreation?: boolean }) {
    return (
        <TbTPage>
            <div className="permissionDenied">
                <div className="rainbowBox rainbowBoxBG">
                    <p>{message}</p>
                </div>
                <div
                    style={{
                        backgroundColor: "rgba(255, 255, 255, 0.85)",
                        padding: "10px 20px",
                        fontSize: "150%"
                    }}
                >
                    <p style={{ margin: 0, color: "#000" }}>
                        {promptTeamCreation && (
                            <>
                                Click <a href="/legacy/createTeam">here</a> to create a new team.{" "}
                            </>
                        )}
                        If you believe this message was shown in error, contact us at{" "}
                        <a href="mailto:tbt@redpandafencing.org">tbt@redpandafencing.org</a>
                    </p>
                </div>
                <div
                    style={{
                        backgroundColor: "rgba(255, 255, 255, 0.85)",
                        padding: "10px 20px",
                        fontSize: "150%"
                    }}
                >
                    <a href="/">Go back to the homepage</a>
                </div>
            </div>
        </TbTPage>
    );
}
