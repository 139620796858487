import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import fire from "../../utils/firebaseSetup";
import { ReduxState } from "../../utils/store";
import { UserFlag } from "../../types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import "./Navigation.css";
import { useState } from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import { mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import ListItem from "@mui/material/ListItem";
import useDivision from "../../hooks/divison";

const Navigation = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const division = useDivision();
    const userInfo = useSelector((s: ReduxState) => s.userInfo);

    const handleLogout = () => {
        fire.auth().signOut();
        dispatch({ type: "setAdminstrating", administrating: false });
        history.push(v2 ? "/" : "/legacy");
    };

    const v2 = !location.pathname.includes("/legacy");

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            <Button href="/" sx={{ color: "white", textTransform: "initial", my: 1 }}>
                <Typography variant="h6" component="div" fontFamily="Lexend Deca">
                    TbT Fencing{division && ` - ${division}`}
                </Typography>
            </Button>
            <Divider />
            <List>
                <ListItem>
                    <Button sx={{ color: "white" }} href="/dualMeets">
                        Dual Meets
                    </Button>
                </ListItem>
                {Boolean(userInfo) && (
                    <ListItem>
                        <Button sx={{ color: "white" }} href="/teamList">
                            My Teams
                        </Button>
                    </ListItem>
                )}
                <ListItem>
                    <Button sx={{ color: "white" }} href="/schoolList">
                        Schools
                    </Button>
                </ListItem>
                {Boolean(userInfo) && (
                    <>
                        <ListItem>
                            <Button sx={{ color: "white" }} href="/createDualMeet">
                                Create Dual Meet
                            </Button>
                        </ListItem>
                        <ListItem>
                            <Button sx={{ color: "white" }} href="/createSchool">
                                Create School
                            </Button>
                        </ListItem>
                    </>
                )}
                {(userInfo?.flags || 0) & UserFlag.SuperAdmin ? (
                    <ListItem>
                        <Button sx={{ color: "white" }} href="/superadminPage">
                            Superadmin Controls
                        </Button>
                    </ListItem>
                ) : (
                    <></>
                )}
                <ListItem>
                    <Button sx={{ color: "white" }} href="/about">
                        About
                    </Button>
                </ListItem>
            </List>
        </Box>
    );

    const container = window !== undefined ? () => document.body : undefined;

    return (
        <div style={{ height: "50px" }}>
            <AppBar component="nav">
                <Toolbar sx={{ justifyContent: "space-between" }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: "none" } }}
                    >
                        <Icon path={mdiMenu} size={1} horizontal vertical rotate={180} color="#EEE" />
                    </IconButton>
                    <Box
                        sx={{
                            width: 300,
                            flexGrow: 0,
                            display: { xs: "none", md: "block" }
                        }}
                    >
                        <Button href="/" sx={{ color: "white", textTransform: "initial" }}>
                            <Typography variant="h6" component="div" fontFamily="Lexend Deca">
                                TbT Fencing{division && ` - ${division}`}
                            </Typography>
                        </Button>
                    </Box>
                    <Box sx={{ display: { xs: "none", md: "block" } }}>
                        <Button sx={{ color: "white" }} href="/dualMeets">
                            Dual Meets
                        </Button>
                        {Boolean(userInfo) && (
                            <Button sx={{ color: "white" }} href="/teamList">
                                My Teams
                            </Button>
                        )}
                        <Button sx={{ color: "white" }} href="/schoolList">
                            Schools
                        </Button>
                        {Boolean(userInfo) && (
                            <>
                                <Button sx={{ color: "white" }} href="/createDualMeet">
                                    Create Dual Meet
                                </Button>
                                <Button sx={{ color: "white" }} href="/createSchool">
                                    Create School
                                </Button>
                            </>
                        )}
                        {(userInfo?.flags || 0) & UserFlag.SuperAdmin ? (
                            <Button sx={{ color: "white" }} href="/superadminPage">
                                Admin Controls
                            </Button>
                        ) : (
                            <></>
                        )}
                        <Button sx={{ color: "white" }} href="/about">
                            About
                        </Button>
                    </Box>
                    <Box sx={{ width: 300, flexGrow: 0, textAlign: "right" }}>
                        {userInfo && (
                            <span style={{ color: "#999" }}>
                                Logged in as {userInfo.firstName} {userInfo.lastName}
                            </span>
                        )}
                        {userInfo ? (
                            <Button onClick={handleLogout}>Log out</Button>
                        ) : (
                            <div>
                                <Button href="/login">Log In</Button>
                            </div>
                        )}
                    </Box>
                </Toolbar>
                <Box component="nav">
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: "block", md: "none" },
                            "& .MuiDrawer-paper": {
                                boxSizing: "border-box",
                                width: 200
                            }
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Box>
            </AppBar>
        </div>
    );
};

export default Navigation;
