import { Card, Grid, CardActionArea, CardHeader, CardContent, Typography, Chip } from "@mui/material";
import { useState, useEffect } from "react";
import { Division, ITeam } from "../types";
import geography from "../utils/geography";
import useDatabase from "../hooks/database";
import useDivision from "../hooks/divison";
import { NCAADivisions } from "../utils/ncaaConference";
import { genColorFromSeed } from "../utils/helpers";
import { DBResult } from "../utils/database";

interface TeamCardProps {
    id: string;
    writeIn?: { name: string; region: string };
    eventMode?: boolean;
    host?: boolean;
}

const TeamCard = ({ id, writeIn, eventMode, host }: TeamCardProps) => {
    const [data, setData] = useState<ITeam | null>(null);
    const [error, setError] = useState("");

    const division = useDivision();
    const DB = useDatabase();

    const handleTeam = (result: DBResult<ITeam>) => {
        if (result.status === "fail") return setError(result.data);
        setData(result.data);
    };

    useEffect(() => {
        DB.getTeam(id, { listener: handleTeam });

        return () => {
            DB.stopListeningTeam(id, handleTeam);
        };
    }, [id]);

    if (writeIn) {
        return (
            <Grid item xs={12} sm={eventMode ? 12 : 6} md={eventMode ? 6 : 4} lg={eventMode ? 6 : 4} xl={eventMode ? 6 : 3}>
                <Card>
                    <CardHeader title={writeIn.name} sx={{ backgroundColor: genColorFromSeed(writeIn.name) }} />
                    <CardContent sx={{ padding: "10px !important" }}>
                        <Typography variant="body1" color="text.secondary">
                            Write-in team ({writeIn.region})
                        </Typography>
                        {host ? <Chip label="Host" sx={{ marginLeft: "10px" }} size="small" /> : null}
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    if (!data) {
        return <></>;
    }

    const regionStr =
        division === Division.NCAA
            ? NCAADivisions.find(l => l.id === data.region)?.name || ""
            : `${data.region}, ${geography.find(l => l.countryCode === data.countryCode)?.country || "Unknown country"}`;

    return (
        <Grid item xs={12} sm={eventMode ? 12 : 6} md={eventMode ? 6 : 4} lg={eventMode ? 6 : 4} xl={eventMode ? 6 : 3}>
            <Card>
                {data && (
                    <CardActionArea href={`/team/${data.id}`} sx={{ textDecoration: "none !important" }}>
                        <CardHeader title={data.name} sx={{ backgroundColor: data.color }} />
                        <CardContent
                            sx={{
                                padding: "10px !important",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <Typography variant="body1" color="text.secondary">
                                {data.conference
                                    ? `${regionStr} - ${typeof data.conference === "string" ? data.conference : data.conference.join(", ")}`
                                    : regionStr}
                            </Typography>
                            {!data.published ? <Chip label="Unpublished" sx={{ marginLeft: "10px" }} size="small" /> : null}
                            {!data.administrators.length ? <Chip label="Unmanaged" sx={{ marginLeft: "10px" }} size="small" /> : null}
                            {host ? <Chip label="Host" sx={{ marginLeft: "10px" }} size="small" /> : null}
                        </CardContent>
                    </CardActionArea>
                )}
            </Card>
        </Grid>
    );
};

export default TeamCard;
