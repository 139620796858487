/**
 * Current fencing season according to USA Fencing. Based on the fall (so the 2023-2024 season would be represented as 2023)
 */
export const CURRENT_SEASON_NUM = new Date().getMonth() >= 7 ? new Date().getFullYear() : new Date().getFullYear() - 1;

/**
 * Current fencing season according to USA Fencing. Formatted as a string (e.g. `2024-2025`)
 */
export const CURRENT_SEASON_STR = `${CURRENT_SEASON_NUM}-${CURRENT_SEASON_NUM + 1}`;

/**
 * Last fencing season according to USA Fencing. Formatted as a string (e.g. `2023-2024`)
 */
export const LAST_SEASON_STR = `${CURRENT_SEASON_NUM - 1}-${CURRENT_SEASON_NUM}`;

/**
 * Generate a list of season options until the current season. Formatted as strings (e.g. `["2023-2024"]`)
 * @param start Number corresponding to the start season. Based on the fall (so the 2023-2024 season would be represented as 2023)
 */
export const getSeasonOptions = (start = 2023): string[] => {
    const currentSeason = new Date().getMonth() >= 7 ? new Date().getFullYear() : new Date().getFullYear() - 1;
    const seasons: string[] = [];
    for (let i = 0; i < 10; i++) {
        // No tolerating monkey business
        const season = currentSeason - i;
        seasons.unshift(`${season}-${season + 1}`);
        if (season === start) break;
    }
    return seasons;
};
