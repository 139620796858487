import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReduxState } from "../../utils/store";
import useDatabase from "../../hooks/database";
import { Link, MenuItem } from "@mui/material";
import StyledDropdown from "../StyledDropdown/StyledDropdown";
import "./SeasonSwitcher.css";

interface LinkSwitcherProps {
    /**
     * Route prefix.
     */
    route: string;
    /**
     * Display text of currently selected team.
     */
    selectedName: string;
    /**
     * List of team names and their correspoinding ID's.
     */
    options: { name: string; id?: string }[];
}

/**
 * Dropdown component that has links to navigate between teams.
 */
export default function LinkSwitcher({ route, selectedName, options }: LinkSwitcherProps) {
    return (
        <div style={{ fontSize: "110%" }}>
            <StyledDropdown displayText={selectedName}>
                {options.map(l => (
                    <Link href={`${route}/${l.id}`} key={`linkSwitcher${l.id}`}>
                        <MenuItem>{l.name}</MenuItem>
                    </Link>
                ))}
            </StyledDropdown>
        </div>
    );
}
