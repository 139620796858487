import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

const SnackbarError = ({ error, close }: { error: string; close: () => void }) => {
    const [errorStr, setErrorStr] = useState("");

    useEffect(() => {
        if (error !== "") setErrorStr(error);
    }, [error]);

    return (
        <Snackbar open={Boolean(error)} onClose={close} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
            <Alert onClose={close} severity="error" variant="filled" sx={{ width: "100%" }}>
                {errorStr}
            </Alert>
        </Snackbar>
    );
};

export default SnackbarError;
