import "./TabView.css";
import { useState, forwardRef, useImperativeHandle } from "react";

type TbTTabViewProps = {
    children: React.ReactNode;
};

export const TbTTabView = forwardRef((props: TbTTabViewProps, ref) => {
    const [selected, setSelected] = useState(0);
    const [selectedView, setSelectedView] = useState(0);

    useImperativeHandle(ref, () => ({
        switchTab: (num: number) => {
            setSelected(num);
            setSelectedView(num);
        }
    }));

    const rawChildren = props.children;
    const children = Array.isArray(rawChildren) ? rawChildren.flat() : [rawChildren];

    return (
        <div className="tbtTabView">
            <div className="tbtTabViewRow">
                {children
                    .filter(l => !!l)
                    .map(l => l.props)
                    .map((l, idx) =>
                        l.hidden ? (
                            <></>
                        ) : (
                            <div
                                className={`tbtTabViewTab ${selected === idx ? "tbtTabViewTabSelected" : ""} ${l.disabled ? "tbtTabDisabled" : ""}`}
                                key={`tbtTabViewTab${idx}`}
                                onClick={() => {
                                    if (!l.disabled) {
                                        setSelected(idx);
                                        setSelectedView(
                                            children.filter(j => !!j && !j.props.hidden).findIndex(j => j.props.title === l.title)
                                        );
                                    }
                                }}
                            >
                                {l.title}
                            </div>
                        )
                    )}
            </div>
            <div
                className="tbtTabViewContent"
                style={{
                    transform: `translate(${selectedView * -100}vw, 0px)`
                }}
            >
                {children.filter(l => !l.props.hidden)}
            </div>
        </div>
    );
});

export function TbTTab({
    title,
    children,
    disabled,
    hidden
}: {
    title: string;
    children: React.ReactNode;
    disabled?: boolean;
    hidden?: boolean;
}) {
    return (
        <div className="tbtTab" key={title}>
            <div className="tbtTabContent">{children}</div>
        </div>
    );
}
