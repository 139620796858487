import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReduxState } from "../../utils/store";
import useDatabase from "../../hooks/database";
import { Link, MenuItem } from "@mui/material";
import StyledDropdown from "../StyledDropdown/StyledDropdown";
import "./SeasonSwitcher.css";

interface TeamSwitcherProps {
    /**
     * Route prefix. Defaults to `/team`
     */
    route?: string;
    /**
     * Display text of currently selected team.
     */
    selectedName: string;
    /**
     * List of team names and their correspoinding ID's.
     */
    teams: { name: string; id?: string }[];
}

/**
 * Dropdown component that has links to navigate between teams.
 */
export default function TeamSwitcher({ route, selectedName, teams }: TeamSwitcherProps) {
    return (
        <div style={{ margin: 25, fontSize: "110%" }}>
            <StyledDropdown displayText={selectedName}>
                {teams.map(l => (
                    <Link href={`${route || "/team"}/${l.id}`} key={`switchTeam${l.id}`}>
                        <MenuItem>{l.name}</MenuItem>
                    </Link>
                ))}
            </StyledDropdown>
        </div>
    );
}
