import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { modalBoxStyle, modalBoxStyleNarrower } from "../../..";

import { CommonLoading } from "../../../components/Loading/Loading";
import TbTPage from "../../../components/TbTPage/TbTPage";
import TeamCard from "../../../components/TeamCard";
import { IOrganization, IPublicationApplication, ITeam, IUser, UserFlag } from "../../../types";
import geography from "../../../utils/geography";
import { ReduxState } from "../../../utils/store";
import useDatabase from "../../../hooks/database";

import "./OrganizationDetail.css";
import ErrorPage from "../NotFound/NotFound";
import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    Dialog,
    InputLabel,
    FormControlLabel,
    Select,
    MenuItem,
    Divider,
    Switch,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from "@mui/material";
import { genAbbreviationFromName } from "../../../utils/helpers";

import { HSDistrict, HighSchoolDistricts, Conference, HighSchoolConferences } from "../../../utils/ncaaConference";
import { DBResult } from "../../../utils/database";
import { ERROR_SCHOOL_DOES_NOT_EXIST } from "../../../utils/constants";
import SnackbarError from "../../../components/SnackbarError";
import InputFileUpload from "../../../components/InputFileUpload/InputFileUpload";

import FileStorage from "../../../utils/fileStorage";

export default function OrganizationDetail() {
    const { id } = useParams<{ id: string }>();
    const DB = useDatabase();
    const history = useHistory();

    const defaultDistrict = { id: "", name: "Select district" };
    const defaultConference = { id: "", name: "Select conference" };

    const userInfo = useSelector((s: ReduxState) => s.userInfo);
    const [organizationData, setOrganizationData] = useState<IOrganization | null>(null);
    const [organizationRequests, setOrganizationRequests] = useState<Record<string, IPublicationApplication>>({});
    const [organizationUser, setOrganizationUser] = useState<IUser | null>(null);
    const [requestingTakeover, setRequestingTakeover] = useState(false);
    const [requestingOrganization, setRequestingOrganization] = useState(false);
    const [requestLoading, setRequestLoading] = useState(false);
    const [requestName, setRequestName] = useState("");
    const [requestSchool, setRequestSchool] = useState("");
    const [requestAddInf, setRequestAddInf] = useState("");

    const [updateInfoLoading, setUpdateInfoLoading] = useState(false);
    const [district, setDistrict] = useState<HSDistrict | undefined>(defaultDistrict);
    const [teamConf, setTeamConf] = useState<Conference | undefined>(undefined);

    const [makingUnmanaged, setMakingUnmanaged] = useState(false);
    const [unpublishingOpen, setUnpublishingOpen] = useState(false);

    const [configOpen, setConfigOpen] = useState(false);
    const [boysTeam, setBoysTeam] = useState<ITeam | null>(null);
    const [girlsTeam, setGirlsTeam] = useState<ITeam | null>(null);

    const [deleting, setDeleting] = useState<"mens" | "womens" | "">("");
    const deletingTeam = deleting === "mens" ? boysTeam : deleting === "womens" ? girlsTeam : undefined;

    const [criticalError, setCriticalError] = useState("");
    const [snackbarError, setSnackbarError] = useState("");

    const [uploadedLogo, setUploadedLogo] = useState<File | null>(null);
    const [previewLogo, setPreviewLogo] = useState<string | undefined>(undefined);
    const [deletingLogo, setDeletingLogo] = useState<boolean>(false);
    // Use when user has just uploaded a new logo to prevent empty image from showing up
    const [justUploadedLogo, setJustUploadedLogo] = useState<string | undefined>(undefined);

    const handleOrganizationRequests = (result: DBResult<Record<string, IPublicationApplication>>) => {
        if (result.status === "fail") return setCriticalError(result.data);
        setOrganizationRequests(result.data);
    };

    const handleOrganization = (result: DBResult<IOrganization>) => {
        if (result.status === "fail") return setCriticalError(result.data);
        setOrganizationData(result.data);
    };

    useEffect(() => {
        DB.getOrganizationRequests(handleOrganizationRequests);
        DB.getOrganization(id, handleOrganization).then(org => {
            if (org.status === "fail") return setCriticalError(org.data);
            setDistrict(HighSchoolDistricts.find(d => d.id === org.data.district) || defaultDistrict);
        });

        return () => {
            DB.stopListeningOrganization(id, handleOrganization);
            DB.stopListeningOrganizationRequests();
        };
    }, []);

    useEffect(() => {
        try {
            if (organizationData?.createdBy) {
                DB.getUserInfo(organizationData.createdBy).then(result => {
                    if (result.status === "fail") return setSnackbarError(result.data);
                    setOrganizationUser(result.data);
                });
            } else {
                setOrganizationUser(null);
            }
        } catch (e: unknown) {
            console.log(e);
        }
    }, [organizationData?.createdBy]);

    const updateTeamsData = async () => {
        boys: if (organizationData?.boysTeam) {
            const team = await DB.getTeam(organizationData.boysTeam);
            if (team.status === "fail") {
                setSnackbarError(team.data);
                break boys;
            }
            setBoysTeam(team.data);
            if (!teamConf && team.data.conference) {
                setTeamConf(
                    HighSchoolConferences.find(
                        c => c.id === (typeof team.data.conference! === "string" ? team.data.conference : team.data.conference![0])
                    )
                );
            }
        }
        girls: if (organizationData?.girlsTeam) {
            const team = await DB.getTeam(organizationData.girlsTeam);
            if (team.status === "fail") {
                setSnackbarError(team.data);
                break girls;
            }
            setGirlsTeam(team.data);

            if (!teamConf && team.data.conference) {
                setTeamConf(
                    HighSchoolConferences.find(
                        c => c.id === (typeof team.data.conference! === "string" ? team.data.conference : team.data.conference![0])
                    )
                );
            }
        }
    };

    console.log(teamConf);

    useEffect(() => {
        updateTeamsData();
    }, [organizationData?.boysTeam, organizationData?.girlsTeam]);

    if (!organizationData) {
        return (
            <TbTPage>
                <CommonLoading />
            </TbTPage>
        );
    }

    if (criticalError === ERROR_SCHOOL_DOES_NOT_EXIST) {
        return <ErrorPage code={404} message="The requested school could not be found." />;
    } else if (criticalError) {
        return <ErrorPage message={criticalError} />;
    }

    const unpublish = async () => {
        const result = await DB.unpublishOrganization(id);
        if (result.status === "fail") {
            setSnackbarError(result.data);
        } else {
            setUnpublishingOpen(false);
        }
    };

    const makeUnmanaged = () => {
        DB.makeOrganizationUnmanaged(organizationData).then(() => setMakingUnmanaged(false));
    };

    const deleteTeam = () => {
        if (!deletingTeam) return;
        DB.deleteTeam(deletingTeam.id);
        setDeleting("");
    };

    const createTeam = async (team: "boys" | "girls") => {
        if (!organizationData) return;

        let name = organizationData.name;
        let abbreviation = organizationData.abbreviation || genAbbreviationFromName(organizationData.name);
        if (team.startsWith("boys")) {
            name += " Men's";
            abbreviation += " Men's";
        }
        if (team.startsWith("girls")) {
            name += " Women's";
            abbreviation += " Women's";
        }

        const newID = await DB.createTeamAttachedToOrg(
            organizationData.id,
            name,
            abbreviation,
            organizationData.countryCode,
            organizationData.region,
            `${team}Team`,
            organizationData.type,
            {
                published: organizationData.published,
                publishedAt: Date.now(),
                publishedBy: organizationData.publishedBy
            },
            teamConf?.id
        );
        history.push(`/team/${newID.data}`);
    };

    const submitOrganizationRequest = () => {
        setRequestLoading(true);
        DB.requestOrganizationPublication(requestingTakeover, id, organizationData.name, requestName, requestSchool, requestAddInf).then(
            () => {
                setRequestLoading(false);
                setRequestingOrganization(false);
                setRequestingTakeover(false);
            }
        );
    };

    const submitSchoolDetailsUpdate = async () => {
        setUpdateInfoLoading(true);
        await DB.editOrganization({ id: organizationData.id, district: district?.id });

        if (organizationData?.boysTeam && teamConf?.id) {
            await DB.updateTeamConference(organizationData.boysTeam, teamConf.id);
        }
        if (organizationData?.girlsTeam && teamConf?.id) {
            await DB.updateTeamConference(organizationData.girlsTeam, teamConf.id);
        }

        setUpdateInfoLoading(false);
        setConfigOpen(false);
    };

    const previewSchoolLogo = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        const file = event.target.files[0];

        if (!file.type.startsWith("image/")) {
            setSnackbarError("Uploaded file must be an image.");
            return;
        }

        if (file.size > 3 * 1024 * 1024) {
            setSnackbarError("Uploaded file must be less than 3MB.");
            return;
        }

        setUploadedLogo(file);
        setPreviewLogo(URL.createObjectURL(file));
    };

    const uploadSchoolLogo = async () => {
        if (!uploadedLogo) {
            return;
        }

        if (organizationData.logoPath) {
            await FileStorage.deleteFile(organizationData.logoPath);
        }

        const path = await FileStorage.upload(uploadedLogo);
        await DB.editOrganization({ id: organizationData.id, logoPath: path });

        setJustUploadedLogo(previewLogo);

        organizationData.logoPath = path;
        setOrganizationData(organizationData);

        setUploadedLogo(null);
        setPreviewLogo(undefined);
        setConfigOpen(false);
    };

    const removeSchoolLogo = async () => {
        if (!organizationData.logoPath) {
            return;
        }

        await FileStorage.deleteFile(organizationData.logoPath);

        organizationData.logoPath = "";
        setOrganizationData(organizationData);

        DB.editOrganization({ id: organizationData.id, logoPath: "" });

        setDeletingLogo(false);
    };

    const requestState = (() => {
        if (Object.values(organizationRequests).some(l => l.orgId === id)) {
            return "pending";
        } else if (organizationData.published) {
            return "published";
        }
        return "unpublished";
    })();

    const superAdmin = Boolean((userInfo?.flags || 0) & UserFlag.SuperAdmin);
    const canAdministrate = organizationData.administrators.includes(userInfo?.id || "") || superAdmin;
    const canTakeOver = !organizationData.administrators.length && Boolean(userInfo);

    const TakeOverCard = () => {
        return (
            <Card sx={{ maxWidth: "500px", width: "90%", margin: "auto" }}>
                <CardHeader title="Unmanaged School Notice" />
                <CardContent sx={{ padding: "20px !important" }}>
                    <Typography variant="body1">
                        This school was created as a write-in school for a dual meet. It is not currently being administrated and is open
                        for management. If you are a coach or manager at this school, you can request to administrate this school with the
                        button below.
                    </Typography>

                    <Button
                        variant="contained"
                        style={{ marginTop: "10px" }}
                        onClick={() => setRequestingTakeover(true)}
                        disabled={requestState === "pending"}
                    >
                        {requestState === "pending" ? "Management Request Pending..." : "Request Management"}
                    </Button>
                </CardContent>
            </Card>
        );
    };

    const canDeleteBoysTeam = Object.values(boysTeam?.dualMeets || {}).flat().length === 0;
    const canDeleteGirlsTeam = Object.values(girlsTeam?.dualMeets || {}).flat().length === 0;

    return (
        <TbTPage>
            <div style={{ height: 50 }}></div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                {organizationData.logoPath && (
                    <img
                        style={{
                            objectFit: "cover",
                            width: 128,
                            height: 128,
                            display: "block",
                            marginRight: 16
                        }}
                        src={
                            justUploadedLogo ??
                            `https://firebasestorage.googleapis.com/v0/b/touchbytouch-b336e.appspot.com/o/${encodeURIComponent(organizationData.logoPath)}?alt=media`
                        }
                    />
                )}
                <div>
                    <Typography variant="h3">{organizationData.name}</Typography>
                    <Typography variant="h5">
                        {organizationData.region},{" "}
                        {geography.find(l => l.countryCode === organizationData.countryCode)?.country || "Unknown country"}
                    </Typography>
                    {teamConf?.name && teamConf.name !== "" && teamConf.id !== "" && <h5>{teamConf.name || ""}</h5>}
                    {organizationData.district && organizationData.district !== "" && (
                        <h5>{HighSchoolDistricts.find(d => d.id === organizationData.district)?.name || ""}</h5>
                    )}
                </div>
            </div>
            {canAdministrate && organizationUser ? (
                <h6 style={{ marginTop: 12 }}>
                    Created by {organizationUser.firstName} {organizationUser.lastName} (
                    <a href={`mailto:${organizationUser.email}`}>{organizationUser.email}</a>)
                </h6>
            ) : null}
            {canTakeOver && <TakeOverCard />}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px auto"
                }}
            >
                {!organizationData.published && canAdministrate && (
                    <Button
                        sx={{ margin: "0 8px" }}
                        variant="outlined"
                        disabled={requestState !== "unpublished"}
                        onClick={() => setRequestingOrganization(true)}
                    >
                        {requestState === "pending" ? "Publication Request Pending..." : "Request School Publication"}
                    </Button>
                )}
                {canAdministrate ? (
                    <Button sx={{ margin: "0 8px" }} variant="outlined" onClick={() => setConfigOpen(true)}>
                        Configure School
                    </Button>
                ) : null}
                {superAdmin && organizationData.administrators.length ? (
                    <Button sx={{ margin: "0 8px" }} onClick={() => setMakingUnmanaged(true)}>
                        Make unmanaged
                    </Button>
                ) : null}
            </Box>
            {organizationData.boysTeam && (
                <div className="organizationDetailDiv">
                    <TeamCard id={organizationData.boysTeam} />
                </div>
            )}
            {organizationData.girlsTeam && (
                <div className="organizationDetailDiv">
                    <TeamCard id={organizationData.girlsTeam} />
                </div>
            )}
            <Modal open={configOpen} onClose={() => setConfigOpen(false)}>
                <Box sx={modalBoxStyleNarrower}>
                    <Typography variant="h5" component="h5">
                        Configure School
                    </Typography>

                    <Box className="configRow">
                        <Typography variant="body1" style={{ marginRight: "0.5rem" }}>
                            This school is currently {organizationData.published ? "published" : "unpublished"}.
                        </Typography>
                        <Button onClick={() => (organizationData.published ? setUnpublishingOpen : setRequestingOrganization)(true)}>
                            {organizationData.published ? "Unpublish" : "Publish"}
                        </Button>
                    </Box>

                    <hr />

                    {organizationData.logoPath && (
                        <img
                            style={{
                                objectFit: "cover",
                                width: 128,
                                height: 128,
                                display: "block",
                                marginBottom: 12
                            }}
                            src={`https://firebasestorage.googleapis.com/v0/b/touchbytouch-b336e.appspot.com/o/${encodeURIComponent(organizationData.logoPath)}?alt=media`}
                        />
                    )}

                    <div style={{ display: "flex" }}>
                        <InputFileUpload onChange={previewSchoolLogo} text={organizationData.logoPath ? "Update" : "Upload Logo"} />
                        {organizationData.logoPath && (
                            <Button
                                component="label"
                                variant="contained"
                                color="error"
                                sx={{ marginLeft: 2 }}
                                onClick={() => setDeletingLogo(true)}
                            >
                                Remove
                            </Button>
                        )}
                    </div>
                    <div style={{ marginTop: 8, opacity: 0.75, fontSize: 12 }}>
                        Logo should be square and approximately 128&times;128 pixels
                    </div>

                    <hr />

                    <div className="configRow">
                        <Typography variant="body1">Men's {boysTeam?.region === "club" ? "Club" : "Varsity"} Team:</Typography>
                        {organizationData.boysTeam ? (
                            <Button
                                onClick={() => setDeleting("mens")}
                                disabled={!canDeleteBoysTeam}
                                variant="outlined"
                                style={{ margin: "0 5px 0 10px" }}
                            >
                                Delete
                            </Button>
                        ) : (
                            <Button onClick={() => createTeam("boys")} variant="contained" style={{ margin: "0 5px 0 10px" }}>
                                Create
                            </Button>
                        )}
                    </div>
                    <div className="configRow">
                        <Typography variant="body1">Women's {girlsTeam?.region === "club" ? "Club" : "Varsity"} Team:</Typography>
                        {organizationData.girlsTeam ? (
                            <Button
                                onClick={() => setDeleting("womens")}
                                disabled={!canDeleteGirlsTeam}
                                variant="outlined"
                                style={{ margin: "0 5px 0 10px" }}
                            >
                                Delete
                            </Button>
                        ) : (
                            <Button onClick={() => createTeam("girls")} variant="contained" style={{ margin: "0 5px 0 10px" }}>
                                Create
                            </Button>
                        )}
                    </div>

                    <hr />

                    {organizationData.region === "New Jersey" && (
                        <>
                            <div className="configRow">
                                <Typography variant="body1" style={{ marginRight: "0.5rem" }}>
                                    District:
                                </Typography>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="district-select">District</InputLabel>
                                    <Select
                                        labelId="district-select"
                                        id="select-district"
                                        label="District"
                                        value={district?.id}
                                        onChange={e =>
                                            setDistrict(HighSchoolDistricts.find(d => d.id === e.target.value) || defaultDistrict)
                                        }
                                    >
                                        {HighSchoolDistricts.map(d => (
                                            <MenuItem value={d.id} key={`teamDistrict${d.id}`}>
                                                {d.name || "Select district"}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="configRow">
                                <Typography variant="body1" style={{ marginRight: "0.5rem" }}>
                                    Conference:
                                </Typography>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="conf-select">Conference</InputLabel>
                                    <Select
                                        labelId="conf-select"
                                        id="select-conf"
                                        label="Conference"
                                        value={teamConf?.id}
                                        onChange={e =>
                                            setTeamConf(HighSchoolConferences.find(d => d.id === e.target.value) || defaultConference)
                                        }
                                    >
                                        {HighSchoolConferences.map(d => (
                                            <MenuItem value={d.id} key={`teamConference${d.id}`}>
                                                {d.name || "Select conference"}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </>
                    )}

                    <div className="configRow">
                        <Button
                            onClick={submitSchoolDetailsUpdate}
                            disabled={updateInfoLoading}
                            className={updateInfoLoading ? "disabledButton" : ""}
                            variant="contained"
                            color="primary"
                        >
                            {updateInfoLoading ? "Updating..." : "Update"}
                        </Button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={requestingOrganization || requestingTakeover}
                onClose={() => {
                    setRequestingOrganization(false);
                    setRequestingTakeover(false);
                }}
            >
                <Box sx={modalBoxStyle}>
                    <h2>Request {requestingTakeover ? "Management of School" : "School Publication"}</h2>
                    {requestingTakeover ? (
                        <h6>
                            Until your school is published, other users cannot see your school. Use this form below and provide your details
                            in order to get accepted. You will be sent an email shortly when your application is accepted or denied.
                        </h6>
                    ) : (
                        <h6>
                            Until your request is accepted, you will not be able to manage this school. Use this form below and provide your
                            details in order to get accepted. You will be sent an email shortly when your application is accepted or denied.
                        </h6>
                    )}
                    <FormControl style={{ marginBottom: 20, width: "100%" }}>
                        <TextField
                            label="Full Name"
                            placeholder="Full Name"
                            value={requestName}
                            onChange={e => setRequestName(e.target.value)}
                            variant="filled"
                            fullWidth
                            required
                        />
                    </FormControl>

                    <FormControl style={{ marginBottom: 20, width: "100%" }}>
                        <TextField
                            label="School"
                            placeholder="Enter your school"
                            value={requestSchool}
                            onChange={e => setRequestSchool(e.target.value)}
                            variant="filled"
                            fullWidth
                            required
                        />
                    </FormControl>

                    <FormControl style={{ marginBottom: 20, width: "100%" }}>
                        <TextField
                            label="Additional Information"
                            placeholder="Enter any additional information here"
                            value={requestAddInf}
                            onChange={e => setRequestAddInf(e.target.value)}
                            variant="filled"
                            fullWidth
                            multiline
                            minRows={3}
                        />
                    </FormControl>

                    <Button
                        onClick={submitOrganizationRequest}
                        disabled={!requestName || !requestSchool || requestLoading}
                        className={!requestName || !requestSchool || requestLoading ? "disabledButton" : ""}
                        variant="contained"
                        color="primary"
                    >
                        {requestLoading ? "Submitting..." : "Submit"}
                    </Button>
                </Box>
            </Modal>
            <Dialog open={unpublishingOpen} onClose={() => setUnpublishingOpen(false)}>
                <DialogTitle>Unpublish school</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This school will no longer be visible to the public. If you want to republish this school later, you will need to
                        fill out another publication request.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={() => setUnpublishingOpen(false)}>
                        Cancel
                    </Button>
                    <Button variant="text" onClick={() => unpublish()}>
                        Unpublish
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={Boolean(deleting)} onClose={() => setDeleting("")}>
                <DialogTitle>Delete Team</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this team? This cannot be undone!</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={() => setDeleting("")}>
                        Cancel
                    </Button>
                    <Button variant="text" onClick={() => deleteTeam()}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={makingUnmanaged} onClose={() => setMakingUnmanaged(false)}>
                <DialogTitle>Make this school unmanaged?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This school will no longer have any administrators. Users will be able to make takeover requests for this team.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setMakingUnmanaged(false)}>Cancel</Button>
                    <Button onClick={() => makeUnmanaged()}>Confirm</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={Boolean(previewLogo)} onClose={() => setPreviewLogo(undefined)}>
                <DialogTitle>Upload Preview</DialogTitle>
                <DialogContent sx={{ paddingBottom: 1 }}>
                    <img
                        style={{
                            objectFit: "cover",
                            width: 128,
                            height: 128
                        }}
                        src={previewLogo}
                    />
                </DialogContent>
                <DialogActions
                    sx={{
                        paddingX: 3,
                        paddingBottom: 2
                    }}
                >
                    <Button variant="contained" onClick={uploadSchoolLogo} sx={{ marginRight: 2 }}>
                        Confirm
                    </Button>
                    <Button variant="contained" color="error" onClick={() => setPreviewLogo(undefined)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={Boolean(deletingLogo)} onClose={() => setDeletingLogo(false)}>
                <DialogTitle>Delete Logo</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete the logo? This cannot be undone!</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={() => setDeletingLogo(false)}>
                        Cancel
                    </Button>
                    <Button variant="text" onClick={() => removeSchoolLogo()}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackbarError error={snackbarError} close={() => setSnackbarError("")} />
        </TbTPage>
    );
}
