import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    FormHelperText,
    Grid,
    Link,
    TextField,
    Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TbTPage from "../../../components/TbTPage/TbTPage";
import TeamCard from "../../../components/TeamCard";
import { ReduxState } from "../../../utils/store";

import "./TeamList.css";
import { mdiPlus } from "@mdi/js";
import { useState, useEffect } from "react";
import useDatabase from "../../../hooks/database";
import { ITeam } from "../../../types";
import Icon from "@mdi/react";
import { isSuccess } from "../../../utils/database";
import RosterAutoUpdateDialog from "../../../components/RosterAutoUpdateDialog";

export default function TeamList() {
    const userInfo = useSelector((s: ReduxState) => s.userInfo);
    const DB = useDatabase();
    const dispatch = useDispatch();

    const [baseTeams, setBaseTeams] = useState<ITeam[]>([]);
    const [linkedTeams, setLinkedTeams] = useState<ITeam[]>([]);

    const [joiningTeam, setJoiningTeam] = useState(false);
    const [code, setCode] = useState("");
    const [codeError, setCodeError] = useState(false);
    const [joinError, setJoinError] = useState("");

    const checkCodeError = () => setCodeError(!(code.length === 6 && code.match(/^[a-z0-9]+$/i)));

    const teamIDs = [...new Set([...(userInfo?.teams || []), ...(userInfo?.managingTeams || []), ...linkedTeams.map(l => l.id)])];

    useEffect(() => {
        const userTeams = [...(userInfo?.teams || []), ...(userInfo?.managingTeams || [])];
        Promise.all(userTeams.map(l => DB.getTeam(l))).then(teams => setBaseTeams(teams.filter(isSuccess).map(l => l.data)));
    }, [userInfo?.teams, userInfo?.managingTeams]);

    useEffect(() => {
        if (userInfo?.linkedFencerIds) {
            (async () => {
                const fencers = await Promise.all(userInfo.linkedFencerIds.map(l => DB.getFencer(l)));
                const ids = fencers.filter(isSuccess).flatMap(l => l.data.teams);
                const t = await Promise.all(ids.map(l => DB.getTeam(l)));
                setLinkedTeams(t.filter(isSuccess).map(l => l.data));
            })();
        }
    }, [userInfo?.linkedFencerIds]);

    const teams = [...baseTeams, ...linkedTeams];

    const joinTeam = async () => {
        const succeeded = await DB.joinTeamUsingInvite(code);
        if (succeeded.status === "success") {
            const userResult = await DB.getCurrentUserInfo();
            if (userResult.status === "fail") {
                return setJoinError(userResult.data);
            } else {
                dispatch({ type: "setUserInfoV2", payload: userResult.data });
                setJoiningTeam(false);
                setCode("");
                setJoinError("");
            }
        } else {
            setJoinError(succeeded.data);
        }
    };

    return (
        <TbTPage>
            <div style={{ height: 50 }}></div>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography fontFamily="Lexend Deca" component="h1" variant="h3">
                    Teams
                </Typography>
                <Fab sx={{ marginLeft: "15px" }} color="secondary" onClick={() => setJoiningTeam(true)}>
                    <Icon path={mdiPlus} size={1} horizontal vertical rotate={180} />
                </Fab>
            </Box>
            <Grid container spacing={3} sx={{ width: "90vw", margin: "auto", paddingRight: "24px" }}>
                {teamIDs.map(l => (
                    <TeamCard id={l} key={`teamListDiv${l}`} />
                ))}
            </Grid>
            {teamIDs.length === 0 && (
                <Typography
                    fontFamily="Lexend Deca"
                    component="h3"
                    sx={{
                        margin: "50px auto 0 auto",
                        color: "#999",
                        fontSize: "24px",
                        width: "90%",
                        maxWidth: "600px"
                    }}
                >
                    You have not created any teams! Head to the Create School page to create your school.
                </Typography>
            )}
            <Dialog open={joiningTeam} onClose={() => setJoiningTeam(false)}>
                <DialogTitle>Join Team as Coach</DialogTitle>
                <DialogContent sx={{ pb: 0 }}>
                    <DialogContentText>Ask the coach for the invite code, then enter it here.</DialogContentText>
                    <TextField
                        error={codeError}
                        helperText={codeError && "Invite codes are 6 characters long containing letters and numbers only."}
                        onBlur={checkCodeError}
                        value={code}
                        onChange={e => setCode(e.target.value)}
                        label="Invite code"
                        autoFocus
                        required
                        sx={{ margin: "10px 0", width: 300 }}
                    />
                    <DialogContentText>
                        If you wish to create a team, please use the <Link href="/createSchool">Create School</Link> page.
                    </DialogContentText>
                    {Boolean(joinError) && <FormHelperText sx={{ color: "#d32f2f", textAlign: "right" }}>{joinError}</FormHelperText>}
                </DialogContent>
                <DialogActions sx={{ pt: 0 }}>
                    <Button onClick={joinTeam}>Join</Button>
                </DialogActions>
            </Dialog>
            <RosterAutoUpdateDialog />
        </TbTPage>
    );
}
