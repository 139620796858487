import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReduxState } from "../../utils/store";
import useDatabase from "../../hooks/database";
import { MenuItem } from "@mui/material";
import StyledDropdown from "../StyledDropdown/StyledDropdown";
import "./SeasonSwitcher.css";

/**
 * Component that provides dropdowns which hook into the state and modify season, team, and subteam
 */
export default function SeasonSwitcher({ teamID }: { teamID: string }) {
    const seasonRaw = useSelector((s: ReduxState) => s.season);
    const dispatch = useDispatch();
    const DB = useDatabase();

    const [season, setSeason] = useState<string>(seasonRaw);
    const [seasons, setSeasons] = useState<string[]>([]);

    useEffect(() => {
        (async () => {
            const teamIDR = teamID;
            const teamData = await DB.getTeam(teamIDR);
            if (teamData.status === "fail") {
                return;
            }
            const seasonsList = teamData.data.seasons;
            let newSeason = season;
            if (JSON.stringify(seasons) !== JSON.stringify(seasonsList)) {
                const barh =
                    new Date().getMonth() >= 7
                        ? `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`
                        : `${new Date().getFullYear() - 1}-${new Date().getFullYear()}`;
                newSeason = barh;
            }
            if (!seasonsList.includes(newSeason)) {
                newSeason = seasonsList[seasonsList.length - 1];
            }
            setSeason(newSeason);
            setSeasons(seasonsList);
            if (newSeason !== seasonRaw) {
                dispatch({ type: "switchSeasonV2", payload: newSeason });
            }
        })();
    }, []);

    useEffect(() => {
        setSeason(seasonRaw);
    }, [seasonRaw]);

    /**
     * Dispatches a value but also calls `updateMeetsThunk` as well.
     */
    const updateValue = async (type: string, payload: unknown): Promise<void> => {
        dispatch({ type, payload });
    };

    return (
        <div style={{ marginRight: 25, marginLeft: 25, fontSize: "110%" }}>
            <StyledDropdown displayText={`${season || "No"} season`}>
                {seasons.map(l => (
                    <MenuItem key={`switchSeason${l}`} onClick={() => updateValue("switchSeasonV2", l)}>
                        {seasons.length === 0 ? "No" : l} season
                    </MenuItem>
                ))}
            </StyledDropdown>
        </div>
    );
}
