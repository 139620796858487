import {
    BoutSide,
    Gender,
    IBoutEvent,
    ICollegeEvent,
    ICollegeEventRoundMeet,
    IDualMeet,
    IDualMeetBout,
    IDualMeet_DB,
    Weapon
} from "../types";

import NCAARecapSheet from "../assets/NCAARecapSheet.pdf";
import NCAAscoresheet from "../assets/fencingdualmeetscoresheet.pdf";
import HSscoresheet from "../assets/NJSIAA-NCAA_2023 Dual Meet Score Sheet.pdf";
import AccountSwitchIcon from "../assets/account-switch.png";
import MedicalBagIcon from "../assets/medical-bag.png";
import { PDFDocument, PDFFont, PDFImage, rgb } from "pdf-lib";
import Arial from "../assets/Arial.ttf";
import fontkit from "@pdf-lib/fontkit";
import { boutWinner, eventsToScoreProgression, getIndividualStatsForBouts, meetScoreByWeapon, meetScoreFromBouts, removeGenderFromStr, textWidth } from "./helpers";
import { DBResult, DBSuccess, DB_V2, isSuccess } from "./database";
import { HighSchoolConferences, HighSchoolDistricts } from "./ncaaConference";

// #region Meets/events

type CollegeDivision = "Varsity" | "JV" | "Club";

const scoresheetBuffer: Promise<ArrayBuffer> = fetch(NCAAscoresheet).then(data => data.arrayBuffer());
const recapBuffer: Promise<ArrayBuffer> = fetch(NCAARecapSheet).then(data => data.arrayBuffer());

const substituteIconBuffer: Promise<ArrayBuffer> = fetch(AccountSwitchIcon).then(data => data.arrayBuffer());
const medicalIconBuffer: Promise<ArrayBuffer> = fetch(MedicalBagIcon).then(data => data.arrayBuffer());

const arialBuffer: Promise<ArrayBuffer> = fetch(Arial).then(data => data.arrayBuffer());
let arial: PDFFont | null = null;
let substituteIcon: PDFImage | null = null;
let medicalForfeitIcon: PDFImage | null = null;

const exportCollegeDualMeetPage = async (
    doc: PDFDocument,
    meet: IDualMeet,
    bouts: IDualMeetBout[],
    gender: Gender,
    weapon: Weapon,
    team1Type: CollegeDivision,
    team2Type: CollegeDivision,
    hostName?: string
) => {
    console.time(`Page ${meet.name}`);
    console.time(`Init ${meet.name}`);
    const buffer = await scoresheetBuffer;
    const pdfDoc = await PDFDocument.load(buffer);
    const [page] = await doc.copyPages(pdfDoc, [0]);
    if (arial) page.setFont(arial);
    const { width, height } = page.getSize();
    console.timeEnd(`Init ${meet.name}`);

    console.time(`Draw ${meet.name}`);
    page.drawText(weapon, { x: 485, y: height - 52, size: 12 });
    page.drawText("X", {
        x: gender === "boys" ? 267 : 347,
        y: height - 101,
        size: 12
    });

    page.drawText(new Date(meet.startedAt).toDateString(), {
        x: 70,
        y: height - 135,
        size: 12
    });

    if (hostName) {
        page.drawText(hostName, {
            x: 267,
            y: height - 135,
            size: 12
        });
    }

    page.drawText(meet.team1.name, { x: 90, y: height - 178, size: 12 });
    page.drawText(meet.team2.name, { x: 370, y: height - 178, size: 12 });

    const leftTypeX = {
        Varsity: 36,
        JV: 83,
        Club: 113
    };

    const rightTypeX = {
        Varsity: 312,
        JV: 359,
        Club: 389
    };

    page.drawText("X", {
        x: leftTypeX[team1Type] || 36,
        y: height - 151,
        size: 10
    });
    page.drawText("X", {
        x: rightTypeX[team2Type] || 312,
        y: height - 151,
        size: 10
    });

    const boutsStartY = height - 275;
    const boutsIntervalY = 35.3;

    let substitutionKeyRequired = false;
    let medicalForfeitKeyRequired = false;

    let leftWins = 0;
    let rightWins = 0;

    // Used to calculate substitutions
    const team1FirstRoundFencers = new Set<string>();
    const team1AllFencers = new Set<string>();
    const team2FirstRoundFencers = new Set<string>();
    const team2AllFencers = new Set<string>();

    const boutsEmpty = bouts.every(l => l.fencer1.score === 0 && l.fencer2.score === 0 && !l.fencer1.forfeit && !l.fencer2.forfeit);

    if (!boutsEmpty) {
        for (let i = 0; i < bouts.length; i++) {
            const bout = bouts[i];
            const y = boutsStartY - boutsIntervalY * i;

            const fencer1ID = bout.fencer1.fencerInfo.id;
            const fencer2ID = bout.fencer2.fencerInfo.id;
            if (bout.order <= 2) {
                if (fencer1ID) team1FirstRoundFencers.add(fencer1ID);
                if (fencer2ID) team2FirstRoundFencers.add(fencer2ID);
            }
            if (fencer1ID) team1AllFencers.add(fencer1ID);
            if (fencer2ID) team2AllFencers.add(fencer2ID);

            let fencer1Name = `${bout.fencer1.fencerInfo.lastName}, ${bout.fencer1.fencerInfo.firstName}`;
            if (bout.fencer1.forfeit && !bout.fencer1.medicalForfeit) fencer1Name = "Forfeit - No fencer";
            if (fencer1Name === "fencer, Unknown") fencer1Name = bout.fencer1.forfeit ? "Forfeit - No fencer" : "";
            page.drawText(fencer1Name, {
                x: 90,
                y: boutsStartY - boutsIntervalY * i,
                size: fencer1Name.length > 25 ? 11 : 12
            });
            const fencer1NameWidth = textWidth(fencer1Name, { font: "Helvetica", size: 12 });
            const substitution1 = !bout.fencer1.forfeit && bout.fencer1.medicalFencerInfo || (team1AllFencers.has(fencer1ID!) && !team1FirstRoundFencers.has(fencer1ID!));
            if (substitution1 && substituteIcon) {
                page.drawImage(substituteIcon, {
                    x: 92 + fencer1NameWidth,
                    y: y - 3,
                    width: 14,
                    height: 14
                });
            }
            if (bout.fencer1.medicalForfeit && medicalForfeitIcon) {
                const offset = substitution1 ? 15 : 0;
                page.drawImage(medicalForfeitIcon, {
                    x: 92 + fencer1NameWidth + offset,
                    y: y - 1,
                    width: 14,
                    height: 14
                });
            }

            let fencer2Name = `${bout.fencer2.fencerInfo.lastName}, ${bout.fencer2.fencerInfo.firstName}`;
            if (bout.fencer2.forfeit && !bout.fencer2.medicalForfeit) fencer2Name = "Forfeit - No fencer";
            if (fencer2Name === "fencer, Unknown") fencer2Name = bout.fencer2.forfeit ? "Forfeit - No fencer" : "";
            page.drawText(fencer2Name, {
                x: 375,
                y: boutsStartY - boutsIntervalY * i,
                size: fencer2Name.length > 25 ? 11 : 12
            });
            const fencer2NameWidth = textWidth(fencer2Name, { font: "Helvetica", size: 12 });
            const substitution2 = !bout.fencer2.forfeit && bout.fencer2.medicalFencerInfo || (team2AllFencers.has(fencer2ID!) && !team2FirstRoundFencers.has(fencer2ID!));
            if (substitution2 && substituteIcon) {
                page.drawImage(substituteIcon, {
                    x: 377 + fencer2NameWidth,
                    y: y - 3,
                    width: 14,
                    height: 14
                });
            }
            if (bout.fencer2.medicalForfeit && medicalForfeitIcon) {
                const offset = substitution2 ? 15 : 0;
                page.drawImage(medicalForfeitIcon, {
                    x: 377 + fencer2NameWidth + offset,
                    y: y - 1,
                    width: 14,
                    height: 14
                });
            }

            if (substitution1 || substitution2) substitutionKeyRequired = true;
            if (bout.fencer1.medicalForfeit || bout.fencer2.medicalForfeit) medicalForfeitKeyRequired = true;

            const winner = boutWinner(bout);
            if (winner === null) {
                page.drawText("-", {
                    x: 62,
                    y: boutsStartY - boutsIntervalY * i + 10,
                    size: 12
                });
                page.drawText("-", {
                    x: 544,
                    y: boutsStartY - boutsIntervalY * i + 10,
                    size: 12
                });
            } else {
                leftWins += Number(winner === BoutSide.Fencer1);
                rightWins += Number(winner === BoutSide.Fencer2);
                page.drawText(winner === BoutSide.Fencer1 ? "V" : "D", {
                    x: 61,
                    y: boutsStartY - boutsIntervalY * i + 9,
                    size: 10
                });
                page.drawText(winner === BoutSide.Fencer2 ? "V" : "D", {
                    x: 543,
                    y: boutsStartY - boutsIntervalY * i + 9,
                    size: 10
                });
            }

            page.drawText(bout.fencer1.score.toString(), {
                x: 62,
                y: boutsStartY - boutsIntervalY * i - 3,
                size: 10
            });
            page.drawText(bout.fencer2.score.toString(), {
                x: 544,
                y: boutsStartY - boutsIntervalY * i - 3,
                size: 10
            });

            page.drawText(leftWins.toString(), {
                x: 42,
                y: boutsStartY - boutsIntervalY * i,
                size: 12
            });
            page.drawText(rightWins.toString(), {
                x: 563,
                y: boutsStartY - boutsIntervalY * i,
                size: 12
            });
        }

        if (substitutionKeyRequired && substituteIcon) {
            page.drawImage(substituteIcon, {
                x: 35,
                y: 210,
                width: 14,
                height: 14
            });
            page.drawText(": Substitution", {
                x: 48,
                y: 212,
                size: 11
            });
        }

        if (medicalForfeitKeyRequired && medicalForfeitIcon) {
            const offset = substitutionKeyRequired ? 85 : 0;
            page.drawImage(medicalForfeitIcon, {
                x: 35 + offset,
                y: 210,
                width: 14,
                height: 14
            });
            page.drawText(": Medical Forfeit", {
                x: 48 + offset,
                y: 212,
                size: 11
            });
        }

        const overallWinner = leftWins > rightWins ? BoutSide.Fencer1 : leftWins < rightWins ? BoutSide.Fencer2 : null;

        if (overallWinner !== null) {
            const winnerName = overallWinner === BoutSide.Fencer1 ? meet.team1.name : meet.team2.name;
            page.drawText(winnerName, {
                x: 75,
                y: 186,
                size: 12
            });
        }

        const higherWins = leftWins > rightWins ? leftWins : rightWins;
        const lowerWins = leftWins > rightWins ? rightWins : leftWins;

        page.drawText(`${higherWins} - ${lowerWins}`, {
            x: 370,
            y: 186,
            size: 12
        });
    }

    if (meet.signatures?.[`${weapon.toLowerCase()}Ref`]) {
        page.drawText(meet.signatures?.[`${weapon.toLowerCase()}Ref`], { x: 120, y: 168, size: 12 });
    }
    if (meet.signatures?.[`team1${weapon}`]) {
        page.drawText(meet.signatures![`team1${weapon}`] || "", { x: 80, y: 130, size: 12 });
    }
    if (meet.signatures?.[`team2${weapon}`]) {
        page.drawText(meet.signatures![`team2${weapon}`] || "", { x: 355, y: 129, size: 12 });
    }
    console.timeEnd(`Draw ${meet.name}`);

    console.time(`Copy ${meet.name}`);
    doc.addPage(page);
    console.timeEnd(`Copy ${meet.name}`);
    console.timeEnd(`Page ${meet.name}`);
};

export const exportCollegeDualMeetRecapSheet = async (
    doc: PDFDocument,
    meet: IDualMeet,
    bouts: IDualMeetBout[],
    gender: Gender,
    team1Type: CollegeDivision,
    team2Type: CollegeDivision,
    hostName?: string
) => {
    const buffer = await recapBuffer;
    const pdfDoc = await PDFDocument.load(buffer);
    const page = pdfDoc.getPage(0);
    const { width, height } = page.getSize();

    if (hostName) page.drawText(hostName, { x: 155, y: 585, size: 16 });
    page.drawText(new Date(meet.startedAt).toDateString(), {
        x: 155,
        y: 561,
        size: 16
    });
    if (hostName) page.drawText(hostName, { x: 205, y: 541, size: 16 });

    page.drawText("X", {
        x: 550,
        y: height - 85 - (gender === "girls" ? 23 : 0),
        size: 18
    });

    page.drawText(meet.team1.name, { x: 145, y: 459, maxWidth: 150, size: 18 });
    page.drawText(meet.team2.name, { x: 318, y: 459, maxWidth: 150, size: 18 });

    const typeYObj: Record<CollegeDivision, number> = {
        Varsity: 473,
        JV: 451,
        Club: 430
    };

    page.drawText("X", { x: 59, y: typeYObj[team1Type], size: 18 });
    page.drawText("X", { x: 498, y: typeYObj[team2Type], size: 18 });

    const weaponWins = meetScoreByWeapon(bouts);

    const weaponBoxStart = 365;
    const weaponBoxInc = 36.5;
    const weaponOrder = { Sabre: 0, Foil: 1, Epee: 2 };

    if (!Object.values(weaponWins).every(l => l[0] === 0 && l[1] === 0)) {
        for (const weapon in weaponWins) {
            page.drawText(weaponWins[weapon][0].toString(), {
                x: 269,
                y: weaponBoxStart - weaponOrder[weapon] * weaponBoxInc
            });
            page.drawText(weaponWins[weapon][1].toString(), {
                x: 340,
                y: weaponBoxStart - weaponOrder[weapon] * weaponBoxInc
            });
        }

        const meetScore = meetScoreFromBouts(bouts);

        page.drawText(meetScore[0].toString(), {
            x: 269,
            y: weaponBoxStart - weaponBoxInc * 3
        });
        page.drawText(meetScore[1].toString(), {
            x: 340,
            y: weaponBoxStart - weaponBoxInc * 3
        });
    }

    const sabreStr = meet.sabreReferee || meet.signatures?.sabreRef;
    if (sabreStr) page.drawText(sabreStr || "", { x: 215, y: 165, size: 14 });
    const foilStr = meet.foilReferee || meet.signatures?.foilRef;
    if (foilStr) page.drawText(foilStr || "", { x: 215, y: 143, size: 14 });
    const epeeStr = meet.epeeReferee || meet.signatures?.epeeRef;
    if (epeeStr) page.drawText(epeeStr || "", { x: 215, y: 122, size: 14 });

    const [copied] = await doc.copyPages(pdfDoc, [0]);
    doc.addPage(copied);
};

export const exportCollegeDualMeet = async (
    doc: PDFDocument | null,
    meet: IDualMeet,
    bouts: IDualMeetBout[] | Record<Weapon, IDualMeetBout[]>,
    gender: Gender,
    weapon: Weapon | "All" | "Cover",
    team1Type: CollegeDivision,
    team2Type: CollegeDivision,
    hostName?: string
): Promise<PDFDocument> => {
    let pdfDoc: PDFDocument | null = doc;
    if (!pdfDoc) {
        pdfDoc = await PDFDocument.create();
        pdfDoc.registerFontkit(fontkit);
        arial = await pdfDoc.embedFont(await arialBuffer);
        substituteIcon = await pdfDoc.embedPng(await substituteIconBuffer);
        medicalForfeitIcon = await pdfDoc.embedPng(await medicalIconBuffer);
    }
    if (weapon === "All") {
        await exportCollegeDualMeetRecapSheet(pdfDoc, meet, Object.values(bouts).flat(), gender, team1Type, team2Type, hostName);
        await exportCollegeDualMeetPage(pdfDoc, meet, bouts["Sabre"], gender, "Sabre", team1Type, team2Type, hostName);
        await exportCollegeDualMeetPage(pdfDoc, meet, bouts["Foil"], gender, "Foil", team1Type, team2Type, hostName);
        await exportCollegeDualMeetPage(pdfDoc, meet, bouts["Epee"], gender, "Epee", team1Type, team2Type, hostName);
    } else if (weapon === "Cover") {
        await exportCollegeDualMeetRecapSheet(pdfDoc, meet, Object.values(bouts).flat(), gender, team1Type, team2Type, hostName);
    } else {
        await exportCollegeDualMeetPage(pdfDoc, meet, bouts as IDualMeetBout[], gender, weapon, team1Type, team2Type, hostName);
    }
    return pdfDoc;
};

export const exportCollegeEventRound = async (
    DB: DB_V2,
    event: ICollegeEvent,
    round: number,
    gender: Gender,
    progressListener?: (progress: number) => void
): Promise<DBResult<PDFDocument>> => {
    console.time("Total");
    const hostName = event.hostName;

    const teamObj = {};
    const teams = gender === "boys" ? event.mensTeams : event.womensTeams;
    for (const team of teams) {
        teamObj[team.id] = team;
    }

    let completed = 0;

    const rounds = gender === "boys" ? event.mensRounds : event.womensRounds;
    const eventMeets = rounds[round].meets;

    const doc = await PDFDocument.create();
    doc.registerFontkit(fontkit);
    arial = await doc.embedFont(await arialBuffer);
    substituteIcon = await doc.embedPng(await substituteIconBuffer);
    medicalForfeitIcon = await doc.embedPng(await medicalIconBuffer);
    for (let i = 0; i < eventMeets.length; i++) {
        const eventMeet = eventMeets[i];

        console.time(`Handling ${eventMeet.abbA} vs ${eventMeet.abbB}`);

        console.time("Meet");
        const meetResult = await DB.getDualMeet(eventMeet.id);
        if (meetResult.status === "fail") return meetResult;
        const meet = meetResult.data;
        console.timeEnd("Meet");

        console.time("Bouts");
        const boutResults = await Promise.all(meet.bouts.map(l => DB.getBout(l.id, undefined, true)));
        const bouts = boutResults.filter(isSuccess).map(l => l.data);
        console.timeEnd("Bouts");
        const boutsObj: Record<Weapon, IDualMeetBout[]> = {
            Sabre: [],
            Foil: [],
            Epee: []
        };
        for (const bout of bouts) {
            boutsObj[bout.weapon].push(bout);
        }

        const type1 = teamObj[meet.team1.id!].type || "Varsity";
        const type2 = teamObj[meet.team2.id!].type || "Varsity";

        console.time("Pages");
        console.time("Recap");
        await exportCollegeDualMeetRecapSheet(doc, meet, Object.values(bouts).flat(), gender, type1, type2, hostName);
        console.timeEnd("Recap");
        await exportCollegeDualMeetPage(doc, meet, boutsObj["Sabre"], gender, "Sabre", type1, type2, hostName);
        await exportCollegeDualMeetPage(doc, meet, boutsObj["Foil"], gender, "Foil", type1, type2, hostName);
        await exportCollegeDualMeetPage(doc, meet, boutsObj["Epee"], gender, "Epee", type1, type2, hostName);

        progressListener?.(++completed);
        console.timeEnd("Pages");

        console.timeEnd(`Handling ${eventMeet.abbA} vs ${eventMeet.abbB}`);
    }
    console.timeEnd("Total");
    return new DBSuccess(doc);
};

export const exportCollegeEvent = async (
    DB: DB_V2,
    event: ICollegeEvent,
    gender: Gender,
    weapon: Weapon | "All",
    progressListener?: (progress: number) => void
): Promise<DBResult<PDFDocument>> => {
    console.time("Total");
    const hostName = event.hostName;

    const teamObj = {};
    const teams = gender === "boys" ? event.mensTeams : event.womensTeams;
    for (const team of teams) {
        teamObj[team.id] = team;
    }

    let completed = 0;

    const rounds = gender === "boys" ? event.mensRounds : event.womensRounds;
    const eventMeets = rounds.flatMap(l => l.meets);

    const doc = await PDFDocument.create();
    doc.registerFontkit(fontkit);
    arial = await doc.embedFont(await arialBuffer);
    substituteIcon = await doc.embedPng(await substituteIconBuffer);
    medicalForfeitIcon = await doc.embedPng(await medicalIconBuffer);
    for (let i = 0; i < eventMeets.length; i++) {
        const eventMeet = eventMeets[i];

        console.time(`Handling ${eventMeet.abbA} vs ${eventMeet.abbB}`);

        console.time("Meet");
        const meetResult = await DB.getDualMeet(eventMeet.id);
        if (meetResult.status === "fail") return meetResult;
        const meet = meetResult.data;
        console.timeEnd("Meet");

        console.time("Bouts");
        const boutResults = await Promise.all(meet.bouts.map(l => DB.getBout(l.id, undefined, true)));
        const bouts = boutResults.filter(isSuccess).map(l => l.data);
        console.timeEnd("Bouts");
        const boutsObj: Record<Weapon, IDualMeetBout[]> = {
            Sabre: [],
            Foil: [],
            Epee: []
        };
        for (const bout of bouts) {
            boutsObj[bout.weapon].push(bout);
        }

        const type1 = teamObj[meet.team1.id!].type || "Varsity";
        const type2 = teamObj[meet.team2.id!].type || "Varsity";

        console.time("Pages");
        if (weapon === "All") {
            console.time("Recap");
            await exportCollegeDualMeetRecapSheet(doc, meet, Object.values(bouts).flat(), gender, type1, type2, hostName);
            console.timeEnd("Recap");
            await exportCollegeDualMeetPage(doc, meet, boutsObj["Sabre"], gender, "Sabre", type1, type2, hostName);
            await exportCollegeDualMeetPage(doc, meet, boutsObj["Foil"], gender, "Foil", type1, type2, hostName);
            await exportCollegeDualMeetPage(doc, meet, boutsObj["Epee"], gender, "Epee", type1, type2, hostName);

            progressListener?.(++completed);
        } else {
            await exportCollegeDualMeetPage(doc, meet, boutsObj[weapon], gender, weapon, type1, type2, hostName);
            progressListener?.(++completed);
        }
        console.timeEnd("Pages");

        console.timeEnd(`Handling ${eventMeet.abbA} vs ${eventMeet.abbB}`);
    }
    console.timeEnd("Total");
    return new DBSuccess(doc);
};

const exportHSDualMeetPage = async (meet: IDualMeet, bouts: IDualMeetBout[], events: Record<string, IBoutEvent[]>, gender: Gender | "") => {
    const data = await fetch(HSscoresheet);
    const buffer = await data.arrayBuffer();
    const pdfDoc = await PDFDocument.load(buffer);
    const page = pdfDoc.getPage(0);
    const { width, height } = page.getSize();

    const substituteIcon = await pdfDoc.embedPng(await substituteIconBuffer);
    const medicalForfeitIcon = await pdfDoc.embedPng(await medicalIconBuffer);

    const refereeArr = [meet.sabreReferee, meet.foilReferee, meet.epeeReferee];
    const refereeStr = [...new Set(refereeArr.filter(Boolean))].join(", ");
    page.drawText(refereeStr, { x: 65, y: 46, size: 10 });

    page.drawText(removeGenderFromStr(meet.team1.name), { x: 61, y: height - 37, size: 10 });
    page.drawText(removeGenderFromStr(meet.team2.name), { x: 61, y: height - 59, size: 10 });
    page.drawText(new Date(meet.startedAt).toDateString(), { x: 285, y: height - 38, size: 10 });
    page.drawText(new Date(meet.startedAt).toLocaleTimeString([], { hour: "numeric", minute: "2-digit" }), {
        x: 265,
        y: height - 58,
        size: 10
    });

    const curScore: [number, number] = [0, 0];
    const sabreScore: [number, number] = [0, 0];
    const foilScore: [number, number] = [0, 0];
    const epeeScore: [number, number] = [0, 0];
    const sabreRecords: ([number, number] | null)[] = [];
    const foilRecords: ([number, number] | null)[] = [];
    const epeeRecords: ([number, number] | null)[] = [];
    const r1Score: [number, number] = [0, 0];
    const r2Score: [number, number] = [0, 0];
    const r3Score: [number, number] = [0, 0];
    let clinchScore: [number, number] | null = null;

    for (const bout of bouts) {
        const winner = boutWinner(bout, { team: true });

        if (winner === BoutSide.Fencer1) {
            curScore[0]++;
            if (bout.weapon === "Sabre") sabreScore[0]++;
            if (bout.weapon === "Foil") foilScore[0]++;
            if (bout.weapon === "Epee") epeeScore[0]++;
            if (Math.floor(bout.order / 3) === 0) r1Score[0]++;
            if (Math.floor(bout.order / 3) === 1) r2Score[0]++;
            if (Math.floor(bout.order / 3) === 2) r3Score[0]++;
        }
        if (winner === BoutSide.Fencer2) {
            curScore[1]++;
            if (bout.weapon === "Sabre") sabreScore[1]++;
            if (bout.weapon === "Foil") foilScore[1]++;
            if (bout.weapon === "Epee") epeeScore[1]++;
            if (Math.floor(bout.order / 3) === 0) r1Score[1]++;
            if (Math.floor(bout.order / 3) === 1) r2Score[1]++;
            if (Math.floor(bout.order / 3) === 2) r3Score[1]++;
        }

        if (bout.weapon === "Sabre") sabreRecords[bout.order] = [...sabreScore];
        if (bout.weapon === "Foil") foilRecords[bout.order] = [...foilScore];
        if (bout.weapon === "Epee") epeeRecords[bout.order] = [...epeeScore];

        if (curScore[0] === 14 || (curScore[1] === 14 && !clinchScore)) clinchScore = [curScore[0], curScore[1]];
    }

    if (clinchScore) {
        page.drawText(clinchScore[0].toString(), { x: 410, y: height - 38, size: 10 });
        page.drawText(clinchScore[1].toString(), { x: 410, y: height - 58, size: 10 });
    }
    page.drawText(curScore[0].toString(), { x: 756, y: height - 38, size: 10 });
    page.drawText(curScore[1].toString(), { x: 756, y: height - 58, size: 10 });

    page.drawText(sabreScore[0].toString(), { x: 477, y: height - 38, size: 10 });
    page.drawText(sabreScore[1].toString(), { x: 477, y: height - 58, size: 10 });
    page.drawText(foilScore[0].toString(), { x: 523, y: height - 38, size: 10 });
    page.drawText(foilScore[1].toString(), { x: 523, y: height - 58, size: 10 });
    page.drawText(epeeScore[0].toString(), { x: 577, y: height - 38, size: 10 });
    page.drawText(epeeScore[1].toString(), { x: 577, y: height - 58, size: 10 });

    page.drawText(r1Score[0].toString(), { x: 624, y: height - 38, size: 10 });
    page.drawText(r1Score[1].toString(), { x: 624, y: height - 58, size: 10 });
    page.drawText(r2Score[0].toString(), { x: 663, y: height - 38, size: 10 });
    page.drawText(r2Score[1].toString(), { x: 663, y: height - 58, size: 10 });
    page.drawText(r3Score[0].toString(), { x: 706, y: height - 38, size: 10 });
    page.drawText(r3Score[1].toString(), { x: 706, y: height - 58, size: 10 });

    const weaponAnchorX = {
        Sabre: 40,
        Foil: 290,
        Epee: 542
    };
    const startY = 90;
    const boutHeight = 40;
    const boutGap = 28.5;

    const scoreAnchorX = {
        Sabre: 152,
        Foil: 404,
        Epee: 656
    };

    let substitutionKeyRequired = false;
    let medicalForfeitKeyRequired = false;

    const runningRecords: Record<Weapon, [number, number]> = { Sabre: [0, 0], Foil: [0, 0], Epee: [0, 0] };
    const individualStats = getIndividualStatsForBouts(bouts);

    const drawnNames = new Set();

    // Used to calculate substitutions
    const team1FirstRoundFencers = new Set<string>();
    const team1AllFencers = new Set<string>();
    const team2FirstRoundFencers = new Set<string>();
    const team2AllFencers = new Set<string>();

    for (const bout of bouts) {
        const fencer1ID = bout.fencer1.fencerInfo.id;
        const fencer2ID = bout.fencer2.fencerInfo.id;
        if (bout.order <= 2) {
            if (fencer1ID) team1FirstRoundFencers.add(fencer1ID);
            if (fencer2ID) team2FirstRoundFencers.add(fencer2ID);
        }
        if (fencer1ID) team1AllFencers.add(fencer1ID);
        if (fencer2ID) team2AllFencers.add(fencer2ID);

        const winner = boutWinner(bout);
        const boutY = startY + bout.order * boutHeight + Math.floor(bout.order / 3) * boutGap;

        let fencer1Name = `${bout.fencer1.fencerInfo.lastName}, ${bout.fencer1.fencerInfo.firstName}`;
        if (fencer1Name === "fencer, Unknown") fencer1Name = bout.fencer1.forfeit ? "Forfeit - No fencer" : "";
        page.drawText(fencer1Name, {
            x: weaponAnchorX[bout.weapon],
            y: height - (boutY + 10),
            size: 10
        });
        const substitution1 = !bout.fencer1.forfeit && bout.fencer1.medicalFencerInfo || (team1AllFencers.has(fencer1ID!) && !team1FirstRoundFencers.has(fencer1ID!));
        if (substitution1) {
            const offset = bout.fencer1.medicalForfeit ? 20 : 0;
            page.drawImage(substituteIcon, {
                x: scoreAnchorX[bout.weapon] - 19 - offset,
                y: height - (boutY + 15),
                width: 14,
                height: 14
            });
        }
        if (bout.fencer1.medicalForfeit) {
            page.drawImage(medicalForfeitIcon, {
                x: scoreAnchorX[bout.weapon] - 19,
                y: height - (boutY + 14),
                width: 14,
                height: 14
            });
        }
        if (fencer1Name !== "" && fencer1Name !== "Forfeit - No fencer") {
            if (!drawnNames.has(fencer1Name)) {
                const score = individualStats.team1[bout.fencer1.fencerInfo.id || ""]?.score;
                if (score) {
                    page.drawText(score[0].toString(), { x: scoreAnchorX[bout.weapon], y: height - (boutY + 10), size: 10 });
                    page.drawText(score[1].toString(), { x: scoreAnchorX[bout.weapon] + 14, y: height - (boutY + 10), size: 10 });
                }
            }
            drawnNames.add(fencer1Name);
        }

        let fencer2Name = `${bout.fencer2.fencerInfo.lastName}, ${bout.fencer2.fencerInfo.firstName}`;
        if (fencer2Name === "fencer, Unknown") fencer2Name = bout.fencer2.forfeit ? "Forfeit - No fencer" : "";
        page.drawText(fencer2Name, {
            x: weaponAnchorX[bout.weapon],
            y: height - (boutY + 30),
            size: 10
        });
        const substitution2 = !bout.fencer2.forfeit && bout.fencer2.medicalFencerInfo || (team2AllFencers.has(fencer2ID!) && !team2FirstRoundFencers.has(fencer2ID!));
        if (substitution2) {
            const offset = bout.fencer2.medicalForfeit ? 20 : 0;
            page.drawImage(substituteIcon, {
                x: scoreAnchorX[bout.weapon] - 19 - offset,
                y: height - (boutY + 33),
                width: 14,
                height: 14
            });
        }
        if (bout.fencer2.medicalForfeit) {
            page.drawImage(medicalForfeitIcon, {
                x: scoreAnchorX[bout.weapon] - 19,
                y: height - (boutY + 32),
                width: 14,
                height: 14
            });
        }

        if (fencer2Name !== "" && fencer2Name !== "Forfeit - No fencer") {
            if (!drawnNames.has(fencer2Name)) {
                const score = individualStats.team2[bout.fencer2.fencerInfo.id || ""]?.score;
                if (score) {
                    page.drawText(score[0].toString(), { x: scoreAnchorX[bout.weapon], y: height - (boutY + 30), size: 10 });
                    page.drawText(score[1].toString(), { x: scoreAnchorX[bout.weapon] + 14, y: height - (boutY + 30), size: 10 });
                }
            }
            drawnNames.add(fencer2Name);
        }

        if (substitution1 || substitution2) substitutionKeyRequired = true;
        if (bout.fencer1.medicalForfeit || bout.fencer2.medicalForfeit) medicalForfeitKeyRequired = true;

        if (winner === BoutSide.Fencer1) {
            page.drawText("H", { x: scoreAnchorX[bout.weapon] + 99, y: height - (boutY + 15), size: 11 });
            runningRecords[bout.weapon][0]++;
            page.drawText(runningRecords[bout.weapon][0].toString(), {
                x: scoreAnchorX[bout.weapon] + 101,
                y: height - (boutY + 33),
                size: 10
            });
        }
        if (winner === BoutSide.Fencer2) {
            page.drawText("A", { x: scoreAnchorX[bout.weapon] + 99, y: height - (boutY + 15), size: 11 });
            runningRecords[bout.weapon][1]++;
            page.drawText(runningRecords[bout.weapon][1].toString(), {
                x: scoreAnchorX[bout.weapon] + 101,
                y: height - (boutY + 33),
                size: 10
            });
        }

        page.drawText(bout.fencer1.score.toString(), { x: scoreAnchorX[bout.weapon] + 83, y: height - (boutY + 10), size: 10 });
        page.drawText(bout.fencer2.score.toString(), { x: scoreAnchorX[bout.weapon] + 83, y: height - (boutY + 30), size: 10 });

        const scoreProgression = eventsToScoreProgression(events[bout.id]);

        for (let i = 0; i < scoreProgression.length; i++) {
            const num = scoreProgression[i];
            if (num === 1 || num === 3) {
                page.drawCircle({
                    x: scoreAnchorX[bout.weapon] + 27 + 6 * i,
                    y: height - (boutY + 6),
                    size: 2
                });
            }
            if (num === 2 || num === 3) {
                page.drawCircle({
                    x: scoreAnchorX[bout.weapon] + 27 + 6 * i,
                    y: height - (boutY + 26),
                    size: 2
                });
            }
        }
    }

    if (gender === "boys") {
        page.drawRectangle({ x: 202, y: height - 42, width: 29, height: 18, borderColor: rgb(0, 0, 0), borderWidth: 1 });
    } else if (gender === "girls") {
        page.drawRectangle({ x: 197, y: height - 63, width: 40, height: 16, borderColor: rgb(0, 0, 0), borderWidth: 1 });
    }

    if (meet.signatures?.team1) {
        page.drawText(meet.signatures.team1, { x: width - 254, y: 67, size: 10 });
    }

    if (meet.signatures?.team2) {
        page.drawText(meet.signatures.team2, { x: width - 254, y: 46, size: 10 });
    }

    if (meet.team1.conference && meet.team1.conference === meet.team2.conference) {
        page.drawText(meet.team1.conference, { x: width - 113, y: 68, size: 10 });
    }

    if (meet.team1.district) {
        let districtStr = HighSchoolDistricts.find(l => l.id === meet.team1.district)!.name;
        if (districtStr === "Independent") districtStr = "Indep.";
        page.drawText(districtStr, { x: width - 110, y: 46, size: 10 });
    }

    if (meet.team2.district) {
        let districtStr = HighSchoolDistricts.find(l => l.id === meet.team2.district)!.name;
        if (districtStr === "Independent") districtStr = "Indep.";
        page.drawText(districtStr, { x: width - 65, y: 46, size: 10 });
    }
    page.drawText(`${team1AllFencers.size - team1FirstRoundFencers.size}`, { x: width - 109, y: 24, size: 10 });
    page.drawText(`${team2AllFencers.size - team2FirstRoundFencers.size}`, { x: width - 64, y: 24, size: 10 });

    if (substitutionKeyRequired) {
        page.drawImage(substituteIcon, {
            x: 35,
            y: 90,
            width: 14,
            height: 14
        });
        page.drawText(": Substitution", {
            x: 48,
            y: 92,
            size: 11
        });
    }

    if (medicalForfeitKeyRequired) {
        const offset = substitutionKeyRequired ? 85 : 0;
        page.drawImage(medicalForfeitIcon, {
            x: 35 + offset,
            y: 90,
            width: 14,
            height: 14
        });
        page.drawText(": Medical Forfeit", {
            x: 48 + offset,
            y: 92,
            size: 11
        });
    }

    return pdfDoc;
};

export const exportHSDualMeet = async (
    meet: IDualMeet,
    bouts: IDualMeetBout[] | Record<Weapon, IDualMeetBout[]>,
    events: Record<string, IBoutEvent[]>,
    gender: Gender
) => {
    return exportHSDualMeetPage(meet, bouts as IDualMeetBout[], events, gender);
};

// #endregion

// #region Team lists

export interface PrintableTeamInfo {
    id: string;
    name: string;
    createdAt: string;
    creator: string;
    admins: string;
    published: boolean;
}

export const printTeamList = async (teams: PrintableTeamInfo[]): Promise<PDFDocument> => {
    const pdfDoc = await PDFDocument.create();
    let page = pdfDoc.addPage();
    const { width, height } = page.getSize();

    const fontSize = 8;
    const headerFontSize = 10;

    const publishedWidth = 70;
    const offset = 20;
    const nameX = offset;
    const createdAtX = (width - publishedWidth - offset) * 0.4 + offset;
    const creatorX = (width - publishedWidth - offset) * 0.55 + offset;
    const administratorsX = (width - publishedWidth - offset) * 0.7 + offset;
    const publishedX = width - publishedWidth;

    const headerY = height - 20;
    let currentY = height - 40;

    page.drawText("Team Name", { x: nameX, y: headerY, size: headerFontSize });
    page.drawText("Created On", { x: createdAtX, y: headerY, size: headerFontSize });
    page.drawText("Creator", { x: creatorX, y: headerY, size: headerFontSize });
    page.drawText("Administrators", { x: administratorsX, y: headerY, size: headerFontSize });
    page.drawText("Published?", { x: publishedX, y: headerY, size: headerFontSize });

    for (let i = 0; i < teams.length; i++) {
        const team = teams[i];

        try {
            page.drawText(team.name, {
                x: nameX,
                y: currentY,
                size: fontSize
            });
        } catch {
            const filterRegex = /[^a-zA-Z0-9'!"#$%&()*+,\-./:;<=>?@[\\\]^_`{|}~ ]/g;
            page.drawText(team.name.replace(filterRegex, ""), {
                x: nameX,
                y: currentY,
                size: fontSize
            });
        }

        page.drawText(team.createdAt, { x: createdAtX, y: currentY, size: fontSize });
        page.drawText(team.creator, { x: creatorX, y: currentY, size: fontSize });
        page.drawText(team.admins, { x: administratorsX, y: currentY, size: fontSize });
        page.drawText(team.published ? "Yes" : "No", { x: publishedX, y: currentY, size: fontSize });

        currentY -= 20;

        if (currentY < 20) {
            page = pdfDoc.addPage();
            currentY = height - 20;
        }
    }

    return pdfDoc;
};

// #endregion
