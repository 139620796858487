import { Button, Menu, MenuProps, Typography, alpha, styled } from "@mui/material";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import { ReactElement, cloneElement, useState } from "react";
import useDivision from "../../hooks/divison";
import { Division } from "../../types";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right"
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0"
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            "&:active": {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
            }
        }
    }
}));

const StyledDropdown = ({
    displayText,
    enableElevation,
    children
}: {
    displayText: string;
    enableElevation?: boolean;
    children: (ReactElement | null)[];
}) => {
    const division = useDivision();
    const color = division === Division.NCAA ? "primary" : "secondary";
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const filteredChildren = children.filter(Boolean).flat();

    return (
        <>
            <Button
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation={!enableElevation}
                onClick={handleClick}
                endIcon={<Icon path={mdiChevronDown} size={1} />}
                sx={{ maxWidth: "100%", textTransform: "none" }}
                color={color}
            >
                <Typography noWrap fontFamily={"Poppins"}>
                    {displayText}
                </Typography>
            </Button>
            <StyledMenu open={open} onClose={handleClose} anchorEl={anchorEl} sx={{ maxHeight: 500 }}>
                {filteredChildren.map(
                    l =>
                        l &&
                        cloneElement(l, {
                            onClick: () => {
                                l.props.onClick?.();
                                handleClose();
                            }
                        })
                )}
            </StyledMenu>
        </>
    );
};

export default StyledDropdown;
