import { compress, decompress } from "lz-string";
import CacheBase from "./cacheBase";

class CacheService extends CacheBase {
    protected async saveToStorage(key: string, value: unknown): Promise<boolean> {
        const str = compress(JSON.stringify(value));
        localStorage.setItem(`${this.PREFIX}.${key}`, str);
        return true;
    }

    protected async loadFromStorage<T>(key: string): Promise<T | null> {
        const ls = localStorage.getItem(`${this.PREFIX}.${key}`);

        if (ls) {
            let str = ls;
            try {
                str = decompress(str);
            } catch {
                console.error(`Decompress ${this.PREFIX}.${key} failed.`);
            }
            const parsed = JSON.parse(str);
            return parsed;
        }

        return null;
    }

    public async initializeStorage(): Promise<void> {
        this.teams = (await this.loadFromStorage("teams")) || {};
        this.fencers = (await this.loadFromStorage("fencers")) || {};
        this.organizations = (await this.loadFromStorage("organizations")) || {};
        this.dualMeets = (await this.loadFromStorage("dualMeets")) || {};
        this.dualMeetBouts = {};
        this.boutEvents = {};
    }
}

export default CacheService;
