import { Dialog, DialogTitle, DialogContent, Button, DialogActions, Typography } from "@mui/material"
import useDivision from "../hooks/divison";
import { Division } from "../types";

const ScoringLockedDialog = ({ visible, close }: { visible: boolean; close: () => void }) => {
    const division = useDivision();

    return <Dialog open={visible} onClose={close}>
        <DialogTitle>Meet Ended</DialogTitle>
        <DialogContent sx={{ py: 0 }}>
            <Typography variant="body1" style={{ marginBottom: 10 }}>
                This meet has ended and is no longer available for scoring. If there is
                an issue with the score, please contact the TbT admins at tbt@redpandafencing.org.
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color={division === Division.NJSIAA ? "secondary" : "primary"} onClick={close}>Okay</Button>
        </DialogActions>
    </Dialog>
}

export default ScoringLockedDialog;