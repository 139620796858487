import { useState, useEffect } from "react";
import { IDualMeet, IDualMeetBout, DualMeetType, IDualMeet_DB, BoutSide } from "../types";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { meetScoreFromBouts } from "../utils/helpers";
import useDatabase from "../hooks/database";
/**
 * Component for dual meet info
 */
function DualMeetInfoComponent({ data }: { data: IDualMeet | IDualMeet_DB }) {
    // const [bouts, setBouts] = useState<IDualMeetBout[]>([]);
    // const [scoreLoaded, setScoreLoaded] = useState(false);
    // const [scoreKey, setScoreKey] = useState(0);

    // const [score1, score2] = meetScoreFromBouts(bouts);

    // const DB = useDatabase();

    // useEffect(() => {
    // const listeners: Record<string, (val: unknown) => void> = {};
    // (async () => {
    //     const boutsData = await Promise.all(data.bouts.map((l, idx) => {
    //         const listener = (v: IDualMeetBout) => {
    //             setBouts(b => {
    //                 b.splice(idx, 1, v);
    //                 return b;
    //             });
    //             setScoreKey(s => s + 1);
    //         };
    //         listeners[l.id] = listener;
    //         const res = DB.getBout(l.id, listener);
    //         return res;
    //     }));
    //     setBouts(boutsData);
    //     setScoreLoaded(true);
    // })();

    // return () => {
    //     for (const id in listeners) {
    //         DB.stopListeningBout(id, listeners[id]);
    //     }
    // }
    // }, [data]);

    const score1 = data.bouts.reduce((acc, cur) => acc + Number(cur.winner === BoutSide.Fencer1), 0);
    const score2 = data.bouts.reduce((acc, cur) => acc + Number(cur.winner === BoutSide.Fencer2), 0);

    const meetStatus = (() => {
        const midnightTomorrow = new Date(data.startedAt).setHours(0, 0, 0, 0) + 86400000;
        if (data.endedAt || Date.now() > midnightTomorrow) {
            return `Meet ended`;
        } else if (data.startedAt > Date.now()) {
            return `Starts at ${new Date(data.startedAt).toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit" })}`;
        } else {
            return "Currently happening - watch live now";
        }
    })();

    const color = {
        Meet: "error.light",
        Starts: "primary.dark",
        Currently: "success.main"
    }[meetStatus.split(" ")[0]];

    return (
        <Card
            sx={{
                margin: "0 auto 20px auto",
                textAlign: "left",
                flexShrink: 0,
                maxWidth: "100%"
            }}
        >
            <CardActionArea href={`/meet/${data.id}`} sx={{ textDecoration: "none !important" }}>
                <CardContent>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "5px"
                        }}
                    >
                        <Typography
                            sx={{ fontSize: 14, margin: "0 10px 0 0" }}
                            fontWeight="500"
                            fontFamily="Lexend Deca"
                            color={color}
                            gutterBottom
                        >
                            {meetStatus}
                        </Typography>
                        {data.type === DualMeetType.JV && (
                            <Chip
                                sx={{
                                    fontFamily: "Lexend Deca",
                                    fontWeight: "500",
                                    marginRight: "10px"
                                }}
                                size="small"
                                label="JV Meet"
                                color="secondary"
                            />
                        )}
                        {data.type === DualMeetType.Club && (
                            <Chip
                                sx={{
                                    fontFamily: "Lexend Deca",
                                    fontWeight: "500",
                                    marginRight: "10px"
                                }}
                                size="small"
                                label="Club Meet"
                                color="secondary"
                            />
                        )}
                        {data.type === DualMeetType.MiddleSchool && (
                            <Chip
                                sx={{
                                    fontFamily: "Lexend Deca",
                                    fontWeight: "500",
                                    marginRight: "10px"
                                }}
                                size="small"
                                label="Middle School"
                                color="secondary"
                            />
                        )}
                        {!data.published && (
                            <Chip
                                sx={{
                                    fontFamily: "Lexend Deca",
                                    fontWeight: "500"
                                }}
                                size="small"
                                label="Unpublished"
                                color="error"
                            />
                        )}
                    </div>
                    <Typography gutterBottom variant="h5" component="div">
                        {data.name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {data.startedAt ? new Date(data.startedAt).toDateString() : "No start date"}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                        {score1} - {score2}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default DualMeetInfoComponent;
