import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1
});

interface InputFileUploadProps {
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    text: string;
}

const InputFileUpload: React.FC<InputFileUploadProps> = ({ onChange, text }) => {
    return (
        <Button component="label" variant="contained">
            {text}
            <VisuallyHiddenInput type="file" onChange={onChange} accept="image/*" />
        </Button>
    );
};

export default InputFileUpload;
