import { Division } from "../types";
import { DB_V2 } from "../utils/database";
import fire from "../utils/firebaseSetup";
import useDivision from "./divison";

let db: DB_V2 | null = null;

const useDatabase = () => {
    const division = useDivision();

    if (db === null) {
        let prefix = "/v2";
        if (division === Division.NCAA) prefix = "/college";

        // if (!process.env.NODE_ENV || process.env.NODE_ENV === "development" || process.env.APP_ENV === "staging") {
        //     prefix = `/test${prefix}`;
        // }

        db = new DB_V2(prefix, fire.database(), fire.auth(), fire.functions());
    }

    return db;
};

export default useDatabase;
