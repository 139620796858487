import { ITeam, IExistingFencer, IOrganization, IDualMeet_DB, IDualMeetBout, IBoutEvent } from "../../types";

abstract class CacheBase {
    protected readonly PREFIX: string;

    protected abstract saveToStorage(key: string, value: unknown): Promise<boolean>;
    protected abstract loadFromStorage<T>(key: string): Promise<T | null>;
    protected abstract initializeStorage(): void;

    protected genProxyHandler<T extends object>(this: CacheBase, name: string): ProxyHandler<T> {
        return {
            get: (obj, prop) => {
                if (!Object.keys(obj).length) {
                    const parsed = this.loadFromStorage(name);
                    this[`${name}_base`] = parsed || {};
                    this[name] = new Proxy(this[`${name}_base`], this.genProxyHandler(name));
                }
                return obj[prop];
            },
            set: (obj, prop, value) => {
                obj[prop] = value;
                this.saveToStorage(name, obj);
                return true;
            }
        };
    }

    private teams_base: Record<string | symbol, ITeam> = {};
    private fencers_base: Record<string | symbol, IExistingFencer> = {};
    private organizations_base: Record<string | symbol, IOrganization> = {};
    private dualMeets_base: Record<string | symbol, IDualMeet_DB> = {};
    private dualMeetBouts_base: Record<string | symbol, IDualMeetBout> = {};
    private boutEvents_base: Record<string | symbol, IBoutEvent> = {};

    public teams = new Proxy(this.teams_base, this.genProxyHandler("teams"));
    public fencers = new Proxy(this.fencers_base, this.genProxyHandler("fencers"));
    public organizations = new Proxy(this.organizations_base, this.genProxyHandler("organizations"));
    public dualMeets = new Proxy(this.dualMeets_base, this.genProxyHandler("dualMeets"));

    public dualMeetBouts = this.dualMeetBouts_base;
    public boutEvents = this.boutEvents_base;

    public constructor(prefix: string) {
        this.PREFIX = prefix;
    }
}

export default CacheBase;
