class ListenerManager {
    public listeners: WeakMap<(val: unknown) => void, (val: unknown) => void> = new WeakMap();
    public idListeners: Record<string, WeakMap<(val: unknown) => void, (val: unknown) => void>> = {};

    public set(key: (val: unknown) => void, value: (val: unknown) => void, id?: string) {
        if (id) {
            if (!(id in this.idListeners)) {
                this.idListeners[id] = new WeakMap();
            }
            this.idListeners[id].set(key, value);
        } else {
            this.listeners.set(key, value);
        }
    }

    public get(key: ((val: unknown) => void) | undefined, id?: string) {
        if (!key) return undefined;
        if (id) {
            return this.idListeners[id]?.get(key);
        } else {
            return this.listeners.get(key);
        }
    }

    public has(key: (val: unknown) => void, id?: string) {
        if (id) {
            return this.idListeners[id]?.has(key) || false;
        } else {
            return this.listeners.has(key);
        }
    }

    public delete(key: (val: unknown) => void, id?: string) {
        if (id) {
            return this.idListeners[id]?.delete(key) || false;
        } else {
            return this.listeners.delete(key);
        }
    }
}

export default ListenerManager;
