/**
 * Creates a Promise that is resolved with an array of results of all the provided Promises that resolved.
 * @param values An array of Promises.
 * @returns A new Promise.
 */
Promise.allResolved = function <T>(values: readonly Promise<T>[]): Promise<T[]> {
    return Promise.allSettled(values).then(v => v.filter(l => l.status === "fulfilled").map(l => (l as PromiseFulfilledResult<T>).value));
};

declare global {
    interface PromiseConstructor {
        allResolved<T>(values: readonly Promise<T>[]): Promise<T[]>;
    }
}
export {};
