import Icon from "@mdi/react";
import { Chip } from "@mui/material";
import { mdiCheck } from "@mdi/js";
import { useEffect, useState } from "react";
import useDatabase from "../hooks/database";

const OnlineIndicator = () => {
    const DB = useDatabase();
    const [online, setOnline] = useState(true);

    useEffect(() => {
        DB.getOnline(setOnline);

        return () => {
            DB.stopListeningOnline(setOnline);
        };
    }, []);

    return (
        <Chip
            color={online ? "success" : "error"}
            sx={{ height: 35, fontWeight: 500, fontSize: 14 }}
            icon={
                online ? (
                    <div>
                        <Icon path={mdiCheck} size="20px" />
                    </div>
                ) : (
                    <div></div>
                )
            }
            label={online ? "Connected" : "Reconnecting..."}
        />
    );
};

export default OnlineIndicator;
