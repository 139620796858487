import { Typography, Paper, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import TbTPage from "../../../components/TbTPage/TbTPage";
import DualMeetInfoComponent from "../../../components/DualMeetInfo";
import { IDualMeet } from "../../../types";

import "./LiveNow.css";
import useDatabase from "../../../hooks/database";
import { DBResult } from "../../../utils/database";
import RosterAutoUpdateDialog from "../../../components/RosterAutoUpdateDialog";

const LiveNow = () => {
    const DB = useDatabase();

    const [liveDualMeets, setLiveDualMeets] = useState<IDualMeet[]>([]);
    const [error, setError] = useState("");

    const handleMeets = async (result: DBResult<IDualMeet[]>) => {
        if (result.status === "fail") return setError(error);
        setLiveDualMeets(result.data);
    };

    useEffect(() => {
        DB.getLiveDualMeets(handleMeets);

        return () => {
            DB.stopListeningLiveDualMeets();
        };
    }, []);

    return (
        <TbTPage className="landingPage">
            <div className="landingPageText">
                <h1>Welcome to Touch By Touch</h1>
            </div>
            <br />
            {/* <Card sx={{ maxWidth: "700px", width: "90%", margin: "0 auto 20px auto" }}>
                <CardHeader title="Notice" sx={{ backgroundColor: theme => theme.palette.secondary.dark }} />
                <CardContent>
                    The TouchByTouch website and app have been rewritten. Data from
                    the 2020-2021 and 2021-2022 seasons will not be accessible on
                    the main site or the app, but can be accessed on the legacy
                    version of the site <a href="/legacy">here.</a>
                </CardContent>
            </Card> */}
            <Typography fontFamily="Lexend Deca" variant="h4" sx={{ marginBottom: "10px" }}>
                Live Now
            </Typography>
            {!liveDualMeets.length && (
                <Paper
                    sx={{
                        padding: "20px",
                        width: "90vw",
                        maxWidth: "500px",
                        margin: "auto"
                    }}
                >
                    <h5 style={{ color: "white" }}>There are no live meets right now. Check back later!</h5>
                </Paper>
            )}
            <Grid container spacing={3} style={{ width: "90vw", margin: "auto" }}>
                {liveDualMeets.map(meet => (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={`liveNowMeet${meet.id}`}>
                        <DualMeetInfoComponent key={`liveNowasdfas${meet.id}`} data={meet} />
                    </Grid>
                ))}
            </Grid>
            <RosterAutoUpdateDialog />
        </TbTPage>
    );
};

export default LiveNow;
